import React, { useState, useEffect, useRef } from "react"
import {
  Table,
  Button,
  Tooltip,
  Select,
  Form,
  Result,
  Collapse,
  Row,
  Col,
} from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import UpsertModal from "../Modals/add_price"
import DeleteModal from "components/Common/DeleteModal"
import { getColumnSearchProps } from "utils/filter"
import CopyModal from "../Modals/copy_price"
import "toastr/build/toastr.min.css"
const { Panel } = Collapse

const PriceManagement = () => {
  document.title = "Price Management | EASY JET CONNECT"
  const searchInput = useRef(null)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [priceList, setPriceList] = useState([])
  const [upsertModal, setUpsertModal] = useState({
    show: false,
    data: null,
    companyId: null,
  })
  const [copyModal, setCopyModal] = useState(false)

  const [deleteModal, setDeleteModal] = useState({ show: false, id: null })
  const [customers, setCustomers] = useState("")
  const [searchText, setSearchText] = useState("")

  const getAllCustomers = () => {
    Http.get(`api/companies/GetAllCustomers?categoryId=1`)
      .then(res => {
        if (res?.success) {
          setCustomers(
            res.data.map(item => {
              return { value: item.id, label: item.name }
            })
          )
        }
      })
      .catch(function (error) {
        toastr.error(error)
      })
  }

  useEffect(() => {
    getAllCustomers()
  }, [])

  const getData = () => {
    const values = form.getFieldValue()
    setLoading(true)
    if (values.companyId) {
      Http.get(
        `api/accounting/getCompanyProductPrice?CompanyId=${values.companyId}&categoryId=1`
      )
        .then(res => {
          if (res?.success) {
            setLoading(false)
            setPriceList(res.data)
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.message)
        })
    } else {
      setLoading(false)
    }
  }
  const columns = [
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
      ...getColumnSearchProps(
        "style",
        "Filter by style",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.style.localeCompare(b.style),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      ...getColumnSearchProps(
        "size",
        "Filter by size",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.size.localeCompare(b.size),
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
      render: (value, _) => "$" + value.toFixed(2),
    },
    {
      title: " ",
      key: "action",
      align: "center",
      width: 120,
      render: (_, record) => (
        <>
          {/* <Tooltip placement="top" title="Delete">
            <Button
              type="button"
              icon={<i className="mdi mdi-delete" />}
              onClick={() => {
                setDeleteModal({ show: true, id: record.id })
              }}
              style={{ marginRight: 5 }}
            />
          </Tooltip> */}
          <Tooltip placement="top" title="Edit">
            <Button
              type="button"
              className="btn btn-primary d-flex align-items-center justify-content-center m-auto"
              icon={<i className="mdi mdi-pencil" />}
              onClick={() => {
                const values = form.getFieldValue()
                setUpsertModal({
                  show: true,
                  data: record,
                  companyId: values.companyId,
                })
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ]

  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="If you haven't selected any company or added financial data yet, you can select and list a company or create a new financial value."
      />
    ),
  }

  const handleDelete = () => {
    setLoading(true)
    if (deleteModal.id) {
      Http.get(
        `api/accounting/deleteCompanyProductPrice?productPriceId=${deleteModal.id}`
      )
        .then(res => {
          if (res?.success) {
            toastr.success("Deletion completed successfully")
            setDeleteModal({ show: false, id: null })
            setLoading(false)
            getData()
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.message)
        })
    } else {
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      {copyModal && (
        <CopyModal
          setCopyModal={setCopyModal}
          loading={loading}
          setLoading={setLoading}
          customersList={customers}
          getData={getData}
        />
      )}

      {upsertModal.show && (
        <UpsertModal
          upsertModal={upsertModal}
          setUpsertModal={setUpsertModal}
          loading={loading}
          setLoading={setLoading}
          customersList={customers}
          getData={getData}
        />
      )}
      {deleteModal.show && (
        <DeleteModal
          show={deleteModal.show}
          onDeleteClick={() => handleDelete()}
          onCloseClick={() => setDeleteModal({ show: false, id: null })}
        />
      )}
      <div className="page-content mb-3">
        <Collapse activeKey={["1"]} expandIcon={() => false}>
          <Panel
            key="1"
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Apparel Price Management
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    columnGap: "15px",
                  }}
                >
                  <Button
                    className="btn btn-primary d-flex align-items-center justify-content-end"
                    onClick={() => {
                      setCopyModal(true)
                    }}
                    icon={
                      <i className="bx bx-plus font-size-16 align-middle"></i>
                    }
                  >
                    Price Copy Process
                  </Button>
                  <Button
                    className="btn btn-primary d-flex align-items-center justify-content-end"
                    onClick={() => {
                      const values = form.getFieldValue()
                      setUpsertModal({
                        show: true,
                        data: null,
                        companyId: values.companyId,
                      })
                    }}
                    icon={
                      <i className="bx bx-plus font-size-16 align-middle"></i>
                    }
                  >
                    Add New Price
                  </Button>
                </div>
              </div>
            }
          >
            <Form form={form} layout="vertical">
              <Row>
                <Col lg={4}>
                  <Form.Item label="Customer" name="companyId" required>
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select a company"
                      options={customers}
                      onChange={() => {
                        getData()
                      }}
                      style={{ minWidth: "150px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Table
              bordered
              locale={locale}
              title={false}
              columns={columns}
              dataSource={priceList}
              loading={loading}
              rowKey={record => record.id}
            />
          </Panel>
        </Collapse>
      </div>
    </React.Fragment>
  )
}

export default PriceManagement
