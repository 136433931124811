import React, { useEffect, useState } from "react"
import {
  Form,
  Card,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Spin,
  Space,
  Tag,
  Row,
  Col,
  Button,
} from "antd"
import Breadcrumbs from "components/Common/Breadcrumb"
import * as Http from "utils/http.helper"

import { SearchOutlined, ClearOutlined, EditOutlined } from "@ant-design/icons"

const EngravingStockTracking = () => {
  document.title = "Stock Tracking | EASY JET CONNECT"

  const [form] = Form.useForm()
  const [formSearch] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [editingKey, setEditingKey] = useState("")

  const getData = (sku = null, maxStockCount = null) => {
    setLoading(true)
    Http.get(`api/stock/getCompanyStock`)
      .then(res => {
        if (res.length > 0) {
          if (sku) {
            let find = res.find(x => x.sku.toLowerCase() === sku.toLowerCase())
            if (find) {
              setData([{ ...find, key: 0 }])
            }
          } else if (maxStockCount > -9999999999 && maxStockCount) {
            let filter = res.filter(x => x.stock <= maxStockCount)
            if (filter) {
              setData(
                filter.map((item, index) => {
                  return { ...item, key: index }
                })
              )
            }
          } else {
            setData(
              res.map((item, index) => {
                return { ...item, key: index }
              })
            )
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const isEditing = record => record.key === editingKey
  const edit = record => {
    form.setFieldsValue({
      stock: "",
    })
    setEditingKey(record.key)
  }
  const cancel = () => {
    setEditingKey("")
  }
  const save = async (key, record) => {
    setLoading(true)
    try {
      const row = await form.validateFields()
      let newStock = parseInt(record.stock) + parseInt(row.stock)
      const newData = [...data]
      const index = newData.findIndex(item => key === item.key)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          stock: newStock,
        })
        setData(newData)
        setEditingKey("")
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey("")
      }
      const obj = {
        sku: record.sku,
        stock: newStock,
      }
      Http.post("api/stock/updateCompanyStock", obj)
        .then(res => {
          if (res.success) {
            toastr.success(`${record.sku} stok eklendi.`)
            getData()
          } else {
            toastr.error(`${record.sku}  stoğu güncellenemedi.`)
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    } catch (errInfo) {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "Sku",
      dataIndex: "sku",
      width: "35%",
    },
    {
      title: "Stock",
      dataIndex: "stock",
      editable: true,
      width: "35%",
      showSorterTooltip: {
        target: "full-header",
      },
      sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: " ",
      dataIndex: "operation",
      width: "30%",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <Space size="middle">
            <Button
              type="button"
              className="btn btn-primary d-flex align-items-center justify-content-center "
              style={{
                border: "1px solid #556ee6",
              }}
              onClick={() => save(record.key, record)}
              icon={<EditOutlined />}
            >
              Save
            </Button>

            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button
                type="button"
                className="btn btn-light d-flex align-items-center justify-content-center"
                icon={<ClearOutlined />}
                style={{
                  border: "1px solid #f94040",
                  background: "#f94040",
                  color: "white",
                }}
              >
                Cancel
              </Button>
            </Popconfirm>
          </Space>
        ) : (
          <Space size="middle">
            <Button
              type="button"
              className="btn btn-primary d-flex align-items-center justify-content-center "
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              icon={<EditOutlined />}
            >
              Add Stock
            </Button>
          </Space>
        )
      },
    },
  ]
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
              width: "100%",
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <InputNumber
              placeholder="Lütfen eklenecek stock miktarını giriniz!"
              style={{
                width: "100%",
              }}
              rules
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <Breadcrumbs title="Engraving Stock Tracking" />
        <Row gutter={[16, 16]} className="mt-3">
          <Col span={24}>
            <Tag color="volcano">
              <span style={{ color: "black", fontWeight: "bold" }}>
                * Girilen stok değeri var olan stok değerine eklenmektedir.
                Örneğin; GFT2141 sku ürününe ait -50 stok varsa ve siz 60
                eklerseniz yeni güncel stok 10 olacaktır.{" "}
              </span>{" "}
            </Tag>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col span={16}>
            <Spin spinning={loading} fullscreen></Spin>

            <Card className="mt-3" hoverable>
              <Form layout="vertical" form={formSearch}>
                <Row gutter={[16, 16]}>
                  <Col lg={6}>
                    <Form.Item name="sku" label="Sku">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <Form.Item name="maxStockCount" label="Maximum Stock Count">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col
                    lg={4}
                    className="d-flex align-items-center justify-content-start gap-2"
                  >
                    <Button
                      type="button"
                      className="btn btn-light d-flex align-items-center justify-content-center"
                      icon={<ClearOutlined />}
                      style={{
                        border: "1px solid #f94040",
                        background: "#f94040",
                        color: "white",
                        width: "100%",
                        marginTop: 5,
                      }}
                      onClick={() => {
                        formSearch.resetFields()
                        getData()
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary d-flex align-items-center justify-content-center "
                      style={{
                        border: "1px solid #556ee6",
                        width: "100%",
                        marginTop: 5,
                      }}
                      icon={<SearchOutlined />}
                      onClick={() => {
                        const { sku, maxStockCount } =
                          formSearch.getFieldsValue()
                        getData(sku, maxStockCount)
                      }}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Form form={form} component={false}>
                <Table
                  size="small"
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={data}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </Card>
          </Col>
        </Row>
      </div>{" "}
    </React.Fragment>
  )
}

export default EngravingStockTracking
