import React from "react"
import { SearchOutlined } from "@ant-design/icons"
import Highlighter from "react-highlight-words"
import { Button, Input, Space } from "antd"

export const getColumnSearchProps = (
  dataIndex,
  textIndex,
  searchInput,
  searchText,
  setSearchText
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={e => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`${textIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => {
          confirm({ closeDropdown: false })
          setSearchText(selectedKeys[0])
        }}
        style={{
          marginBottom: 8,
          display: "block",
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm({ closeDropdown: false })
            setSearchText(selectedKeys[0])
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters && clearFilters()
            confirm()
            setSearchText("")
          }}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => (
    <SearchOutlined
      style={{
        color: filtered ? "#1677ff" : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100)
    }
  },
  render: text =>
    dataIndex === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
})
