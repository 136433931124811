import React, { useState, useEffect, useRef } from "react"
import { Form, Spin, Input, Row, Col, Card, Divider } from "antd"
import * as Http from "utils/http.helper"
import Breadcrumbs from "components/Common/Breadcrumb"
// Black t-shirt
import teeFrontBlack from "assets/images/transparent/TEE/TEE-B-FRONT.png"
import teeBackBlack from "assets/images/transparent/TEE/TEE-B-BACK.png"
import teeLeftBlack from "assets/images/transparent/TEE/TEE-B-LEFT.png"
import teeRightBlack from "assets/images/transparent/TEE/TEE-B-RIGHT.png"
// White t-shirt
import teeFrontWhite from "assets/images/transparent/TEE/TEE-W-FRONT.png"
import teeBackWhite from "assets/images/transparent/TEE/TEE-W-BACK.png"
import teeLeftWhite from "assets/images/transparent/TEE/TEE-W-LEFT.png"
import teeRightWhite from "assets/images/transparent/TEE/TEE-W-RIGHT.png"
// Black Onesie
import onesieFrontBlack from "assets/images/transparent/ONESIE/ONESIE-B-FRONT.png"
import onesieBackBlack from "assets/images/transparent/ONESIE/ONESIE-B-BACK.png"
// White Onesie
import onesieFrontwhite from "assets/images/transparent/ONESIE/ONESIE-W-FRONT.png"
import onesieBackwhite from "assets/images/transparent/ONESIE/ONESIE-W-BACK.png"
// Black Sweatshirt
import sweatshirtFrontBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-FRONT.png"
import sweatshirtBackBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-BACK.png"
import sweatshirtLeftBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-LEFT.png"
import sweatshirtRightBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-RIGHT.png"
// White Sweatshirt
import sweatshirtFrontWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-FRONT.png"
import sweatshirtBackWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-BACK.png"
import sweatshirtLeftWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-LEFT.png"
import sweatshirtRightWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-RIGHT.png"
// Black Hoodie
import hoodieFrontBlack from "assets/images/transparent/HOODIE/HOODIE-B-FRONT.png"
import hoodieBackBlack from "assets/images/transparent/HOODIE/HOODIE-B-BACK.png"
import hoodieLeftBlack from "assets/images/transparent/HOODIE/HOODIE-B-LEFT.png"
import hoodieRightBlack from "assets/images/transparent/HOODIE/HOODIE-B-RIGHT.png"
// White Hoodie
import hoodieFrontWhite from "assets/images/transparent/HOODIE/HOODIE-W-FRONT.png"
import hoodieBackWhite from "assets/images/transparent/HOODIE/HOODIE-W-BACK.png"
import hoodieLeftWhite from "assets/images/transparent/HOODIE/HOODIE-W-LEFT.png"
import hoodieRightWhite from "assets/images/transparent/HOODIE/HOODIE-W-RIGHT.png"
import ImageComponent from "components/image"

import error from "assets/images/error-img.png"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "./style.scss"

const optionGroup = [
  {
    value: "front",
    label: "Front View",
  },
  {
    value: "left_front",
    label: "Left and Upper Front View",
  },
  {
    value: "rigth_front",
    label: "Right and Upper Front View",
  },
  {
    value: "left",
    label: "Left Side View",
  },
  {
    value: "right",
    label: "Right Side View",
  },
  {
    value: "back",
    label: "Back View",
  },
  {
    value: "inside_right_sleeve",
    label: "Inside Right Sleeve",
  },
  {
    value: "inside_left_sleeve",
    label: "Inside Left Sleeve",
  },
  {
    value: "outside_right_sleeve",
    label: "Outside Right Sleeve",
  },
  {
    value: "outside_left_sleeve",
    label: "Outside Left Sleeve",
  },
]
const Printer = () => {
  //meta title
  document.title = "Printer Page | EASY JET CONNECT"
  const [form] = Form.useForm()
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState([])
  const [userInfo, setUserInfo] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [boxLoading, setBoxLoading] = useState(false)

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("authUserV0"))
    setUserInfo(info)
    inputRef.current && inputRef.current.focus()
  }, [])

  const handleInputChange = e => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      getOrder(e.target.value)
      setOrderId(e.target.value)
      setTimeout(() => {
        inputRef.current.focus()
      }, 500)
    }
  }

  const handleInputBlur = e => {
    if (e.target.value.length > 0) {
      getOrder(e.target.value)
      setOrderId(e.target.value)
    }
    setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 750)
  }

  const getOrder = value => {
    const orderStatus =
      JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length > 0
        ? JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length > 0 &&
          JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] ===
            "Completed"
          ? 50
          : 60
        : 0
    form.setFieldsValue({
      barcode: "",
    })
    setLoading(true)
    setBoxLoading(true)
    Http.get(
      `api/orders/getPrinterOrders?orderId=${value}&companyId=${userInfo?.companyId}&status=${orderStatus}`
    )
      .then(res => {
        if (res?.success && res?.data.length > 0) {
          setInfo(res.data)
        } else {
          toastr.error(
            `${value} numaralı order henüz print etmek için hazır olmayabilir (Has Image=false) ve ya order numaranız hatalı olabilir. Kontrol ediniz!`
          )
        }
        setLoading(false)
        setTimeout(() => {
          setBoxLoading(false)
        }, 750)
      })
      .catch(function (error) {
        setBoxLoading(false)
        setLoading(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <Spin spinning={loading}>
          <Breadcrumbs title="Apparel Printer" />
          <Card className="mt-3">
            <Row className="align-items-center" gutter={[16, 16]}>
              <Col lg={8}>
                <Form form={form} layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col lg={24}>
                      <Form.Item label="Order Number" name="barcode">
                        <Input
                          ref={inputRef}
                          onPressEnter={e => handleInputChange(e)}
                          onBlur={e => handleInputBlur(e)}
                          onKeyUp={e => handleInputChange(e)}
                          onChange={e => handleInputChange(e)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            {info.length > 0 && (
              <Row gutter={[16, 16]}>
                <Col
                  lg={24}
                  style={{
                    display: "flex",
                    paddingBottom: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Order Number: {orderId}
                </Col>
                <Col
                  lg={24}
                  style={{
                    display: "flex",
                    paddingBottom: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 17,
                  }}
                >
                  Notes: {info[0].note}
                </Col>
                {info.map((item, index) => {
                  let labelName = optionGroup.find(
                    x => x.value === item?.positionCode
                  )
                  const css = `
                  .img_${index}{
                      ${
                        item?.colorHexCode === "#fff" ||
                        item?.colorHexCode === "#ffffff" ||
                        item?.colorHexCode === "#000" ||
                        item?.colorHexCode === "#000000"
                          ? "background-color:#e8e8e8ad"
                          : ""
                      }
                  }
                  `
                  return (
                    <Col xs={6} key={index + "-col"}>
                      <style>{css}</style>
                      <Card
                        title={labelName?.label}
                        hoverable
                        id={item.positionCode}
                        key={index + "-file"}
                        style={{
                          padding: "10px",
                          border: "1px solid #c5c5c58f",
                        }}
                      >
                        <Spin spinning={boxLoading}>
                          {item.positionCode === "left" ? (
                            <div style={{ background: item.colorHexCode }}>
                              <ImageComponent
                                key={`tshirt_${index}]`}
                                imageUrl={
                                  item.colorHexCode === "#000000"
                                    ? item.styleName === "Sweatshirt"
                                      ? sweatshirtLeftWhite
                                      : item.styleName === "Hoodie"
                                      ? hoodieLeftWhite
                                      : teeLeftWhite
                                    : item.styleName === "Sweatshirt"
                                    ? sweatshirtLeftBlack
                                    : item.styleName === "Hoodie"
                                    ? hoodieLeftBlack
                                    : teeLeftBlack
                                }
                                width="100%"
                                height="100%"
                                preview={false}
                              />
                            </div>
                          ) : item.positionCode === "right" ? (
                            <div style={{ background: item.colorHexCode }}>
                              <ImageComponent
                                key={`tshirt_${index}]`}
                                imageUrl={
                                  item.colorHexCode === "#000000"
                                    ? item.styleName === "Sweatshirt"
                                      ? sweatshirtRightWhite
                                      : item.styleName === "Hoodie"
                                      ? hoodieRightWhite
                                      : teeRightWhite
                                    : item.styleName === "Sweatshirt"
                                    ? sweatshirtRightBlack
                                    : item.styleName === "Hoodie"
                                    ? hoodieRightBlack
                                    : teeRightBlack
                                }
                                width="100%"
                                height="100%"
                                preview={false}
                              />
                            </div>
                          ) : item.positionCode === "back" ? (
                            <div style={{ background: item.colorHexCode }}>
                              <ImageComponent
                                key={`tshirt_${index}]`}
                                imageUrl={
                                  item.colorHexCode === "#000000"
                                    ? item.styleName === "Sweatshirt"
                                      ? sweatshirtBackWhite
                                      : item.styleName === "Hoodie"
                                      ? hoodieBackWhite
                                      : item.styleName === "Onesie"
                                      ? onesieBackwhite
                                      : teeBackWhite
                                    : item.styleName === "Sweatshirt"
                                    ? sweatshirtBackBlack
                                    : item.styleName === "Hoodie"
                                    ? hoodieBackBlack
                                    : item.styleName === "Onesie"
                                    ? onesieBackBlack
                                    : teeBackBlack
                                }
                                width="100%"
                                height="100%"
                                preview={false}
                              />
                            </div>
                          ) : item.positionCode === "outside_right_sleeve" ||
                            item.positionCode === "outside_left_sleeve" ? (
                            <div style={{ background: item.colorHexCode }}>
                              <ImageComponent
                                key={`tshirt_${index}]`}
                                imageUrl={
                                  item.colorHexCode === "#000000"
                                    ? sweatshirtBackWhite
                                    : sweatshirtBackBlack
                                }
                                width="100%"
                                height="100%"
                                preview={false}
                              />
                            </div>
                          ) : item.positionCode === "inside_right_sleeve" ||
                            item.positionCode === "inside_left_sleeve" ? (
                            <div style={{ background: item.colorHexCode }}>
                              <ImageComponent
                                key={`tshirt_${index}]`}
                                imageUrl={
                                  item.colorHexCode === "#000000"
                                    ? sweatshirtFrontWhite
                                    : sweatshirtFrontBlack
                                }
                                width="100%"
                                height="100%"
                                preview={false}
                              />
                            </div>
                          ) : (
                            <div style={{ background: item.colorHexCode }}>
                              <ImageComponent
                                key={`tshirt_${index}]`}
                                imageUrl={
                                  item.colorHexCode === "#000000"
                                    ? item.styleName === "Sweatshirt"
                                      ? sweatshirtFrontWhite
                                      : item.styleName === "Hoodie"
                                      ? hoodieFrontWhite
                                      : item.styleName === "Onesie"
                                      ? onesieFrontwhite
                                      : teeFrontWhite
                                    : item.styleName === "Sweatshirt"
                                    ? sweatshirtFrontBlack
                                    : item.styleName === "Hoodie"
                                    ? hoodieFrontBlack
                                    : item.styleName === "Onesie"
                                    ? onesieFrontBlack
                                    : teeFrontBlack
                                }
                                width="100%"
                                height="100%"
                                preview={false}
                              />
                            </div>
                          )}

                          <div
                            className={
                              item.positionCode +
                              " " +
                              item.styleName.toLowerCase() +
                              " printer " +
                              `img_${index}`
                            }
                            key={`div_${index}]`}
                            style={{
                              backgroundImage: `url(${item.imageUrl})`,
                              border: "red 1px dashed",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "bottom",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />

                          <Divider />
                          <Row gutter={[16, 16]}>
                            <Col lg={10}>
                              Style:{" "}
                              <span className="text-primary">
                                {item?.styleName}
                              </span>
                            </Col>
                            <Col lg={14}>
                              Sku:{" "}
                              <span className="text-primary">{item?.sku}</span>
                            </Col>

                            <Col lg={10}>
                              Color:{" "}
                              <span className="text-primary">
                                {item?.colorName}
                              </span>
                            </Col>
                            <Col lg={7}>
                              Size:{" "}
                              <span className="text-primary">
                                {item?.sizeName}
                              </span>
                            </Col>

                            <Col lg={7}>
                              {" "}
                              Price:{" "}
                              <span
                                className="text-primary"
                                style={{ marginRight: 15 }}
                              >
                                ${item?.totalPrice}
                              </span>
                            </Col>
                          </Row>
                        </Spin>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            )}
            {info.length === 0 && (
              <>
                <Row gutter={[16, 16]}>
                  {" "}
                  <Col lg={8}>
                    <div className="text-center mb-5">
                      <h4 className="text-uppercase">
                        Lütfen Order Numarası İle arama yapınız!
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="justify-content-center">
                  <Col lg={24}>
                    <div>
                      <img src={error} alt="" className="img-fluid" />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default Printer
