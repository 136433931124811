import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import {
  Table,
  Tag,
  Button,
  Card,
  Tooltip,
  Form,
  Space,
  Input,
  Popconfirm,
  Typography,
} from "antd"
import * as Http from "utils/http.helper"
import {
  SearchOutlined,
  ClearOutlined,
  CloseCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import toastr from "toastr"
import * as moment from "moment"
import Breadcrumbs from "components/Common/Breadcrumb"
import { BrowserRouter as Router, useNavigate } from "react-router-dom"
const { Paragraph } = Typography

const css = `

#order-list td{
  vertical-align: middle;
}
tr{ cursor:pointer }
 .multipleRow, .multipleRow .ant-table-cell-fix-right{
  background:#c2dafe!important;
}
.multipleRow td:first-child
{
  font-weight:bold
}
.ant-table-row-level-1 .ant-table-cell:first-child,
.ant-table-row-level-2 .ant-table-celll:first-child,
.ant-table-row-level-3 .ant-table-celll:first-child,
.ant-table-row-level-4 .ant-table-celll:first-child,
.ant-table-row-level-5 .ant-table-celll:first-child,
.ant-table-row-level-6 .ant-table-celll:first-child,
.ant-table-row-level-7 .ant-table-celll:first-child,
.ant-table-row-level-8 .ant-table-celll:first-child,
.ant-table-row-level-9 .ant-table-celll:first-child,
.ant-table-row-level-10 .ant-table-celll:first-child{
  font-weight:bold
}
.ant-table-cell-row-hover,
.ant-table-cell-row-hover.ant-table-cell-fix-right{
  background:#dddddd!important;
}
.ant-table-cell.ant-table-cell-with-append .ant-table-row-expand-icon.ant-table-row-expand-icon-spaced{
  background:red
}
.multipleRow:hover .ant-table-cell-row-hover{
  background:#c2dafe!important;
}
@page  
{ 
    size: auto;  
    margin: 25mm 5mm 25mm 5mm;  
}`
const orderStatus = [
  {
    value: 10,
    label: "New Order",
  },
  {
    value: 20,
    label: "Production Ready",
  },
  {
    value: 30,
    label: "Production",
  },
  {
    value: 40,
    label: "Pdf Printed",
  },
  {
    value: 50,
    label: "Print",
  },
  {
    value: 60,
    label: "Complated",
  },
  {
    value: 70,
    label: "WAiting For Design",
  },
]
const EngravingWaitingList = () => {
  document.title = "Waiting For Design List | EASY JET CONNECT"
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [engravingList, setEngravingList] = useState([])
  const [tableParams, setTableParams] = useState({
    current: 0,
    pageSize: 0,
    total: 0,
  })
  useEffect(() => {
    getTableData()
  }, [])

  const getTableData = pagination => {
    setLoading(true)
    const { orderId } = form.getFieldValue()
    Http.get(
      `api/orders/GetWaitingForDesignOrders?pageNumber=${
        pagination?.current ? pagination.current : 1
      }&limit=${pagination?.pageSize ? pagination.pageSize : 10}&categoryId=3${
        orderId ? "&search=" + orderId : ""
      }`
    )
      .then(res => {
        if (res?.success) {
          if (res?.data?.data?.orders.length > 0) {
            res?.data?.data?.orders.map(item => {
              item.key = item.id
              item?.children?.length === 0 && delete item.children
            })
            setEngravingList(res?.data?.data?.orders)
            setTableParams({
              current: pagination?.current ? pagination.current : 1,
              pageSize: pagination?.pageSize ? pagination.pageSize : 10,
              total:
                res?.data?.totalItems !== 0
                  ? res?.data?.totalItems
                  : tableParams.total,
            })
          } else {
            setEngravingList([])
          }
        } else {
          setTableParams({
            current: 1,
            pageSize: 10,
            total: 0,
          })
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error)
      })
  }

  const handleChangeStatus = (id, statusCode, sku, orderId) => {
    setLoading(true)
    Http.post("api/orders/ChangeOrderStatus", {
      orderId: id,
      status: statusCode,
    })
      .then(res => {
        if (res.success) {
          toastr.success(
            `${orderId} order number and ${sku} sku number, order status has been update.`
          )
          statusCode === 80 && getTableData()
        } else {
          setProducts([])
          toastr.error(
            `${orderId} order number and ${sku} sku number, order status update process failed`
          )
          getTableData()
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        setProducts([])
        getTableData()
        toastr.error("Status update process fail")
      })
  }

  const columns = [
    {
      title: "Sku",
      dataIndex: "sku",
      width: 150,
      render: value => {
        return <Paragraph copyable>{value}</Paragraph>
      },
    },

    {
      title: "Order No",
      dataIndex: "orderId",
      render: value => {
        return <Paragraph copyable>{value}</Paragraph>
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Quant.",
      dataIndex: "quantity",
      width: 80,
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Variant",
      dataIndex: "variant",
      width: 130,
      render: (_, row) => {
        return (
          <Tooltip placement="top" title={row.variant}>
            <div className="tooltip_table">
              {row.variant.substring(0, 12)}...
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "150px",
      render: (_, row) => {
        return (
          <Tag color={row.status === 10 ? "red" : "green"} key={row.id}>
            {orderStatus.find(x => x.value === row.status)?.label}
          </Tag>
        )
      },
    },
    {
      title: "Name Surname",
      dataIndex: "nameSurname",
      render: (_, row) => {
        return row.shipmentDetail.nameSurname
      },
    },
    {
      title: "Sale Date",
      dataIndex: "saleDate",
      key: "saleDate",
      render: value => {
        return moment(new Date(value)).format("DD MMM YYYY")
      },
    },
    {
      title: " ",
      key: "action",
      align: "center",
      hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1,
      render: (_, record) => {
        return (
          <Space size="middle">
            <Tooltip placement="top" title="Upload Image">
              <Button
                type="button"
                className="btn btn_tertiary d-flex align-items-center justify-content-center  m-auto"
                icon={<UploadOutlined />}
                onClick={() => {
                  navigate("/engraving-design-upload", {
                    state: {
                      ...record,
                      waitingList: true,
                    },
                  })
                }}
                style={{ marginRight: 5 }}
              />
            </Tooltip>
            {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 ||
              JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) && (
              <Tooltip placement="top" title="Cancel Order">
                <Popconfirm
                  title="Attention!"
                  description="Are you sure you want to cancel the order?"
                  onConfirm={() => {
                    handleChangeStatus(
                      record.id,
                      80,
                      record.sku,
                      record.orderId
                    )
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="button"
                    className="btn btn_quinary d-flex align-items-center justify-content-center"
                    icon={<CloseCircleOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </Space>
        )
      },
    },
  ].filter(item => !item.hidden)

  const handleTableChange = pagination => {
    setLoading(true)
    setTableParams({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: tableParams.total,
    })
    if (
      tableParams.current !== pagination.current ||
      tableParams.pageSize !== pagination.pageSize
    ) {
      getTableData({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    }
  }

  return (
    <React.Fragment>
      <style>{css}</style>
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs title="Engraving Waiting For Design List" />
          <Card className="mt-3">
            <Form form={form} layout="vertical">
              <Row>
                <Col lg={2}>
                  <Form.Item label="Order Id" name="orderId">
                    <Input onPressEnter={() => getTableData(null)} allowClear />
                  </Form.Item>
                </Col>
                <Col
                  span={3}
                  className="d-flex align-items-center justify-content-start gap-2"
                >
                  <Button
                    type="button"
                    className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                    icon={<ClearOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        orderId: "",
                      })
                      setEngravingList([])
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                    icon={<SearchOutlined />}
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => {
                          getTableData(null)
                        })
                        .catch(err => {
                          setLoading(false)
                        })
                    }}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={engravingList}
              loading={loading}
              pagination={tableParams}
              onChange={handleTableChange}
              rowKey={record => record.id}
              rowClassName={record =>
                record.children?.length > 0 ? "multipleRow" : ""
              }
            />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EngravingWaitingList
