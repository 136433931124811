import React, { useState } from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  if (!localStorage.getItem("access_token")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  } else if (
    (window.location.pathname === "/user-list" &&
      JSON.parse(localStorage.getItem("authUserV0"))?.roleId > 2) ||
    (window.location.pathname === "/main-company-list" &&
      JSON.parse(localStorage.getItem("authUserV0"))?.roleId !== 8) ||
    (window.location.pathname === "/order-list" &&
      JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 8)
  ) {
    return (
      <Navigate
        to={{ pathname: "/not-found-403", state: { from: props.location } }}
      />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
