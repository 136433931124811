import React, { useEffect, useState } from "react"
import { Select, Input, Form, Modal } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import "toastr/build/toastr.min.css"
const UpsertModal = ({
  upsertModal,
  setUpsertModal,
  getData,
  setLoading,
  companyList,
  companyId,
}) => {
  const [refForm] = Form.useForm()

  useEffect(() => {
    let data = upsertModal.data
    refForm.setFieldsValue({
      companyId: companyId,
    })
    if (data?.id) {
      refForm.setFieldsValue({
        firstName: data.nameSurname.split(" ")[0],
        lastName: data.nameSurname.split(" ")[1],
        email: data.email,
        phoneNumber: data.phoneNumber,
        companyId: companyId,
      })
    }
  }, [])

  const handleSave = values => {
    setLoading(true)
    const obj = {
      userId: upsertModal.data ? upsertModal.data.id : "",
      email: values.email,
      firstName: values.firstName,
      mobilePhone: values.phoneNumber,
      lastName: values.lastName,
      password: values.password ? values.password : "",
      confirmPassword: values.confirmPassword ? values.confirmPassword : "",
      companyId: values.companyId,
    }
    if (upsertModal.data?.id) {
      Http.post("api/users/UpdateCompanyAdmin", obj)
        .then(res => {
          if (res.success) {
            toastr.success(
              `${values.firstName} ${values.lastName} company admin has been update.`
            )
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            setLoading(false)
            toastr.error(
              `${values.firstName} ${values.lastName} company admin update failed`
            )
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.title)
        })
    } else {
      delete obj.userId
      Http.post("api/users/CreateCompanyAdmin", obj)
        .then(res => {
          if (res.success) {
            toastr.success(
              `${values.firstName} ${values.lastName} company admin has been create.`
            )
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            setLoading(false)
            toastr.error(
              `${values.firstName} ${values.lastName} company admin create failed`
            )
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.Message)
        })
    }
  }
  const handleCancel = () => {
    setUpsertModal({ show: false, barcode: null })
  }

  return (
    <Modal
      title={
        upsertModal.data?.id ? (
          <span style={{ display: "flex", alignItems: "center" }}>
            Edit Company Admin
            <span
              style={{
                opacity: "0.6",
                fontSize: "13px",
                marginLeft: "8px",
              }}
            >
              [ {upsertModal.data?.nameSurname} ]
            </span>
          </span>
        ) : (
          "Add Company Admin"
        )
      }
      maskClosable={false}
      open={true}
      centered
      onCancel={handleCancel}
      okText="Save"
      onOk={() =>
        refForm
          .validateFields()
          .then(val => {
            handleSave(val)
          })
          .catch(err => {})
      }
      cancelText="Close"
    >
      <Form
        form={refForm}
        style={{ marginTop: 25 }}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          companyId: companyId,
        }}
      >
        <Form.Item
          label="Company"
          name="companyId"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            options={companyList}
            disabled={upsertModal.data?.id}
          />
        </Form.Item>
        <Form.Item
          label="Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Surname"
          name="lastName"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Input type="email" disabled={upsertModal.data?.id} />
        </Form.Item>
        <Form.Item label="Mobile Phone" name="phoneNumber">
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          autocomplete="off"
          rules={[
            {
              required: upsertModal.data?.id ? false : true,
              message: "This field is required!",
            },
          ]}
        >
          <Input.Password
            placeholder="input password"
            autocomplete="off"
            iconRender={visible =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: upsertModal.data?.id ? false : true,
              message: "This field is required!",
            },
          ]}
        >
          <Input.Password
            placeholder="input password"
            iconRender={visible =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpsertModal
