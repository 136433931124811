import React, { useEffect, useState } from "react"
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
} from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Maps from "components/map"
import * as moment from "moment"
const { TextArea } = Input
const stateCodeList = [
  { value: "AL", label: "Alabama (AL)" },
  { value: "AK", label: "Alaska (AK)" },
  { value: "AZ", label: "Arizona (AZ)" },
  { value: "AR", label: "Arkansas (AR)" },
  { value: "CA", label: "California (CA)" },
  { value: "CO", label: "Colorado (CO)" },
  { value: "CT", label: "Connecticut (CT)" },
  { value: "DE", label: "Delaware (DE)" },
  { value: "DC", label: "District Of Columbia (DC)" },
  { value: "FL", label: "Florida (FL)" },
  { value: "GA", label: "Georgia (GA)" },
  { value: "HI", label: "Hawaii (HI)" },
  { value: "ID", label: "Idaho (ID)" },
  { value: "IL", label: "Illinois (IL)" },
  { value: "IN", label: "Indiana (IN)" },
  { value: "IA", label: "Iowa (IA)" },
  { value: "KS", label: "Kansas (KS)" },
  { value: "KY", label: "Kentucky (KY)" },
  { value: "LA", label: "Louisiana (LA)" },
  { value: "ME", label: "Maine (ME)" },
  { value: "MD", label: "Maryland (MD)" },
  { value: "MA", label: "Massachusetts (MA)" },
  { value: "MI", label: "Michigan (MI)" },
  { value: "MN", label: "Minnesota (MN)" },
  { value: "MS", label: "Mississippi (MS)" },
  { value: "MO", label: "Missouri (MO)" },
  { value: "MT", label: "Montana (MT)" },
  { value: "NE", label: "Nebraska (NE)" },
  { value: "NV", label: "Nevada (NV)" },
  { value: "NH", label: "New Hampshire (NH)" },
  { value: "NJ", label: "New Jersey (NJ)" },
  { value: "NM", label: "New Mexico (NM)" },
  { value: "NY", label: "New York (NY)" },
  { value: "NC", label: "North Carolina (NC)" },
  { value: "ND", label: "North Dakota (ND)" },
  { value: "OH", label: "Ohio (OH)" },
  { value: "OK", label: "Oklahoma (OK)" },
  { value: "OR", label: "Oregon (OR)" },
  { value: "PA", label: "Pennsylvania (PA)" },
  { value: "RI", label: "Rhode Island (RI)" },
  { value: "SC", label: "South Carolina (SC)" },
  { value: "SD", label: "South Dakota (SD)" },
  { value: "TN", label: "Tennessee (TN)" },
  { value: "TX", label: "Texas (TX)" },
  { value: "UT", label: "Utah (UT)" },
  { value: "VT", label: "Vermont (VT)" },
  { value: "VA", label: "Virginia (VA)" },
  { value: "WA", label: "Washington (WA)" },
  { value: "WV", label: "West Virginia (WV)" },
  { value: "WI", label: "Wisconsin (WI)" },
  { value: "WY", label: "Wyoming (WY)" },
]

const UpsertModal = props => {
  const [refForm] = Form.useForm()
  const [addressInfo, setAddressInfo] = useState(null)
  useEffect(() => {
    refForm.setFieldsValue({
      country: "USA",
    })
    if (props.upsertModal?.data?.id) {
      let formData = props.upsertModal?.data
      let findStateCode = stateCodeList.find(
        x => x.value === formData?.shipmentDetail?.stateCode
      )
      refForm.setFieldsValue({
        sku: formData?.sku,
        orderId: formData?.orderId,
        variant: formData?.variant,
        city: formData?.shipmentDetail?.city,
        quantity: formData?.quantity,
        stateCode: findStateCode?.value,
        nameSurname: formData?.shipmentDetail?.nameSurname,
        saleDate: new moment(formData.saleDate),
        zipCode: formData?.shipmentDetail?.zipCode,
        addressLine1: formData?.shipmentDetail?.addressLine1,
        addressLine2: formData?.shipmentDetail?.addressLine2,
        transactionId: formData?.transactionId,
        price: formData.price,
      })
    }
  }, [])

  useEffect(() => {
    if (addressInfo?.value?.place_id) {
      refForm.setFieldsValue({
        city:
          addressInfo?.value?.terms.length === 4
            ? addressInfo?.value?.terms[1]?.value
            : addressInfo?.value?.terms.length === 5
            ? addressInfo?.value?.terms[2]?.value
            : addressInfo?.value?.terms.length === 6
            ? addressInfo?.value?.terms[3]?.value
            : "",
        addressLine1:
          addressInfo?.value?.terms.length === 4
            ? addressInfo?.value?.terms[0]?.value
            : addressInfo?.value?.terms.length === 5
            ? addressInfo?.value?.terms[0]?.value +
              " " +
              addressInfo?.value?.terms[1]?.value
            : addressInfo?.value?.terms.length === 6
            ? addressInfo?.value?.terms[0]?.value +
              " " +
              addressInfo?.value?.terms[1]?.value
            : "",
        addressLine2:
          addressInfo?.value?.terms.length === 6
            ? addressInfo?.value?.terms[2]?.value
            : "",
        stateCode:
          addressInfo?.value?.terms.length === 4
            ? addressInfo?.value?.terms[2]?.value
            : addressInfo?.value?.terms.length === 5
            ? addressInfo?.value?.terms[3]?.value
            : addressInfo?.value?.terms.length === 6
            ? addressInfo?.value?.terms[4]?.value
            : "",
      })
    }
  }, [addressInfo])

  const onFinish = () => {
    const formValues = refForm.getFieldsValue()
    const obj = {
      id: props.upsertModal?.data?.id,
      recordName: props?.upsertModal?.data?.recordName,
      ...formValues,
      country: "USA",
      saleDate:
        moment.utc(formValues.saleDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
    }

    delete obj.searchMaps
    delete obj.price
    if (props.upsertModal?.data?.id) {
      delete obj.recordName
      Http.post("api/orders/updateEngraving", obj)
        .then(res => {
          if (res.success) {
            toastr.success(`Process Success.`)
            props.setLoading(false)
            props.onClose(true)
          } else {
            props.setLoading(false)
            toastr.success(`Process Fail.`)
          }
        })
        .catch(function (error) {
          props.setLoading(false)
          toastr.error(error.response.data.title)
        })
      //hgüncelleme
    } else {
      delete obj.id
      Http.post("api/orders/CreateApparel", obj)
        .then(res => {
          if (res.success) {
            toastr.success(`Process Success.`)
            props.setLoading(false)
            props.onClose(true)
          } else {
            props.setLoading(false)
            toastr.success(`Process Fail.`)
          }
        })
        .catch(function (error) {
          props.setLoading(false)
          toastr.error(error.response.data.title)
        })
    }
  }

  return (
    <Modal
      centered
      cancelText="Close"
      width={950}
      confirmLoading={props.loading}
      maskClosable={false}
      title={
        props.upsertModal?.data?.id ? (
          <>
            Edit Order{" "}
            <span style={{ fontSize: 14 }}>
              <span style={{ opacity: "0.3" }}>[ Order: </span>
              {props.upsertModal?.data?.orderId}{" "}
              <span style={{ opacity: "0.3" }}>| Sku: </span>
              {props.upsertModal?.data?.sku}
              <span style={{ opacity: "0.3" }}> ]</span>
            </span>
          </>
        ) : (
          "Add New Order"
        )
      }
      open={props.upsertModal.show}
      onOk={() => refForm.validateFields().then(val => onFinish(val))}
      okText="Save"
      onCancel={() => props.onClose(false)}
    >
      <Form
        form={refForm}
        layout="vertical"
        onSubmit={e => {
          e.preventDefault()
          return false
        }}
      >
        <Row gutter={[16, 16]}>
          <Col lg={8}>
            <Form.Item
              label="SKU"
              name="sku"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label="Order No"
              name="orderId"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label="Transaction Id"
              name="transactionId"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={8}>
            <Form.Item
              label="Name Surname"
              name="nameSurname"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item label="Price" name="price">
              <InputNumber min={0} style={{ width: "100%" }} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={8}>
            <Form.Item
              label="Sale Date"
              name="saleDate"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format={"DD.MM.YYYY"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={24}>
            <Form.Item
              label="Variant"
              name="variant"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={24}>
            <Form.Item label="Search Address" name="searchMaps">
              <Maps setValue={setAddressInfo} value={addressInfo} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={6}>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                disabled
                options={[
                  { value: "USA", label: "United States of America (USA)" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              label="State"
              name="stateCode"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select options={stateCodeList} />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              label="ZipCode"
              name="zipCode"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item label="Address" name="addressLine1">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item label="Apartment/Suit" name="addressLine2">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default UpsertModal
