import React, { useState, useEffect, useRef } from "react"
import {
  Form,
  Spin,
  Input,
  Row,
  Col,
  Card,
  Descriptions,
  Divider,
  Progress,
  Table,
  Button,
  Typography,
} from "antd"
import * as Http from "utils/http.helper"
import Breadcrumbs from "components/Common/Breadcrumb"
import ImageComponent from "components/image"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { CheckSquareFilled, CloseSquareFilled } from "@ant-design/icons"
import tiff from "assets/images/tiff.png"
import pdfImage from "assets/images/pdf.png"
const { Paragraph } = Typography
import axios from "axios"
import { saveAs } from "file-saver"
const UVDTFPrinter = () => {
  //meta title
  document.title = "UVDTF Printer Page | EASY JET CONNECT"
  const [form] = Form.useForm()
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(null)

  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [])

  const handleInputChange = e => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      getOrder(e.target.value)
      setTimeout(() => {
        inputRef.current.focus()
      }, 500)
    }
  }

  const handleInputBlur = e => {
    if (e.target.value.length > 0) {
      getOrder(e.target.value)
    }
    setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 750)
  }

  const getOrder = value => {
    setLoading(true)
    form.setFieldsValue({
      barcode: "",
    })
    Http.get(`api/dtf/GetDetailByProductName?productName=${value}&CategoryId=4`)
      .then(response => {
        if (response) {
          setOrder(response)
          onChangeStatus(response?.id)
          setLoading(false)
        } else {
          setLoading(false)
          toastr.error(
            `${value} numaralı order henüz print etmek için hazır olmayabilir ve ya order numaranız hatalı olabilir. Kontrol ediniz!`
          )
        }
        setLoading(false)
      })
      .catch(function (error) {
        toastr.error(error?.response?.data?.Message)
        setLoading(false)
      })
  }

  const columns = [
    {
      title: "Image",
      dataIndex: "imgUrl",
      align: "center",
      render: value => {
        return (
          <ImageComponent
            imageUrl={!value.includes("tif") ? value : tiff}
            preview={
              !value.includes("tif")
                ? !value.includes("pdf")
                  ? true
                  : false
                : false
            }
            width={!value.includes("tif") ? 40 : 70}
            height={!value.includes("tif") ? 45 : 75}
          />
        )
      },
    },
    {
      title: "Length",
      dataIndex: "length",
      align: "center",
      render: value => {
        return `${value} inc`
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      align: "center",
      render: value => {
        return `${value} $`
      },
    },
  ]

  const onChangeStatus = id => {
    let obj = {
      id: id,
      categoryId: 4,
      status: 40,
    }
    Http.post(`api/orders/ChangeDtfUvdtfOrderStatus`, obj)
  }

  const [downProgress, setDownProgress] = useState([])
  const [downModal, setDownModal] = useState(false)

  const downloadImageOne = async (imageUrl, name, index, row) => {
    const urlParts = imageUrl.split("/")
    const bucket = urlParts[2].split(".")[0]
    const key = urlParts.slice(3).join("/")
    const s3Url = `https://${bucket}.s3.amazonaws.com/${key}`

    try {
      const response = await axios({
        url: s3Url,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: evt => {
          let prg = Math.round((evt.loaded / evt.total) * 100)
          setDownProgress(prevProgress => {
            const newProgress = [...prevProgress]
            const existingIndex = newProgress.findIndex(p => p.id === index)
            if (existingIndex >= 0) {
              newProgress[existingIndex].value = prg
            } else {
              newProgress.push({
                id: index,
                name: imageUrl.split("dtf/")[1],
                value: prg,
              })
            }
            return newProgress
          })
        },
      })

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      })
      saveAs(blob, name + "_" + key.split("/").pop())
      setDownModal(false)
      setDownProgress([])
    } catch (error) {
      console.error("Download error:", error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        {downModal && downProgress.length > 0 && (
          <Card className="my_card">
            <Divider orientation="left">File Download Process Info</Divider>

            {downProgress.map(item => {
              return (
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 10,
                  }}
                >
                  <div>
                    <Progress
                      percent={item.value}
                      style={{ minWidth: "300px" }}
                    />
                  </div>
                  <div>{item.name}</div>
                </div>
              )
            })}
          </Card>
        )}
        <Spin spinning={loading}>
          <Breadcrumbs title="UVDTF Printer" />
          <Card className="mt-3" hoverable>
            <Form form={form} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col lg={8}>
                  <Form.Item
                    label="Product Name Or Order Number"
                    name="barcode"
                  >
                    <Input
                      ref={inputRef}
                      onPressEnter={e => handleInputChange(e)}
                      onBlur={e => handleInputBlur(e)}
                      onKeyUp={e => handleInputChange(e)}
                      onChange={e => handleInputChange(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {order && (
              <Row gutter={[16, 16]}>
                <Col lg={6}>
                  <Card hoverable>
                    <Descriptions bordered column={1}>
                      <Descriptions.Item
                        label={
                          <span style={{ fontWeight: "bold" }}>
                            Product Name
                          </span>
                        }
                      >
                        <span style={{ fontWeight: "bold" }}>
                          <Paragraph copyable>{order?.productName}</Paragraph>
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <span style={{ fontWeight: "bold" }}>
                            Customer Name
                          </span>
                        }
                      >
                        {order?.customerName}
                      </Descriptions.Item>

                      <Descriptions.Item
                        label={
                          <span style={{ fontWeight: "bold" }}>Pickup</span>
                        }
                      >
                        {order.pickUp ? (
                          <CheckSquareFilled
                            style={{ color: "#22bd37", fontSize: 24 }}
                          />
                        ) : (
                          <CloseSquareFilled
                            style={{ color: "#fb2525", fontSize: 24 }}
                          />
                        )}
                      </Descriptions.Item>

                      {order?.labelFile && (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>Label</span>
                          }
                        >
                          {order?.labelFile.split(".")[
                            order?.labelFile.split(".").length - 1
                          ] === "pdf" ? (
                            <Button
                              href={order?.labelFile}
                              download
                              className="pdf_download"
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "none",
                              }}
                            >
                              <img
                                src={pdfImage}
                                alt=""
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  maxWidth: "60px",
                                }}
                              />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                setDownModal(true)
                                downloadImageOne(
                                  order?.labelFile,
                                  order?.labelFile.split("dtf/")[1],
                                  0,
                                  order?.id
                                )
                              }}
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "none",
                              }}
                            >
                              <ImageComponent
                                width={
                                  !order?.labelFile.includes("tif") ? 100 : 60
                                }
                                height={75}
                                imageUrl={
                                  !order?.labelFile.includes("tif")
                                    ? order?.labelFile
                                    : tiff
                                }
                                preview={false}
                              />
                            </Button>
                          )}
                        </Descriptions.Item>
                      )}
                      {order?.note.length > 0 && (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>Note</span>
                          }
                        >
                          {order?.note}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </Card>
                </Col>
                <Col lg={18}>
                  <Card hoverable>
                    <Table
                      title={() => "Images List"}
                      bordered
                      size="small"
                      columns={columns}
                      dataSource={order.files}
                    />
                  </Card>
                </Col>
              </Row>
            )}
          </Card>
        </Spin>
      </div>{" "}
    </React.Fragment>
  )
}

export default UVDTFPrinter
