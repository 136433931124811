import React, { useState, useEffect, useRef } from "react"
import {
  Table,
  Button,
  Tag,
  Result,
  Collapse,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Select,
} from "antd"
import * as Http from "utils/http.helper"
import UpsertModal from "./modals/upsert"
import toastr from "toastr"
import ImageComponent from "components/image"
import * as moment from "moment"
import { useNavigate } from "react-router-dom"
import "toastr/build/toastr.min.css"
import { SearchOutlined, ClearOutlined } from "@ant-design/icons"
const { Panel } = Collapse
const css = `
.first_row td{
  font-weight:bold;
}
.interior_row td{
  font-weight:bold;
}
.type_row td{
  font-weight:bold;
}

`
const EngravingTemplateImage = () => {
  document.title = "Engraving Image List  | EASY JET CONNECT"
  const [filterForm] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [data, setData] = useState([])
  const [disabled, setDisabled] = useState({
    productName: false,
    sku: false,
    priceCheck: false,
  })
  const [productNameList, setProductNameList] = useState([])
  const [upsertModal, setUpsertModal] = useState({
    show: false,
    data: null,
  })
  const getData = () => {
    setLoading(true)
    Http.get(`api/orders/GetLeatherImages`)
      .then(res => {
        if (res?.success && res.data.length > 0) {
          setData(res.data.reverse())
          setProductNameList(
            res.data.map(item => {
              return { label: item.productName, value: item.key }
            })
          )
          setLoading(false)
        } else {
          setData([])
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: value => {
        if (value === 10) {
          return "Front"
        } else if (value === 20) {
          return "Back"
        } else if (value === 30) {
          return "Interior"
        } else if (value === 40) {
          return "Spine"
        } else if (value === 50) {
          return "Tools"
        } else if (value === 60) {
          return "Stones"
        } else if (value === 70) {
          return "Guitar Pick"
        }
      },
    },
    {
      title: "HexCode",
      dataIndex: "colorHexCode",
      key: "colorHexCode",
      render: value => {
        return value ? <Tag>{value}</Tag> : null
      },
    },
    {
      title: "Design Positions",
      dataIndex: "positions",
      key: "positions",
      render: (value, row) => {
        if (value) {
          return value.map((item, index) => {
            return (
              <Tag key={index} value={item}>
                {item}
              </Tag>
            )
          })
        } else {
          return ""
        }
      },
    },
    {
      title: "Template Images",
      dataIndex: "imgUrl",
      key: "imgUrl",
      align: "center",
      render: (value, row, index) => {
        return value ? (
          <ImageComponent imageUrl={value} width={"100%"} height={"70px"} />
        ) : row.productName ? (
          <ImageComponent
            imageUrl={row.children[0].children[0].children[0].imgUrl}
            width={"100%"}
            height={"70px"}
          />
        ) : (
          ""
        )
        // if (value.split(".")[value.split(".").length - 1] === "svg") {
        //   return (
        //     <>
        //       <style>{`
        //       .svg${index} .st0,
        //       .svg${index} .st1,
        //       .svg${index} .st_0,
        //       .svg${index} .st_1
        //       {
        //         fill:${row.colorHexCode}!important
        //       }`}</style>

        //       <SvgComponent
        //         svgUrl={value}
        //         className={"svg" + index}
        //         width="75px"
        //         height="75px"
        //       />
        //     </>
        //   )
        // } else {
        //   return <div style={{ fontWeight: 700 }}>NO SVG</div>
        // }
      },
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, row) => {
        return row.price
          ? row.price + " $"
          : row.productName
          ? row.children[0].children[0].children[0].price + " $"
          : ""
      },
    },
    {
      title: "Add Date",
      dataIndex: "addDate",
      key: "addDate",
      render: (value, row) => {
        return value
          ? moment(new Date(value)).format("DD MMM YYYY")
          : row.productName
          ? moment(
              new Date(row.children[0].children[0].children[0].addDate)
            ).format("DD MMM YYYY")
          : ""
      },
    },
    // {
    //   title: " ",
    //   key: "action",
    //   align: "center",
    //   width: 120,
    //   render: (_, record) => (
    //     <div className="d-flex align-items-center justify-content-center gap-1">
    //       <Tooltip placement="top" title="Delete">
    //         <Button
    //           className="btn btn-primary d-flex align-items-center justify-content-center"
    //           icon={<i className="mdi mdi-pencil" />}
    //           onClick={() => {
    //             setUpsertModal({ show: true, data: record })
    //           }}
    //         />
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ]
  const tableColumns = columns.map(item => ({
    ...item,
    ellipsis: "enable",
  }))
  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="If you haven't selected any company or added financial data yet, you can select and list a company or create a new financial value."
      />
    ),
  }

  const onSearch = () => {
    const formValues = filterForm.getFieldsValue()
    setData([])
    setLoading(true)
    Http.get(`api/orders/GetLeatherImages`)
      .then(res => {
        if (res?.success && res.data.length > 0) {
          if (formValues?.productName) {
            let filter = res.data.filter(x => x.key === formValues.productName)
            setData(filter)
          } else if (formValues?.sku?.length > 0) {
            let find = []
            res.data.map((item, index) => {
              return item.children.map(x => {
                if (
                  x.sku.toLowerCase().includes(formValues.sku.toLowerCase())
                ) {
                  find.push(res.data[index])
                }
              })
            })
            find.length > 0 && setData(find)
          } else if (checked) {
            let find = []
            res.data.map((item, index) => {
              item.children.map(x => {
                x.children.map(y => {
                  y.children.map(z => {
                    if (z.price === null || z.price === "" || !z.price) {
                      find.push(res.data[index])
                    }
                  })
                })
              })
            })
            find.length > 0 && setData(find)
          } else {
            setData(res.data)
          }
          setLoading(false)
        } else {
          setData([])
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }
  return (
    <React.Fragment>
      <style>{css}</style>
      <div className="page-content mb-3">
        {upsertModal.show && (
          <UpsertModal
            upsertModal={upsertModal}
            setUpsertModal={setUpsertModal}
            loading={loading}
            setLoading={setLoading}
            getData={getData}
          />
        )}

        <Collapse activeKey={["1"]} expandIcon={() => false}>
          <Panel
            key="1"
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Engraving Image List
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    columnGap: "15px",
                  }}
                >
                  <div>
                    <Button
                      type="button"
                      className="btn btn-primary d-flex align-items-center justify-content-end"
                      onClick={() => navigate("/add-engraving-image")}
                      icon={
                        <i className="bx bx-plus font-size-16 align-middle"></i>
                      }
                    >
                      Add Engraving Image
                    </Button>
                  </div>
                </div>
              </div>
            }
          >
            <Form form={filterForm} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col lg={4}>
                  <Form.Item label="Product Name" name="productName">
                    <Select
                      placeholder="Select a chose"
                      showSearch
                      allowClear
                      options={productNameList}
                      disabled={
                        productNameList.length === 0
                          ? true
                          : disabled.productName
                      }
                      onChange={e => {
                        if (e) {
                          setDisabled({
                            ...disabled,
                            sku: true,
                            priceCheck: true,
                          })
                        } else {
                          setDisabled({
                            ...disabled,
                            sku: false,
                            priceCheck: false,
                          })
                        }
                      }}
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item label="Sku" name="sku">
                    <Input
                      allowClear
                      disabled={disabled.sku}
                      onChange={e => {
                        if (e.target.value) {
                          setDisabled({
                            ...disabled,
                            productName: true,
                            priceCheck: true,
                          })
                        } else {
                          setDisabled({
                            ...disabled,
                            productName: false,
                            priceCheck: false,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item label=" " name="priceCheck">
                    <Checkbox
                      checked={checked}
                      disabled={disabled.priceCheck}
                      onClick={e => {
                        setChecked(!e.target.checked)
                        if (!e.target.checked) {
                          setDisabled({
                            ...disabled,
                            productName: true,
                            sku: true,
                          })
                        } else {
                          setDisabled({
                            ...disabled,
                            productName: false,
                            sku: false,
                          })
                        }
                      }}
                    >
                      Do not have a price info
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col
                  span={3}
                  className="d-flex align-items-center justify-content-start gap-2"
                >
                  <Button
                    type="button"
                    className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                    icon={<SearchOutlined />}
                    onClick={() => {
                      filterForm
                        .validateFields()
                        .then(() => {
                          onSearch()
                        })
                        .catch(err => {})
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                    icon={<ClearOutlined />}
                    onClick={() => {
                      filterForm.setFieldsValue({
                        sku: "",
                        productName: "",
                      })
                      setDisabled({
                        productName: false,
                        sku: false,
                        priceCheck: false,
                      })
                      setChecked(false)
                    }}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              size="small"
              bordered
              columns={tableColumns}
              dataSource={data}
              className="table table-centered"
              loading={loading}
              locale={locale}
              rowKey={record => record.key}
              rowClassName={record =>
                record.productName?.length > 0
                  ? "first_row"
                  : record.sku?.length > 0
                  ? "interior_row"
                  : record?.type > 0
                  ? "type_row"
                  : "end_row"
              }
            />
          </Panel>
        </Collapse>
      </div>
    </React.Fragment>
  )
}

export default EngravingTemplateImage
