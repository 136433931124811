import React, { useState, useEffect } from "react"
import { Card, CardBody, CardSubtitle, Container } from "reactstrap"
import * as moment from "moment"
import Papa from "papaparse"
import Dropzone from "react-dropzone"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { useLocation, useNavigate } from "react-router-dom"
import { Table, Button, Input, Spin, Form, Space, Modal, Col, Row } from "antd"

const allowedExtensions = ["csv"]
const css = `
.ant-form-item{
    margin-bottom:0!important;
}
.custom_btn{
  background: #ffffff;
    color: #0958d9;
    border-color: #0958d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    border-style: dashed;
}`

const EngravingAdd = () => {
  document.title = "Engraving Add | EASY JET CONNECT"
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState("")
  const [file, setFile] = useState("")
  const [formV1] = Form.useForm()
  const [fileName, setFileName] = useState(null)
  const [questionModal, setQuestionModal] = useState(false)
  const [haveOrderBefore, setHaveOrderBefore] = useState(true)
  const [orderArrayList, setOrderArrayList] = useState([])
  const [notSaveList, setNotSaveList] = useState([])

  const columns = [
    {
      title: "SKU",
      dataIndex: "sku",
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Variations",
      dataIndex: "variant",
      width: 800,
    },
    {
      title: "Sale Date",
      dataIndex: "saleDate",
      render: (_, row) => {
        return moment.utc(row.saleDate).format("DD.MM.YYYY")
      },
    },
  ]

  useEffect(() => {
    Http.get(`api/orders/HaveOrderBefore?categoryId=3`)
      .then(res => {
        if (res?.success) {
          setHaveOrderBefore(!res.data)
        }
      })
      .catch(function (error) {})
  }, [])

  const handleFileChange = e => {
    setLoading(true)
    setData([])
    setFile(null)
    setError("")
    if (e.length > 0) {
      const inputFile = e[0]
      const fileExtension = inputFile?.type.split("/")[1]
      if (!allowedExtensions.includes(fileExtension)) {
        setLoading(false)
        setError("Please input a csv file")
        return
      }
      setFile(inputFile)
      setLoading(false)
    } else {
      setLoading(false)
      setError("Please input a csv file")
    }
  }

  const isFutureDate = dateString => {
    const today =
      state?.maxDate != "null" ? new Date(state?.maxDate) : new Date()
    state?.maxDate === "null" && today.setHours(0, 0, 0, 0)
    const [day, month, year] = dateString.split(".").map(Number)
    const date = new Date(year, month - 1, day)
    if (date >= today) {
      return true
    } else {
      return false
    }
  }

  const handleParse = value => {
    setQuestionModal(false)
    // value => true --> onaylıyor kaydedilmiyecek geçmiş
    setLoading(true)
    if (!file) return alert("Enter a valid file")
    const reader = new FileReader()
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
      })
      const parsedData = csv?.data
      const columns = []
      const notSaveList = []
      let control = true
      let dateProblem = true
      try {
        if (value) {
          parsedData.map((item, index) => {
            const today = new Date()
            const yearStart = today.getFullYear().toString().slice(0, 2)
            let csvYear =
              item["Sale Date"].split("/")[2].length === 4
                ? item["Sale Date"].split("/")[2]
                : yearStart + item["Sale Date"].split("/")[2]
            let csvMonth = item["Sale Date"].split("/")[0]
            let csvDay = item["Sale Date"].split("/")[1]
            let newDate = csvDay + "." + csvMonth + "." + csvYear
            if (isFutureDate(newDate)) {
              if (!orderArrayList.includes(item["Order ID"])) {
                if (item["SKU"] && item["Sale Date"] && item["Order ID"]) {
                  columns.push({
                    index: index,
                    sku: item["SKU"],
                    saleDate:
                      moment.utc(item["Sale Date"]).format("YYYY-MM-DD") +
                      "T00:00:00.000Z",
                    itemName: item["Item Name"],
                    quantity: item["Quantity"],
                    transactionId: item["Transaction ID"],
                    variant: item["Variations"],
                    orderId: item["Order ID"],
                    shipmentOrder: {
                      nameSurname: item["Ship Name"],
                      city: item["Ship City"],
                      addressLine1: item["Ship Address1"],
                      addressLine2: item["Ship Address2"],
                      zipCode: item["Ship Zipcode"],
                      stateCode: item["Ship State"],
                      country: item["Ship Country"],
                    },
                  })
                } else {
                  notSaveList.push({
                    index: index,
                    sku: item["SKU"],
                    saleDate:
                      moment.utc(item["Sale Date"]).format("YYYY-MM-DD") +
                      "T00:00:00.000Z",
                    itemName: item["Item Name"],
                    quantity: item["Quantity"],
                    transactionId: item["Transaction ID"],
                    variant: item["Variations"],
                    orderId: item["Order ID"],
                  })
                }
              } else if (control) {
                control = false
              }
            } else if (dateProblem) {
              dateProblem = false
            }
          })
        } else {
          parsedData.map((item, index) => {
            const today = new Date()
            const yearStart = today.getFullYear().toString().slice(0, 2)
            let csvYear =
              item["Sale Date"].split("/")[2].length === 4
                ? item["Sale Date"].split("/")[2]
                : yearStart + item["Sale Date"].split("/")[2]
            let csvMonth = item["Sale Date"].split("/")[0]
            let csvDay = item["Sale Date"].split("/")[1]
            let newDate = csvDay + "." + csvMonth + "." + csvYear
            if (!state?.maxDate || isFutureDate(newDate)) {
              if (!orderArrayList.includes(item["Order ID"])) {
                if (item["SKU"] && item["Sale Date"] && item["Order ID"]) {
                  columns.push({
                    index: index,
                    sku: item["SKU"],
                    saleDate:
                      moment.utc(item["Sale Date"]).format("YYYY-MM-DD") +
                      "T00:00:00.000Z",
                    itemName: item["Item Name"],
                    quantity: item["Quantity"],
                    transactionId: item["Transaction ID"],
                    variant: item["Variations"],
                    orderId: item["Order ID"],
                    shipmentOrder: {
                      nameSurname: item["Ship Name"],
                      city: item["Ship City"],
                      addressLine1: item["Ship Address1"],
                      addressLine2: item["Ship Address2"],
                      zipCode: item["Ship Zipcode"],
                      stateCode: item["Ship State"],
                      country: item["Ship Country"],
                    },
                  })
                } else {
                  notSaveList.push({
                    index: index,
                    sku: item["SKU"],
                    saleDate:
                      moment.utc(item["Sale Date"]).format("YYYY-MM-DD") +
                      "T00:00:00.000Z",
                    itemName: item["Item Name"],
                    quantity: item["Quantity"],
                    transactionId: item["Transaction ID"],
                    variant: item["Variations"],
                    orderId: item["Order ID"],
                  })
                }
              } else if (control) {
                control = false
              }
            } else if (dateProblem) {
              dateProblem = false
            }
          })
        }
      } catch (error) {
        setLoading(false)
      }
      if (columns.length > 0) {
        if (!control) {
          toastr.info(
            "Yüklemek istediğiniz dosyadaki siparişlere ait order numaraları daha önce kaydedilmiş olanlar kaydedilmemiştir."
          )
        }

        setData(columns)
        if (notSaveList.length > 0) {
          setNotSaveList(notSaveList)
        }
      } else {
        if (!dateProblem) {
          toastr.error(
            "Yüklemek istediğiniz dosyadaki siparişlerin satış günü bu günden daha eski olduğu için kaydedilmeyecektir."
          )
        } else if (!control) {
          toastr.info(
            "Yüklemek istediğiniz dosyadaki siparişlere ait order numaraları daha önce kaydedilmiştir."
          )
        } else {
          toastr.error(
            "Beklenmedik bir hata! Yüklediğiniz dosya ve login olduğunuz kullanıcı adı ile it birimiyle iletişime geçiniz."
          )
        }
      }
      setLoading(false)
    }
    reader.readAsText(file)
  }

  const handleSaveCsv = () => {
    setLoading(true)
    const recordName = moment(new Date()).format("DD-MM-YYYY")
    const obj = {
      recordName: recordName,
      orders: [],
    }

    data.map(item => {
      obj.orders.push({
        sku: item.sku,
        saleDate: item.saleDate,
        itemName: item.itemName,
        quantity: parseInt(item.quantity),
        transactionId: item.transactionId,
        variant: item.variant,
        orderId: item.orderId,
        shipmentOrder: {
          nameSurname: item.shipmentOrder.nameSurname,
          city: item.shipmentOrder.city,
          addressLine1: item.shipmentOrder.addressLine1,
          addressLine2: item.shipmentOrder.addressLine2,
          zipCode: item.shipmentOrder.zipCode,
          stateCode: item.shipmentOrder.stateCode,
          country: item.shipmentOrder.country,
        },
      })
    })
    Http.post("api/orders/CreateMultipleLeatherOrder", obj)
      .then(res => {
        if (res.success) {
          toastr.success(
            `Csv file upload has been success. It may take some time for the data to be saved.`
          )
          setTimeout(() => {
            toastr.info("Listeye yönlendiriliyorsunuz. Lütfen bekleyiniz.")
            setTimeout(() => {
              setLoading(false)
              navigate("/engraving-list")
            }, 500)
          }, 500)
        } else {
          setLoading(false)
          toastr.error(res?.Message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error?.response?.data?.Message)
      })
  }

  useEffect(() => {
    Http.get(`api/orders/getOldAddedOrders?categoryId=3`)
      .then(res => {
        if (res?.success) {
          res.data.length > 0 && setOrderArrayList(res.data)
        }
      })
      .catch(function (error) {})
  }, [])

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        {questionModal && (
          <Modal
            title="Dikkat"
            open={true}
            centered
            maskClosable={false}
            onOk={() => {
              handleParse(true)
            }}
            onCancel={() => {
              handleParse(false)
            }}
            okText="Yes"
            cancelText="No"
          >
            <p>
              İlk defa veri kaydediyorsunuz. Bu günden önceki kayıtlarınız
              kaydedilmeyecektir. Onaylıyor musunuz?
            </p>
          </Modal>
        )}
        <style>{css}</style>
        <Spin spinning={loading}>
          <Container fluid>
            <Card>
              <CardBody>
                <Row
                  gutter={[16, 16]}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col lg={6}>
                    <h4>Engraving Add</h4>
                  </Col>
                  <Col
                    lg={18}
                    className="d-flex align-items-center justify-content-end gap-2  mb-3"
                  >
                    <Button
                      type="button"
                      className="btn btn_default d-flex align-items-center justify-content-center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setFileName(null)
                        navigate("/engraving-list")
                      }}
                      icon={
                        <i className="bx bx-arrow-back font-size-16 align-middle mr-2"></i>
                      }
                    >
                      Geri Dön
                    </Button>
                    {data.length > 0 && (
                      <Button
                        type="button"
                        className="btn btn-primary d-flex align-items-center justify-content-center mt-1"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setData([])
                          setFileName(null)
                          setNotSaveList([])
                        }}
                        icon={
                          <i className="bx bx-cloud-upload font-size-16 align-middle mr-2"></i>
                        }
                      >
                        Tekrar CSV Yükle
                      </Button>
                    )}
                  </Col>
                  {data.length > 0 && (
                    <>
                      <Col
                        lg={16}
                        className="d-flex align-items-center justify-content-start gap-2  mb-3"
                      >
                        <Row>
                          <Col lg={24}>
                            <Form form={formV1} style={{ width: "100%" }}>
                              <Form.Item
                                label="Record Name:"
                                name="saveNameFile"
                              >
                                <Space.Compact>
                                  <Input
                                    style={{
                                      width: "100%",
                                    }}
                                    disabled
                                    defaultValue={moment(new Date()).format(
                                      "DD-MM-YYYY"
                                    )}
                                  />
                                </Space.Compact>
                              </Form.Item>
                            </Form>
                          </Col>
                          {fileName?.length > 0 && (
                            <Col lg={24}>
                              <span
                                style={{ fontWeight: "bold", marginLeft: 10 }}
                              >
                                Record Name:
                              </span>{" "}
                              {fileName}
                            </Col>
                          )}
                        </Row>
                      </Col>
                      {
                        <Col
                          lg={8}
                          className="d-flex align-items-center justify-content-end gap-2  mb-3"
                        >
                          <Button
                            type="button"
                            className="btn btn_success d-flex align-items-center justify-content-center"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setLoading(true)
                              formV1
                                .validateFields()
                                .then(() => {
                                  handleSaveCsv()
                                })
                                .catch(err => {
                                  setLoading(false)
                                })
                            }}
                            loading={loading}
                          >
                            Save CSV ({data.length})
                          </Button>
                        </Col>
                      }
                    </>
                  )}
                </Row>
                <Row gutter={[16, 16]}>
                  {data.length === 0 && (
                    <Col lg={24} className="mb-2">
                      <CardSubtitle className="mb-3">
                        This area provides the ability to upload a drag and drop
                        file containing csv file previews.
                      </CardSubtitle>
                      <Form>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleFileChange(acceptedFiles)
                          }}
                          multiple={false}
                          accept={{
                            "file/csv": [".csv"],
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input type="file" {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload " />
                                </div>
                                {file.name ? (
                                  <h6 style={{ color: "red" }}>
                                    Upload File Name : {file.name}
                                  </h6>
                                ) : (
                                  <h4>Drop files here or click to upload.</h4>
                                )}
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <Col
                          lg={"12"}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            color="primary"
                            className="btn btn-primary"
                            disabled={data.length > 0 ? true : false}
                            onClick={() => {
                              if (file.type === "text/csv") {
                                setLoading(true)
                                setTimeout(() => {
                                  if (haveOrderBefore) {
                                    setQuestionModal(true)
                                  } else {
                                    handleParse(false)
                                  }
                                }, 300)
                              } else {
                                toastr.error(`Dosya Yükleyiniz!`)
                              }
                            }}
                            style={{
                              marginTop: 15,
                              height: 35,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            Parse
                          </Button>
                        </Col>
                      </Form>
                    </Col>
                  )}

                  {error.length > 2 && (
                    <Col lg={24}>
                      <h4 style={{ textAlign: "center", color: "red" }}>
                        {error}
                      </h4>
                    </Col>
                  )}

                  {data.length > 0 && (
                    <Col lg={24}>
                      <Table
                        name="Apparel Listesi"
                        bordered
                        size="small"
                        columns={columns}
                        dataSource={data}
                        className="table table-centered"
                        scroll={{ x: 240 }}
                      />
                    </Col>
                  )}
                  {notSaveList.length > 0 && (
                    <Col lg={24} style={{ color: "red" }}>
                      * {notSaveList.length} adet order eklenememiştir.
                      <span
                        style={{
                          color: "black",
                          opacity: "0.7",
                          marginLeft: 18,
                        }}
                      >
                        Siparişin sku, sale date ve ya order numara bilgisi
                        eksik olabilir.{" "}
                      </span>{" "}
                      <br />* Eklenmeyen order numaraları.{" "}
                      <span
                        style={{
                          color: "black",
                          marginLeft: 8,
                        }}
                      >
                        {notSaveList.map((item, index) => {
                          if (index === notSaveList.length - 1) {
                            return item.orderId
                          } else {
                            return item.orderId + ", "
                          }
                        })}
                      </span>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Container>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default EngravingAdd
