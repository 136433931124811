import React, { useEffect } from "react"
import { Navigate } from "react-router-dom"

// Pages Component
// Profile
import UserProfile from "../pages/Authentication/user-profile"

//Pages User
import UserList from "../pages/CompanyManagement/UserList/index"

// Apparel
import ApparelList from "../pages/Apparel/List/index"
import ApparelPrinter from "../pages/Apparel/Printer/index"
import ApparelFinancial from "../pages/Apparel/Financial/index"

// Engraving
import EngravingList from "../pages/Engraving/List/index"
import DesignUpload from "../pages/Engraving/AddDesign"
import EngravingAdd from "pages/Engraving/AddEngraving/index"
import EngravingFinancial from "../pages/Engraving/Financial/index"
import EngravingPrinter from "../pages/Engraving/Printer/index"

import Company from "../pages/CompanyManagement/Company/index"
import MainCompany from "../pages/CompanyManagement/MainCompany/index"
import MainCompanyUserList from "../pages/CompanyManagement/MainCompanyUserList/index"
import PriceManagement from "../pages/Apparel/PriceManagement"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import Pages404 from "../pages/NotFound/pages-404"
import Pages403 from "../pages/NotFound/pages-403"
import Pages500 from "../pages/NotFound/pages-500"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import PackageList from "pages/Package/ListUpload"
import PackageListDownload from "pages/Package/ListDonwload"
import PackageFinancial from "pages/Package/Financial"
import PackageManagement from "pages/Package/Management"
import UVDTFList from "pages/UvDTF/List"
import DTFList from "pages/DTF/List"
import DTFManagement from "pages/DTF/Management"
import UVDTFManagement from "pages/UvDTF/Management"
import UVDTFFinancial from "pages/UvDTF/Financial"
import StyleColorManagement from "pages/StyleSizeColorManagement/ColorsForStyles"
import ApparelAdd from "pages/Apparel/AddApparel"
import StyleSizeManagement from "pages/StyleSizeColorManagement/SizesForColors"
import StyleManagement from "pages/StyleSizeColorManagement/Style"
import AddTemplateImage from "../pages/Engraving/TemplateImage/addTemplateImage"
import EngravingTemplateImage from "pages/Engraving/TemplateImage"
import EngravingPriceManagement from "pages/Engraving/PriceManagement"
import EngravingStockTracking from "pages/Engraving/StockTracking"
import PaymentManagement from "pages/PaymentManagement"
import EngravingWaitingList from "pages/Engraving/WaitingList"
import DTFFinancial from "pages/DTF/Financial"
import DTFPrinter from "pages/DTF/Printer"
import UVDTFPrinter from "pages/UvDTF/Printer"
import ApparelDesignUpload from "pages/Apparel/AddDesign"
import ApparelWaitingList from "pages/Apparel/WaitingList"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Utility
  {
    path: "/user-list",
    component: <UserList />,
  },
  {
    path: "/main-company-list",
    component: <MainCompany />,
  },
  {
    path: "/main-company-user-list",
    component: <MainCompanyUserList />,
  },
  {
    path: "/price-management",
    component: <PriceManagement />,
  },
  {
    path: "/add-engraving-image",
    component: <AddTemplateImage />,
  },

  {
    path: "/company-list",
    component: <Company />,
  },

  // Apparel
  { path: "/apparel-add", component: <ApparelAdd /> },
  { path: "/apparel-list", component: <ApparelList /> },
  { path: "/apparel-financial", component: <ApparelFinancial /> },
  { path: "/apparel-financial", component: <ApparelFinancial /> },
  { path: "/apparel-printer", component: <ApparelPrinter /> },
  { path: "/apparel-design-upload", component: <ApparelDesignUpload /> },
  {
    path: "waiting-for-desing-apparel",
    component: <ApparelWaitingList />,
  },
  {
    path: "/style-management",
    component: <StyleManagement />,
  },
  {
    path: "/color-management",
    component: <StyleColorManagement />,
  },
  {
    path: "/size-management",
    component: <StyleSizeManagement />,
  },

  // EngravingList
  { path: "/engraving-list", component: <EngravingList /> },
  { path: "/waiting-for-desing", component: <EngravingWaitingList /> },
  { path: "/engraving-design-upload", component: <DesignUpload /> },
  { path: "/engraving-add", component: <EngravingAdd /> },
  { path: "/engraving-financial", component: <EngravingFinancial /> },
  { path: "/engraving-printer", component: <EngravingPrinter /> },
  {
    path: "/engraving-image-management",
    component: <EngravingTemplateImage />,
  },
  {
    path: "/engraving-price-management",
    component: <EngravingPriceManagement />,
  },
  {
    path: "/payment-management/:categoryId",
    component: <PaymentManagement />,
  },
  {
    path: "/engraving-stock-tracking",
    component: <EngravingStockTracking />,
  },

  // Package
  { path: "/package-list", component: <PackageList /> },
  { path: "/package-list-download", component: <PackageListDownload /> },
  { path: "/package-financial", component: <PackageFinancial /> },
  { path: "/package-price-management", component: <PackageManagement /> },

  //DTF
  { path: "/dtf-list", component: <DTFList /> },
  { path: "/dtf-financial", component: <DTFFinancial /> },
  { path: "/dtf-management", component: <DTFManagement /> },
  { path: "/dtf-printer", component: <DTFPrinter /> },

  // UVDTF

  { path: "/uvdtf-list", component: <UVDTFList /> },
  { path: "/uvdtf-financial", component: <UVDTFFinancial /> },
  { path: "/uvdtf-management", component: <UVDTFManagement /> },
  { path: "/uvdtf-printer", component: <UVDTFPrinter /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/register", component: <Register /> },
  { path: "/not-found-403", component: <Pages403 /> },
  { path: "/not-found-404", component: <Pages404 /> },
  { path: "/not-found-500", component: <Pages500 /> },
]

export { authProtectedRoutes, publicRoutes }
