import React, { useEffect, useState, useRef } from "react"
import { Container } from "reactstrap"
import * as moment from "moment"
import UpsertModal from "./modals/upsert"
import * as Http from "utils/http.helper"
import { Table, Button, Tooltip, Card, Row, Col } from "antd"
import { getColumnSearchProps } from "utils/filter"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const Company = () => {
  document.title = "Customer | EASY JET CONNECT"
  const searchInput = useRef(null)
  const [loading, setLoading] = useState(true)
  const [customerData, SetCustomerData] = useState([])
  const [upsertModal, setUpsertModal] = useState({ show: false, data: null })
  const [searchText, setSearchText] = useState("")

  const getData = () => {
    setLoading(true)
    Http.get("api/companies/GetCustomerCompanies")
      .then(res => {
        if (res?.success) {
          setLoading(false)
          SetCustomerData(res.data)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(
          error.response.data.message
            ? error.response.data.message
            : error.message
        )
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps(
        "name",
        "Filter by customer name",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Create Date",
      dataIndex: "addDate",
      key: "addDate",
      render: value => {
        return value ? moment(new Date(value)).format("DD MMM YYYY") : ""
      },
    },

    {
      title: " ",
      key: "action",
      align: "center",
      width: 120,
      render: (_, record) => (
        <Tooltip placement="top" title="Edit Customer">
          <Button
            type="button"
            className="btn btn-primary d-flex align-items-center justify-content-center m-auto"
            icon={<i className="mdi mdi-pencil" />}
            onClick={() => {
              setUpsertModal({ show: true, data: record })
            }}
          />
        </Tooltip>
      ),
    },
  ]

  return (
    <React.Fragment>
      {upsertModal && (
        <UpsertModal
          upsertModal={upsertModal}
          setUpsertModal={setUpsertModal}
          getData={getData}
          setLoading={setLoading}
        />
      )}

      <div className="page-content mb-3">
        <Container fluid>
          <Card
            title="Customer List"
            extra={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Row>
                  <Col
                    lg="12"
                    className="d-flex align-items-center justify-content-end gap-2"
                  >
                    <Button
                      type="button"
                      className="btn btn-primary d-flex align-items-center justify-content-end"
                      onClick={() => setUpsertModal({ show: true, data: null })}
                      icon={
                        <i className="bx bx-plus font-size-16 align-middle"></i>
                      }
                    >
                      Add Customer
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          >
            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={customerData}
              loading={loading}
            />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Company
