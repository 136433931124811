module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:
      "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  apiVersion: "v1",
  // apiUrl: "https://localhost:7033",
  apiUrl: "https://easyjetconnect.xyz",
  // apiUrl: "http://3.75.49.164",
  mapKey: "AIzaSyAH5cGLoPVL2Wk1Htq9_6MSVmY9ncohslU", // yeni Api key alındı.
  node: {
    fs: "empty",
  },
  plugins: [require("autoprefixer")],
}
