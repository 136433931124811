import React, { useState, useEffect } from "react"
import { Form, Input, Row, Modal, Col, Tag, Space } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const css = `.ant-tag{
  white-space:normal!important;
  padding: 0 15px
}
`
const UpsertModal = ({
  upsertModal,
  setUpsertModal,
  loading,
  setLoading,
  getData,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (upsertModal.data?.id) {
      form.setFieldsValue({
        styleName: upsertModal?.data.name,
      })
    }
  }, [upsertModal])

  const onFinish = values => {
    setLoading(true)
    const obj = {
      styleId: upsertModal?.data?.id,
      styleName: values.styleName,
    }
    if (upsertModal.data?.id) {
      Http.post("api/styles/update", obj)
        .then(res => {
          if (res.success) {
            toastr.success(
              `${upsertModal?.data?.name} isimli style güncellenmiştir.`
            )
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            setLoading(false)
            toastr.error(
              `${upsertModal?.data?.name} isimli style güncelleme işlemi başarısız.`
            )
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.title)
        })
    } else {
      delete obj.styleId
      Http.post("api/styles/add", obj)
        .then(res => {
          if (res.success) {
            toastr.success(`${values?.styleName} isimli style eklenmiştir.`)
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            setLoading(false)
            toastr.error(
              `${values?.styleName} isimli style ekleme işlemi başarısız.`
            )
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.title)
        })
    }
  }
  return (
    <Modal
      title={upsertModal.data?.id ? "Style Update" : "Add New Style"}
      centered
      maskClosable={false}
      open={upsertModal.show}
      cancelText="Close"
      okText={upsertModal.data?.id ? "Update" : "Save"}
      onOk={() => {
        form
          .validateFields()
          .then(val => {
            onFinish(val)
          })
          .catch(err => {})
      }}
      onCancel={() => setUpsertModal({ show: false, data: null })}
      width={600}
      confirmLoading={loading}
    >
      <style>{css}</style>
      <Row style={{ marginTop: 25 }}>
        <Col span={24}>
          <Tag color="volcano">
            * Style Name alanı yüklenebilecek farklı csv dosyalarındaki
            Variations alanında yazan style ile birebir eşleşmesi (Büyük, Küçük
            Harf) gerekmektedir. Örneğin; Bir csv dosyasında{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>T-Shirt</span>{" "}
            var diğerinde{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>t-shirt</span>{" "}
            var ise sistemede ikisinin de tanımlı olması gerekmektedir.
          </Tag>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <Form form={form}>
            <Form.Item
              name={"styleName"}
              label={`Style Name`}
              rules={[
                {
                  required: true,
                  message: "Style name alanı zorunludur.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default UpsertModal
const { Search } = Input
