import AWS from "aws-sdk"

const REGION = "eu-central-1" // Örneğin 'us-west-2'
const IDENTITY_POOL_ID = "eu-central-1:29a9f7f6-62dd-499d-b88f-d26248fee003"

AWS.config.update({
  region: REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IDENTITY_POOL_ID,
  }),
})

export const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
})
