import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row
      style={{
        background: "white",
        display: "block",
        marginLeft: 0,
        marginRight: 0,
        borderRadius: 5,
        height: 50,
      }}
    >
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ height: "100%" }}
      >
        <h4 className="mb-sm-0 font-size-18">{props.title}</h4>
        <div className="page-title-right">
          <ol className="breadcrumb m-0 p-0">{props.button}</ol>
        </div>
      </div>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
