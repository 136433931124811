import React, { useState, useEffect, useRef } from "react"
import {
  Form,
  Spin,
  Input,
  Row,
  Col,
  Card,
  Descriptions,
  Divider,
  Typography,
} from "antd"
import * as Http from "utils/http.helper"
import Breadcrumbs from "components/Common/Breadcrumb"
import ImageComponent from "components/image"
import config from "config"
import error from "assets/images/error-img.png"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SVGFromURL from "components/svg2comp"
const { Paragraph } = Typography

const css = `.ant-card-head{ padding:0!important}`
const allPositions = [
  {
    name: "Front Center",
    code: "front_center",
  },
  {
    name: "Spine",
    code: "spine",
  },
  {
    name: "Front Vertical on the Right",
    code: "front_vertical_on_the_right",
  },
  {
    name: "Front Left Lower Corner",
    code: "front_left_lower_corner",
  },
  {
    name: "Front Bottom Center",
    code: "front_bottom_center",
  },
  {
    name: "Inside Center",
    code: "inside_center",
  },
  {
    name: "Inside Upper Side",
    code: "inside_upper_side",
  },
  {
    name: "Inside Left Lower Corner",
    code: "inside_left_lower_corner",
  },
  {
    name: "Inside Left Side",
    code: "inside_left_side",
  },
  {
    name: "Inside Right Side",
    code: "inside_right_side",
  },
  {
    name: "Back Center",
    code: "back_center",
  },
  {
    name: "Back Right Lower Corner",
    code: "back_right_lower_corner",
  },
  {
    name: "Back Bottom Center",
    code: "back_bottom_center",
  },
  {
    name: "Stones",
    code: "stones",
  },
  {
    name: "Tools",
    code: "tools",
  },
  {
    name: "Guitar Pick",
    code: "guitar_pick",
  },
  {
    name: "Front Right Lower Corner",
    code: "front_right_lower_corner",
  },
]

const EngravingPrinter = () => {
  //meta title
  document.title = "Engraving Printer Page | EASY JET CONNECT"
  const [form] = Form.useForm()
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState([])
  const [orderNo, setOrderNo] = useState(null)
  const [productNameList, setProductNameList] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [])

  const handleInputChange = e => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      getOrder(e.target.value)
      setOrderNo(e.target.value)
      setTimeout(() => {
        inputRef.current.focus()
      }, 500)
    }
  }

  const handleInputBlur = e => {
    if (e.target.value.length > 0) {
      getOrder(e.target.value)
      setOrderNo(e.target.value)
    }
    setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 750)
  }

  const getOrder = value => {
    const orderStatus =
      JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length > 0
        ? JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length > 0 &&
          JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] ===
            "Completed"
          ? 50
          : 60
        : 0
    form.setFieldsValue({
      barcode: "",
    })
    setLoading(true)
    Http.get(
      `api/orders/GetEngravingOrderImagesByOrderId?orderId=${value}&status=${orderStatus}`
    )
      .then(response => {
        if (response?.success) {
          const res = response.data
          if (res?.orderImages.length > 0) {
            setData(res)
            JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length >
              0 &&
              JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] ===
                "Completed" &&
              getDesignById(value)
          } else {
            setData([])
          }
        } else {
          toastr.error(
            `${value} numaralı order henüz print etmek için hazır olmayabilir (Has Image=false) ve ya order numaranız hatalı olabilir. Kontrol ediniz!`
          )
          setInfo([])
        }
        setLoading(false)
      })
      .catch(function (error) {
        setInfo([])
        toastr.error(error?.response?.data?.Message)
        setLoading(false)
      })
  }
  console.log("data: ", data)

  const getDesignById = value => {
    setLoading(true)
    try {
      fetch(`${config.apiUrl}/api/orders/DownloadEngravingFiles`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          orderId: value,
        }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok")
          }
          return response.blob()
        })
        .then(blob => {
          const downloadUrl = window.URL.createObjectURL(blob)
          const link = document.createElement("a")
          link.href = downloadUrl
          link.download = `${value}`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
        })
    } catch (error) {}
  }
  return (
    <React.Fragment>
      <style>{css}</style>
      <div className="page-content mb-3">
        <Spin spinning={loading}>
          <Breadcrumbs title=" Engraving Printer" />
          <Card className="mt-3" hoverable>
            <Form form={form} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col lg={8}>
                  <Form.Item label="Order Number" name="barcode">
                    <Input
                      ref={inputRef}
                      onPressEnter={e => handleInputChange(e)}
                      onBlur={e => handleInputBlur(e)}
                      onKeyUp={e => handleInputChange(e)}
                      onChange={e => handleInputChange(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Row
            gutter={[16, 16]}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {orderNo && (
              <Col
                span={12}
                className="mb-3 mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "white",
                  borderRadius: 4,
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: 20, padding: 10 }}>
                  Sipariş Detayı
                  <span
                    style={{
                      fontWeight: "normal",
                      marginLeft: 5,
                      color: "red",
                    }}
                  >
                    [ {orderNo} ]
                  </span>
                </span>
              </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            {data?.orderImages &&
              data?.orderImages.map((item, index) => {
                return (
                  <Col
                    lg={data?.orderImages.length > 1 ? 12 : 24}
                    key={item.sku + "_" + index}
                  >
                    <Card
                      className="mb-3"
                      bordered
                      hoverable
                      title={
                        <Descriptions
                          bordered
                          layout="vertical"
                          column={{
                            xs: 2,
                            sm: 4,
                            md: 5,
                            lg: 5,
                            xl: 5,
                            xxl: 5,
                          }}
                        >
                          <Descriptions.Item label="Sku">
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {item.sku}
                            </span>
                          </Descriptions.Item>
                          <Descriptions.Item label="Color Hex Code">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                columnGap: 20,
                              }}
                            >
                              <div
                                className="whiteSpace-normal"
                                style={{
                                  color: item.positions[0].templateColorHexCode,
                                  fontWeight: "bold",
                                }}
                              >
                                {item.positions[0].templateColorHexCode}
                              </div>
                              <div
                                style={{
                                  backgroundColor:
                                    item.positions[0].templateColorHexCode,
                                  height: 30,
                                  width: 30,
                                  borderRadius: "100%",
                                  boxShadow: "#0000002e 0px 0px 10px 2px",
                                }}
                              ></div>
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item label="Product Name">
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {item.productName}
                            </span>
                          </Descriptions.Item>

                          <Descriptions.Item label="Quantity">
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {item.quantity}
                            </span>
                          </Descriptions.Item>
                          {item.note && (
                            <Descriptions.Item label="Note">
                              <span
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                <Paragraph copyable> {item.note}</Paragraph>
                              </span>
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      }
                    >
                      <Row gutter={[12, 12]}>
                        <Col span={24}>
                          <span
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            <ul
                              className="printer-list"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {item.variation.split(",").map(variation => {
                                return (
                                  <li
                                    className="list-item"
                                    key={variation}
                                    style={{
                                      width: "100%",
                                      columnGap: 5,
                                      textAlign: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      {variation.split(":")[0]}
                                    </p>
                                    <span style={{ fontWeight: "normal" }}>
                                      {variation.split(":")[1]}
                                    </span>{" "}
                                  </li>
                                )
                              })}
                            </ul>{" "}
                          </span>{" "}
                        </Col>
                        <Divider />
                        {item.positions.map((x, i) => {
                          let position = allPositions.find(
                            a => a.code === x.positionCode
                          )
                          return (
                            <Col
                              span={12}
                              key={position + "_" + x.positionCode + "_" + i}
                            >
                              <Descriptions
                                bordered
                                layout="vertical"
                                className="mb-3"
                                title={
                                  <div
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    * {position?.name}
                                  </div>
                                }
                                column={{
                                  xs: 2,
                                  sm: 2,
                                  md: 2,
                                  lg: 2,
                                  xl: 2,
                                  xxl: 2,
                                }}
                              >
                                <Descriptions.Item
                                  label="Template"
                                  style={{ textAlign: "center" }}
                                >
                                  <style>
                                    {`.${item.sku} g g polyline, g g line{ stroke: blue!important }
                                 .${item.sku} rect, .${item.sku} path { fill: ${x.templateColorHexCode}!important }
                                  `}
                                  </style>

                                  <SVGFromURL
                                    className={item.sku}
                                    url={x.templateImageUrl}
                                    width={120}
                                    height={120}
                                  />
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label="Design"
                                  style={{ textAlign: "center" }}
                                >
                                  <ImageComponent
                                    imageUrl={x.imgUrl}
                                    width={120}
                                    height={120}
                                  />
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                          )
                        })}
                      </Row>
                    </Card>
                  </Col>
                )
              })}
          </Row>
          {data.length === 0 && (
            <>
              <Row gutter={[16, 16]}>
                {" "}
                <Col lg={8}>
                  <div className="text-center mb-5">
                    <h5 className="text-uppercase">
                      Lütfen Order Numarası İle arama yapınız!
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="justify-content-center">
                <Col lg={12}>
                  <div>
                    <img src={error} alt="" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Spin>
      </div>{" "}
    </React.Fragment>
  )
}

export default EngravingPrinter
