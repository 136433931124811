import React, { useState, useEffect } from "react"
import { Form, Select, Row, Modal, Col, InputNumber, Spin } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import Sounds from "utils/sounds"

import "toastr/build/toastr.min.css"
const { Option } = Select
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
}
const product = [
  { value: 1, label: "Apparel" },
  { value: 2, label: "Engraving" },
  { value: 3, label: "Deri" },
  { value: 4, label: "Uvdtf" },
  { value: 5, label: "Package" },
]

const UpsertModal = ({
  upsertModal,
  setUpsertModal,
  loading,
  setLoading,
  customersList,
  getData,
}) => {
  const [form] = Form.useForm()
  const [styles, setStyles] = useState([])
  const [options, setOptions] = useState([])
  const [sizes, setSizes] = useState([])

  const getStyles = () => {
    setLoading(true)
    Http.get(`api/orders/GetAllProperties`)
      .then(res => {
        if (res?.success) {
          setOptions(res.data)
          let styleList = []
          res.data.map(item => {
            styleList.push({ value: item.styleId, label: item.styleName })
          })
          styleList.sort((a, b) => a.label.localeCompare(b.label))
          setStyles(styleList)
          if (upsertModal?.data?.id) {
            handleChangeStyle(upsertModal?.data?.styleId, res.data)
          }
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }
  const handleChangeStyle = (val, data) => {
    form.setFieldsValue({
      sizeId: "",
    })
    setLoading(true)
    setSizes([])
    let filter = []
    if (options.length > 0) {
      filter = options.filter(x => x.styleId === val)
    } else {
      filter = data.filter(x => x.styleId === val)
    }
    if (filter.length > 0) {
      let arr = []
      filter[0].sizes.map(item => {
        arr.push({ value: item.id, label: item.name })
      })
      arr.sort((a, b) => {
        const aIsMonth = a.label.includes("Month")
        const bIsMonth = b.label.includes("Month")
        if (!aIsMonth) {
          arr.sort((a, b) => a.label.localeCompare(b.label))
        } else if (aIsMonth && !bIsMonth) {
          return -1 // a önce gelir
        } else if (!aIsMonth && bIsMonth) {
          return 1 // b önce gelir
        } else if (aIsMonth && bIsMonth) {
          return a.label.localeCompare(b.label) // İkisi de "Month" içeriyorsa alfabetik sırala
        } else {
          return a.label.localeCompare(b.label) // İkisi de "Month" içermiyorsa alfabetik sırala
        }
      })
      setSizes(arr)
    }
    setLoading(false)
  }

  useEffect(() => {
    getStyles()
  }, [])

  const onFinish = values => {
    setLoading(true)
    const obj = {
      companyId: values.companyId,
      price: values.price,
      sizeId: values.sizeId,
      styleId: values.styleId,
      categoryId: "1",
    }

    Http.post("api/accounting/setCompanyProductPrice", obj)
      .then(res => {
        if (res.success) {
          Sounds.success()
          toastr.success(`Process Success.`)
          setUpsertModal({ show: false, data: null, companyId: null })
          getData()
          setLoading(false)
        } else {
          Sounds.error()
          setLoading(false)
          toastr.success(`Process Fail.`)
        }
      })
      .catch(function (error) {
        Sounds.error()
        setLoading(false)
        toastr.error(error.response.data.title)
      })
  }

  useEffect(() => {
    if (upsertModal.data?.id) {
      form.setFieldsValue({
        companyId: upsertModal.data.companyId,
        styleId: upsertModal.data.styleId,
        sizeId: upsertModal.data.sizeId,
        price: upsertModal.data.price,
      })
    }
    if (upsertModal?.companyId) {
      form.setFieldsValue({
        companyId: upsertModal.companyId,
      })
    }
  }, [upsertModal])

  return (
    <Modal
      title={upsertModal.data?.id ? "Price Update" : "Add New Price"}
      centered
      maskClosable={false}
      open={upsertModal.show}
      cancelText="Close"
      okText={upsertModal.data?.id ? "Update" : "Save"}
      onOk={() => {
        form
          .validateFields()
          .then(val => {
            onFinish(val)
          })
          .catch(err => {})
      }}
      onCancel={() =>
        setUpsertModal({ show: false, data: null, companyId: null })
      }
      width={600}
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <Row style={{ marginTop: 25 }}>
          <Col span={24}>
            <Form {...layout} form={form}>
              <Form.Item
                label="Company"
                name="companyId"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select a company"
                  style={{ minWidth: "150px" }}
                  disabled={upsertModal?.data?.companyId}
                >
                  {customersList.map(item => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="styleId"
                label="Style"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={e => handleChangeStyle(e)}
                  options={styles || []}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="sizeId"
                label="Size"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  options={sizes || []}
                  disabled={sizes.length === 0}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputNumber
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  min={1}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export default UpsertModal
