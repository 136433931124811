import React, { useState, useEffect } from "react"
import { Modal, Form, Input, Select, Row, Col } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const UpsertModal = ({ upsertModal, setUpsertModal, getData, setLoading }) => {
  const [form] = Form.useForm()
  const [categories, setCategories] = useState([])
  useEffect(() => {
    if (upsertModal.data?.id) {
      form.setFieldsValue({
        maincompanyName: upsertModal.data?.name,
        description: upsertModal.data?.descriptio,
        categories: upsertModal.data?.categories,
      })
    }
  }, [upsertModal])

  const getMainCategories = () => {
    Http.get("api/categories/getMainCategories")
      .then(res => {
        if (res) {
          let arr = []
          let userCategories = JSON.parse(
            localStorage.getItem("authUserV0")
          ).userCategories
          res.map(item => {
            if (userCategories) {
              let find = userCategories.find(x => x.categoryId === item.id)
              if (find) {
                arr.push({
                  value: item.id,
                  label: item.name,
                })
              }
            } else {
              arr.push({
                value: item.id,
                label: item.name,
              })
            }
          })
          setCategories(arr)
        }
      })
      .catch(function (error) {
        toastr.error(error.response.Message)
      })
  }
  useEffect(() => {
    getMainCategories()
  }, [])

  const onSave = () => {
    setLoading(true)
    const values = form.getFieldsValue()

    if (upsertModal.data?.id) {
      const obj = {
        companyId: upsertModal.data?.id,
        name: values.maincompanyName,
        description: values.description,
        categories: values.categories,
      }
      Http.post("api/companies/UpdateMainCompany", obj)
        .then(res => {
          if (res.success) {
            toastr.success(
              `${values.maincompanyName}  company has been update.`
            )
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            toastr.error(`${values.maincompanyName}  company update failed`)
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.title)
        })
    } else {
      const obj = {
        name: values.maincompanyName,
        description: values.description,
        categories: values.categories,
      }
      Http.post("api/companies/AddMainCompany", obj)
        .then(res => {
          if (res.success) {
            toastr.success(
              `${values.maincompanyName}  company has been create.`
            )
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            toastr.error(`${values.maincompanyName}  company create failed`)
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    }
  }
  return (
    <Modal
      maskClosable={false}
      title={
        upsertModal.data?.id ? (
          <span style={{ display: "flex", alignItems: "center" }}>
            Edit Company
            <span
              style={{
                opacity: "0.6",
                fontSize: "13px",
                marginLeft: "8px",
              }}
            >
              {/* [ {upsertModal.data?.sku} | {upsertModal.data?.orderId} ] */}
            </span>
          </span>
        ) : (
          "Add Company"
        )
      }
      open={upsertModal.show}
      onOk={onSave}
      okText="Save"
      onCancel={() => {
        form.resetFields()
        setUpsertModal({ show: false, data: null })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onSubmit={e => {
          e.preventDefault()
          return false
        }}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24}>
            <Form.Item
              label="Company Name"
              name="maincompanyName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Categories"
              name="categories"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                options={categories}
              />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default UpsertModal
