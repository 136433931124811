import React, { useState, useEffect } from "react"

import { Image } from "antd"
function ImageComponent({ imageUrl, width = 30, height = 30, preview = true }) {
  return (
    <Image width={width} height={height} src={imageUrl} preview={preview} />
  )
}

export default ImageComponent
