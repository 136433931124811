import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  Button,
  Spin,
  Form,
  Select,
  Table,
  Result,
  InputNumber,
  Input,
  Popconfirm,
  Typography,
} from "antd"
import Sounds from "utils/sounds"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import * as moment from "moment"
import { SearchOutlined, ClearOutlined } from "@ant-design/icons"
const { Option } = Select

import "toastr/build/toastr.min.css"
const css = `
.ant-form-item.price{
  margin-bottom:0!important;
}`

const EngravingPriceManagement = () => {
  document.title = "Engraving Price Management | EASY JET CONNECT"
  const [form] = Form.useForm()
  const [formTable] = Form.useForm()
  const [filterForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [customers, setCustomers] = useState([])
  const [data, setData] = useState([])
  const [tempData, setTempData] = useState([])
  const [selectedCompanyName, setSelectedCompanyName] = useState(null)
  const [editingKey, setEditingKey] = useState("")
  const isEditing = record => record.key === editingKey

  const edit = record => {
    formTable.resetFields()
    setEditingKey(record.key)
  }
  const cancel = () => {
    setEditingKey("")
  }

  const getAllCustomers = () => {
    Http.get(`api/companies/getAllCustomers?categoryId=3`)
      .then(res => {
        if (res?.success) {
          setCustomers(
            res.data.map(item => {
              return { value: item.id, label: item.name }
            })
          )
        }
      })
      .catch(function (error) {
        toastr.error(error)
      })
  }

  useEffect(() => {
    getAllCustomers()
  }, [])

  const getData = () => {
    setLoading(true)
    setData([])
    let { customerId } = form.getFieldsValue()
    const { sku } = filterForm.getFieldsValue()
    let find = customers.find(x => x.value === customerId)
    find && setSelectedCompanyName(find?.label)
    if (customerId) {
      Http.get(
        `api/Accounting/GetEngravingPriceByCustomer?CustomerId=${customerId}&sku=${
          sku ? sku : ""
        }`
      )
        .then(res => {
          if (res?.data.length > 0) {
            setData(
              res?.data
                ? res.data.map((item, index) => {
                    return { ...item, key: index }
                  })
                : []
            )
            const skuSet = new Set(res?.data.map(item => item.sku))
            let filter = tempData.filter(x => !skuSet.has(x.sku))
            //eklenmeyen templateleri template listesinde görüntülemek için filtreliyoruz
            setTempData(filter)
          }
          setLoading(false)
        })
        .catch(function (error) {
          toastr.error(error)
          setLoading(false)
        })
    } else {
      toastr.warning("Please seect a customer")
      Sounds.warning()
      setLoading(false)
    }
  }

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  const getTempData = () => {
    Http.get(`api/Accounting/GetEngravingSuggestedPrice`)
      .then(res => {
        if (data.length > 0) {
          const skuSet = new Set(res?.data.map(item => item.sku))
          let filter = res?.data.filter(x => !skuSet.has(x.sku))
          setTempData(filter)
        } else {
          setTempData(res?.data ? res.data : [])
        }
      })
      .catch(function (error) {
        toastr.error(error)
      })
  }

  useEffect(() => {
    getTempData()
  }, [])

  const handleSave = () => {
    setLoading(true)
    const { customerId } = form.getFieldsValue()
    let obj = {
      customerId: customerId,
      priceList: tempData,
    }
    Http.post("api/Accounting/SetEngravingPriceCustomer", obj)
      .then(res => {
        if (res.success) {
          Sounds.success()
          let { customerId } = form.getFieldsValue()
          if (customerId) {
            getData()
          }
          toastr.success(`Process success.`)
          setLoading(false)
        }
      })
      .catch(function (error) {
        Sounds.error()
        toastr.error(error?.response?.data?.Message)
        setLoading(false)
      })
  }

  const columns = [
    {
      title: "Sku",
      dataIndex: "skuData",
      render: (_, row) => {
        return row.sku
      },
    },
    {
      title: "Price",
      dataIndex: "priceData",
      editable: true,
      render: (_, record) => {
        return record.price
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (_, row) => {
        return moment(new Date(row.createdDate)).format("DD.MM.YYYY")
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === "price" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const save = async record => {
    try {
      const price = await formTable.getFieldsValue()
      let { customerId } = form.getFieldsValue()
      let obj = {
        customerId: customerId,
        priceList: [
          {
            sku: record.sku,
            price: price.priceData,
          },
        ],
      }

      Http.post("api/Accounting/SetEngravingPriceCustomer", obj)
        .then(res => {
          if (res.success) {
            Sounds.success()
            let { customerId } = form.getFieldsValue()
            if (customerId) {
              setData([])
              getData()
              setEditingKey("")
            }
            toastr.success(`Process success.`)
            setLoading(false)
          }
        })
        .catch(function (error) {
          Sounds.error()
          toastr.error(error?.response?.data?.Message)
          setLoading(false)
        })
    } catch (errInfo) {}
  }

  const columnsTemp = [
    {
      title: "Sku",
      dataIndex: "sku",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
  ]

  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="If you haven't selected any company or added financial data yet, you can select and list a company or create a new financial value."
      />
    ),
  }
  let localeTemp = {
    emptyText: (
      <Result
        title="No data"
        subTitle={`${selectedCompanyName} müşterisi için template verisi olmayabilir ve ya tüm templateler için fiyatlandırma işlemi yapılmış olabilir.`}
      />
    ),
  }
  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <style>{css}</style>
        <Spin spinning={loading}>
          <Breadcrumbs title="Engraving Price Management" />

          <Row gutter={[16, 16]}>
            <Col lg={12}>
              <Card hoverable className="mt-3">
                <Form layout="vertical" form={form}>
                  <Row gutter={[16, 16]}>
                    <Col lg={8}>
                      <Form.Item
                        label="Customer"
                        name="customerId"
                        rules={[
                          {
                            required: true,
                            message: "Customer  zorunludur.",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={() => {
                            getData()
                          }}
                          options={customers}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
              <Card
                hoverable
                title={
                  selectedCompanyName
                    ? `Price list for ${selectedCompanyName}`
                    : "Price list for selected customer"
                }
                className="mt-3"
              >
                <Form form={filterForm} layout="horizontal">
                  <Row gutter={[16, 16]}>
                    <Col lg={10}>
                      <Form.Item label="Sku" name="sku">
                        <Input
                          allowClear
                          disabled={!selectedCompanyName}
                          onPressEnter={() => {
                            getData()
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={6}
                      className="d-flex align-items-start justify-content-start gap-2"
                    >
                      <Button
                        type="button"
                        className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                        disabled={!selectedCompanyName}
                        icon={<SearchOutlined />}
                        onClick={() => {
                          getData()
                        }}
                      >
                        Search
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                        disabled={!selectedCompanyName}
                        icon={<ClearOutlined />}
                        onClick={() => {
                          filterForm.setFieldsValue({
                            sku: "",
                          })
                          getData()
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Form form={formTable} component={false}>
                  {" "}
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    bordered
                    locale={locale}
                    columns={mergedColumns}
                    dataSource={data || []}
                    loading={loading}
                    rowClassName="editable-row"
                    rowKey={record => record.id}
                    size="small"
                    pagination={{
                      onChange: cancel,
                    }}
                  />
                </Form>
              </Card>
            </Col>

            {selectedCompanyName && (
              <Col lg={8} offset={2}>
                {tempData.length > 0 && (
                  <Card
                    title="Template Price List"
                    hoverable
                    extra={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          columnGap: 10,
                        }}
                      >
                        {tempData.length > 0 && (
                          <Button
                            size="small"
                            type={"primary"}
                            onClick={handleSave}
                          >
                            Save template prices for {selectedCompanyName}
                          </Button>
                        )}
                      </div>
                    }
                  >
                    <Table
                      locale={localeTemp}
                      bordered
                      columns={columnsTemp}
                      dataSource={tempData}
                      className="table table-centered"
                      size="small"
                    />
                  </Card>
                )}
              </Col>
            )}
          </Row>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default EngravingPriceManagement
