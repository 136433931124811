import React, { useState, useEffect } from "react"
function SVGFromURL({ url, width, height, className = "" }) {
  const [svgContent, setSvgContent] = useState("")
  useEffect(() => {
    const fetchSVG = async () => {
      try {
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error("SVG dosyası alınamadı.")
        }
        let svgText = await response.text()
        setSvgContent(svgText)
      } catch (error) {
        console.error("Error fetching SVG:", error)
      }
    }

    fetchSVG()

    // cleanup
    return () => {
      setSvgContent("")
    }
  }, [url])

  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgContent }}
      className={className}
      style={{
        width: width,
        height: height ? height : "auto",
        margin: "auto",
      }}
    />
  )
}

export default SVGFromURL
