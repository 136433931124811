import React, { useState, useEffect, useRef } from "react"
import * as Http from "utils/http.helper"
import * as moment from "moment"
import {
  Spin,
  Col,
  Row,
  Card,
  Modal,
  Button,
  Popconfirm,
  Typography,
} from "antd"
import ImageComponent from "components/image"
import toastr from "toastr"

// Black t-shirt
import teeFrontBlack from "assets/images/transparent/TEE/TEE-B-FRONT.png"
import teeBackBlack from "assets/images/transparent/TEE/TEE-B-BACK.png"
import teeLeftBlack from "assets/images/transparent/TEE/TEE-B-LEFT.png"
import teeRightBlack from "assets/images/transparent/TEE/TEE-B-RIGHT.png"
// White t-shirt
import teeFrontWhite from "assets/images/transparent/TEE/TEE-W-FRONT.png"
import teeBackWhite from "assets/images/transparent/TEE/TEE-W-BACK.png"
import teeLeftWhite from "assets/images/transparent/TEE/TEE-W-LEFT.png"
import teeRightWhite from "assets/images/transparent/TEE/TEE-W-RIGHT.png"
// Black Onesie
import onesieFrontBlack from "assets/images/transparent/ONESIE/ONESIE-B-FRONT.png"
import onesieBackBlack from "assets/images/transparent/ONESIE/ONESIE-B-BACK.png"
// White Onesie
import onesieFrontwhite from "assets/images/transparent/ONESIE/ONESIE-W-FRONT.png"
import onesieBackwhite from "assets/images/transparent/ONESIE/ONESIE-W-BACK.png"
// Black Sweatshirt
import sweatshirtFrontBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-FRONT.png"
import sweatshirtBackBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-BACK.png"
import sweatshirtLeftBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-LEFT.png"
import sweatshirtRightBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-RIGHT.png"
// White Sweatshirt
import sweatshirtFrontWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-FRONT.png"
import sweatshirtBackWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-BACK.png"
import sweatshirtLeftWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-LEFT.png"
import sweatshirtRightWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-RIGHT.png"
// Black Hoodie
import hoodieFrontBlack from "assets/images/transparent/HOODIE/HOODIE-B-FRONT.png"
import hoodieBackBlack from "assets/images/transparent/HOODIE/HOODIE-B-BACK.png"
import hoodieLeftBlack from "assets/images/transparent/HOODIE/HOODIE-B-LEFT.png"
import hoodieRightBlack from "assets/images/transparent/HOODIE/HOODIE-B-RIGHT.png"
// White Hoodie
import hoodieFrontWhite from "assets/images/transparent/HOODIE/HOODIE-W-FRONT.png"
import hoodieBackWhite from "assets/images/transparent/HOODIE/HOODIE-W-BACK.png"
import hoodieLeftWhite from "assets/images/transparent/HOODIE/HOODIE-W-LEFT.png"
import hoodieRightWhite from "assets/images/transparent/HOODIE/HOODIE-W-RIGHT.png"
const { Paragraph } = Typography
import "./style.scss"
const optionGroup = [
  {
    value: "front",
    label: "Front View",
  },
  {
    value: "left_front",
    label: "Left and Upper Front View",
  },
  {
    value: "rigth_front",
    label: "Right and Upper Front View",
  },
  {
    value: "left",
    label: "Left Side View",
  },
  {
    value: "right",
    label: "Right Side View",
  },
  {
    value: "back",
    label: "Back View",
  },
  {
    value: "inside_right_sleeve",
    label: "Inside Right Sleeve",
  },
  {
    value: "inside_left_sleeve",
    label: "Inside Left Sleeve",
  },
  {
    value: "outside_right_sleeve",
    label: "Outside Right Sleeve",
  },
  {
    value: "outside_left_sleeve",
    label: "Outside Left Sleeve",
  },
]
const OrderDetails = ({
  setModal,
  modal,
  loading,
  setLoading,
  getTableData,
  tableParams,
}) => {
  const [apiFiles, setApiFiles] = useState([])
  const [color, setColor] = useState(null)
  const [boxLoading, setBoxLoading] = useState(false)
  const getOptions = () => {
    Http.get(`api/orders/GetApparelProperties`)
      .then(res => {
        if (res?.success) {
          const findColor = res?.data?.colors.find(
            x => x.id === modal?.data?.colorId
          )
          setColor(findColor?.hexCode)
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    getOptions()
  }, [])

  const getImages = () => {
    setBoxLoading(true)
    Http.get(`api/orders/GetApparelOrderImages?OrderId=${modal.data?.id}`)
      .then(res => {
        if (res?.success) {
          if (res.data.length > 0) {
            setApiFiles(res.data)
            setTimeout(() => {
              setBoxLoading(false)
            }, 1250)
          } else {
            setModal({ show: false, data: null })
            getTableData({
              current: tableParams.current,
              pageSize: tableParams.pageSize,
            })
          }
        }
      })
      .catch(function (error) {
        setBoxLoading(false)
      })
  }

  useEffect(() => {
    getImages()
  }, [])

  const onDelete = values => {
    setLoading(true)
    let obj = {
      sku: modal.data?.sku,
      orderId: modal.data?.id,
      positions: [
        {
          positionCode: values.positionCode,
        },
      ],
    }
    Http.post(`api/orders/removeApparelImages`, obj)
      .then(res => {
        if (res?.success) {
          setLoading(false)
          setApiFiles([])
          getImages()
          toastr.success(`Process Success.`)
          getTableData()
        } else {
          setLoading(false)
          toastr.error(res?.Message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error?.response?.data?.Message)
      })
  }

  return (
    <Modal
      width={1200}
      maskClosable={false}
      open={modal.show}
      onCancel={() => setModal({ show: false, data: null })}
      footer={
        <Button
          type="primary"
          onClick={() => setModal({ show: false, data: null })}
        >
          Close
        </Button>
      }
      title={
        <>
          Oder Details{" "}
          <span style={{ fontSize: 14 }}>
            <span style={{ opacity: "0.3" }}>[ Order: </span>
            {modal?.data?.orderId}{" "}
            <span style={{ opacity: "0.3" }}>| Sku: </span>
            {modal?.data?.sku}
            <span style={{ opacity: "0.3" }}> ]</span>
          </span>
        </>
      }
    >
      <Spin spinning={loading} fullscreen />

      <Card
        hoverable
        style={{
          padding: "10px",
          border: "1px solid #c5c5c58f",
        }}
      >
        <Row className="align-items-center" gutter={[16, 0]}>
          <Col lg={6}>
            {" "}
            <p className="mb-2 custom_show_info">
              Sku: <span className="text-primary">{modal.data?.sku}</span>
            </p>
            <p className="mb-2 custom_show_info">
              Order No:{" "}
              <Paragraph copyable className="text-primary">
                {modal.data?.orderId}
              </Paragraph>
            </p>
          </Col>
          <Col lg={6}>
            <p className="mb-2 custom_show_info">
              Style : <span className="text-primary">{modal.data?.style}</span>
            </p>
            <p className="mb-2 custom_show_info">
              Price:{" "}
              <span className="text-default">
                {modal.data?.price.toFixed(2)}
                {" $"}
              </span>
            </p>
          </Col>
          <Col lg={6}>
            <p className="mb-2 custom_show_info">
              Size: <span className="text-primary">{modal.data?.size}</span>
            </p>{" "}
            <p className="mb-2 custom_show_info">
              Transaction Id:{" "}
              <span className="text-default">{modal.data?.transactionId}</span>
            </p>{" "}
          </Col>
          <Col lg={6}>
            {" "}
            <p className="mb-2 custom_show_info">
              Color: <span className="text-primary">{modal.data?.color} </span>
            </p>
            <p className="mb-2 custom_show_info">
              Sale Date:{" "}
              <span className="text-default">
                {moment(new Date(modal.data?.saleDate)).format("DD MMM YYYY")}
              </span>
            </p>{" "}
          </Col>
          {modal.data?.note && (
            <Col lg={24}>
              {" "}
              <p className="mb-2 custom_show_info">
                Note: <span className="text-default">{modal.data?.note} </span>
              </p>
            </Col>
          )}
        </Row>
      </Card>
      <Row gutter={[16, 16]} style={{ minHeight: 400 }}>
        {apiFiles.map((item, index) => {
          if (item?.imageUrl.length > 0) {
            let labelName = optionGroup.find(x => x.value === item.positionCode)

            let imageUrl = null
            if (item.positionCode === "left") {
              imageUrl =
                color === "#000000"
                  ? modal?.data?.style === "Sweatshirt"
                    ? sweatshirtLeftWhite
                    : modal?.data?.style === "Hoodie"
                    ? hoodieLeftWhite
                    : teeLeftWhite
                  : modal?.data?.style === "Sweatshirt"
                  ? sweatshirtLeftBlack
                  : modal?.data?.style === "Hoodie"
                  ? hoodieLeftBlack
                  : teeLeftBlack
            } else if (
              item.positionCode === "inside_right_sleeve" ||
              item.positionCode === "inside_left_sleeve"
            ) {
              imageUrl =
                color === "#000000"
                  ? sweatshirtFrontWhite
                  : sweatshirtFrontBlack
            } else if (
              item.positionCode === "outside_right_sleeve" ||
              item.positionCode === "outside_left_sleeve"
            ) {
              imageUrl =
                color === "#000000" ? sweatshirtBackWhite : sweatshirtBackBlack
            } else if (item.positionCode === "right") {
              imageUrl =
                color === "#000000"
                  ? modal?.data?.style === "Sweatshirt"
                    ? sweatshirtRightWhite
                    : modal?.data?.style === "Hoodie"
                    ? hoodieRightWhite
                    : teeRightWhite
                  : modal?.data?.style === "Sweatshirt"
                  ? sweatshirtRightBlack
                  : modal?.data?.style === "Hoodie"
                  ? hoodieRightBlack
                  : teeRightBlack
            } else if (item.positionCode === "back") {
              imageUrl =
                color === "#000000"
                  ? modal?.data?.style === "Sweatshirt"
                    ? sweatshirtBackWhite
                    : modal?.data?.style === "Hoodie"
                    ? hoodieBackWhite
                    : modal?.data?.style === "Onesie"
                    ? onesieBackwhite
                    : teeBackWhite
                  : modal?.data?.style === "Sweatshirt"
                  ? sweatshirtBackBlack
                  : modal?.data?.style === "Hoodie"
                  ? hoodieBackBlack
                  : modal?.data?.style === "Onesie"
                  ? onesieBackBlack
                  : teeBackBlack
            } else {
              imageUrl =
                color === "#000000"
                  ? modal?.data?.style === "Sweatshirt"
                    ? sweatshirtFrontWhite
                    : modal?.data?.style === "Hoodie"
                    ? hoodieFrontWhite
                    : modal?.data?.style === "Onesie"
                    ? onesieFrontwhite
                    : teeFrontWhite
                  : modal?.data?.style === "Sweatshirt"
                  ? sweatshirtFrontBlack
                  : modal?.data?.style === "Hoodie"
                  ? hoodieFrontBlack
                  : modal?.data?.style === "Onesie"
                  ? onesieFrontBlack
                  : teeFrontBlack
            }
            return item.imageUrl.map((x, y) => {
              return (
                <Col lg={8} key={index + "-col"}>
                  <Card
                    title={
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{labelName?.label}</span>
                          {modal.data.status < 30 && (
                            <Popconfirm
                              title="Attention!"
                              description={
                                <>
                                  Are you sure you want to delete the design for
                                  the <br />
                                  <span style={{ fontWeight: "700" }}>
                                    {labelName?.label}
                                  </span>{" "}
                                  of the Order?
                                </>
                              }
                              onConfirm={() => {
                                onDelete(item)
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button danger>Delete</Button>
                            </Popconfirm>
                          )}
                        </Col>
                      </Row>
                    }
                    className="mt-3"
                    hoverable
                    id={item.positionCode}
                    key={index + "-file"}
                    style={{
                      padding: "10px",
                      border: "1px solid #c5c5c58f",
                      minHeight: 440,
                    }}
                  >
                    <Spin spinning={boxLoading}>
                      <div style={{ background: color, minHeight: 250 }}>
                        {
                          <ImageComponent
                            key={`tshirt_${index}]`}
                            imageUrl={imageUrl}
                            width="100%"
                            height="100%"
                            preview={false}
                          />
                        }
                      </div>
                      <div
                        className={
                          item?.positionCode +
                          " " +
                          modal?.data?.style.toLowerCase() +
                          " showImage"
                        }
                        key={`div_${index}]`}
                        style={{
                          backgroundImage: `url(${x})`,
                          border: "red 1px dashed",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "bottom",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            color === "#fff" ||
                            color === "#ffffff" ||
                            color === "#000" ||
                            color === "#000000"
                              ? "#e8e8e8ad"
                              : "#ffffff00",
                        }}
                      />
                    </Spin>
                  </Card>
                </Col>
              )
            })
          }
        })}
      </Row>
    </Modal>
  )
}

export default OrderDetails
