import PropTypes from "prop-types"
import React, { useState } from "react"
import * as Http from "utils/http.helper"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import toastr from "toastr"
import { Spin, Flex } from "antd"
//redux
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import black_mini_logo from "../../assets/images/logo/black_mini_logo.png"
const css = `.ant-spin-custom{
  display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    height: calc(100% + 20px);
    z-index: 9;
    background: #c8d1f580;
    top: -20px;
}

`

const Login = props => {
  //meta title
  document.title = "Login | EASY JET CONNECT"
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      setLoading(true)

      localStorage.removeItem("access_token")
      localStorage.removeItem("authUserV0")
      const data = new URLSearchParams()
      data.append("client_id", "easyjetclientuser")
      data.append("client_secret", "1q2w3e**")
      data.append("grant_type", "password")
      data.append("username", values?.email)
      data.append("password", values?.password)

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
      Http.postLogin("connect/token", data, config)
        .then(res => {
          if (res.access_token) {
            localStorage.setItem("access_token", res.access_token)
            handleLogin(res.access_token)
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error?.response?.data?.Message)
        })
    },
  })
  const handleLogin = token => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    }

    Http.getUserInfo("api/Users/GetUserInfo", headers)
      .then(res => {
        setLoading(false)
        localStorage.setItem("authUserV0", JSON.stringify(res?.data))

        navigate(`/dashboard`)
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error?.response?.data?.Message)
      })
  }

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  return (
    <React.Fragment>
      <style>{css}</style>
      {loading && (
        <div className="ant-spin-custom">
          <Spin spinning={true} size="large"></Spin>
        </div>
      )}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome !</h5>
                        {/* <p>Sign in to continue to easyjetconnect.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={black_mini_logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}

                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
