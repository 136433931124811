import React, { useEffect, useState } from "react"
import {
  Button,
  Select,
  Form,
  Input,
  Row,
  Col,
  Card,
  DatePicker,
  Divider,
  Spin,
} from "antd"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Http from "utils/http.helper"
import { SearchOutlined, ClearOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import toastr from "toastr"

dayjs.extend(customParseFormat)

const { RangePicker } = DatePicker

const css = `.ant-form-item{
  margin-bottom:0!important
} `
let dateFormat = "DD/MM/YYYY"
let apiToDateFormat = "MM-DD-YYYY"
const today = dayjs()
const tomorrow = today.add(1, "day")
const startOfMonth = dayjs().startOf("month")
const Dashboard = () => {
  //meta title
  document.title = "Dashboard | EASY JET CONNECT"
  const [form] = Form.useForm()
  const [formSearch] = Form.useForm()
  const navigate = useNavigate()

  const [userInfo, setUserInfo] = useState()
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [customerName, setCustomerName] = useState(null)
  const [typeOptions, setTypeOptions] = useState([])
  const [totalInfo, setTotalInfo] = useState({
    count: 0,
    price: 0,
  })
  const [infoLoading, setInfoLoading] = useState(false)
  const [value, setValue] = useState(null)

  const [customers, setCustomers] = useState([])

  const getAllCustomers = () => {
    Http.get(`api/companies/getAllCustomers`)
      .then(res => {
        if (res?.success) {
          let arr = [{ value: "", label: "All Customer" }]
          res.data.map(item => {
            arr.push({ value: item.id, label: item.name })
          })
          setCustomerName("All Customer")
          setCustomers(arr)
          formSearch.setFieldsValue({
            customerId: "",
          })
        }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("authUserV0")).roleId === 1) {
      getAllCustomers()
    }
  }, [])

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem("authUserV0")))
    let arr = []
    JSON.parse(localStorage.getItem("authUserV0")).userCategories?.length > 0 &&
      JSON.parse(localStorage.getItem("authUserV0")).userCategories.map(
        item => {
          // apparel 1 , engraving 3 , package 5
          if (
            item.categoryId === 1 ||
            item.categoryId === 3 ||
            item.categoryId === 5
          ) {
            arr.push({ value: item.categoryId, label: item.categoryName })
          }
        }
      )
    if (arr.length === 1) {
      form.setFieldsValue({
        type: arr[0].value,
      })
      setBtnDisabled(false)
    }
    setTypeOptions(arr)
  }, [])

  const getDatas = () => {
    setInfoLoading(true)
    const { dates, customerId } = formSearch.getFieldsValue()
    if (userInfo?.roleId === 2) {
      //customer Admin
      Http.get(
        `api/Accounting/GetCustomerPriceSummary?startDate=${
          dates ? dayjs(dates[0]).format(apiToDateFormat) : ""
        }&endDate=${dates ? dayjs(dates[1]).format(apiToDateFormat) : ""}`
      )
        .then(res => {
          if (res.success) {
            setTotalInfo({
              count: res?.data?.orderCount,
              price: res?.data?.totalPrice,
            })
          }
          setInfoLoading(false)
        })
        .catch(function (error) {
          setInfoLoading(false)
        })
    } else if (userInfo?.roleId === 1) {
      //company Admin
      Http.get(
        `api/Accounting/getCompanyPriceSummary?customerId=${
          customerId ? customerId : ""
        }&startDate=${
          dates ? dayjs(dates[0]).format(apiToDateFormat) : ""
        }&endDate=${dates ? dayjs(dates[1]).format(apiToDateFormat) : ""}`
      )
        .then(res => {
          if (res.success) {
            setTotalInfo({
              count: res?.data?.orderCount,
              price: res?.data?.totalPrice,
            })
          }
          setInfoLoading(false)
        })
        .catch(function (error) {
          setInfoLoading(false)
        })
    }
  }

  useEffect(() => {
    if (userInfo?.roleId) {
      getDatas()
    }
  }, [userInfo])

  const handleSearch = () => {
    const values = form.getFieldValue()
    if (values.type === 1) {
      navigate(`/apparel-list`, { state: values.orderId })
    } else if (values.type === 3) {
      navigate(
        `/engraving-list?${values.orderId ? `orderId=${values.orderId}` : ""}`
      )
    } else if (values.type === 5) {
      Http.get(
        `api/packages/GetPackageBarcode?ProductBarcode=${values.orderId}`
      )
        .then(res => {
          console.log("res: ", res)
          if (res) {
            if (JSON.parse(localStorage.getItem("authUserV0")).roleId === 1) {
              navigate(
                `/package-list-download?productBarcode=${res.data.productBarcode}&packageBarcode=${res.data.packageBarcode}`
              )
            } else if (
              JSON.parse(localStorage.getItem("authUserV0")).roleId === 2
            ) {
              navigate(
                `/package-list?productBarcode=${res.data.productBarcode}&packageBarcode=${res.data.packageBarcode}`
              )
            }
          }
        })
        .catch(err => {
          toastr.error(err.response.data.Message)
        })
    }
  }

  const onChangeCustomer = val => {
    let find = customers.find(x => x.value === val)
    setCustomerName(find.label)
    getDatas()
  }

  //Stokların güncellenmesi için bu gerekli.
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 &&
      JSON.parse(localStorage.getItem("authUserV0"))?.userCategories &&
      JSON.parse(localStorage.getItem("authUserV0"))?.userCategories.find(
        x => x.categoryId === 3
      )
    ) {
      Http.get(`api/stock/getCompanyStock`)
    }
  }, [])
  return (
    <React.Fragment>
      <style>{css}</style>
      {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7 ||
      JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 8 ? (
        <div
          className="page-content"
          style={{
            padding:
              "calc(15px + 57px) calc(1px * 0.75) 70px calc(1px * 0.75)!important",
          }}
        >
          <div className="main banner1">
            <h1>
              WELCOME TO EASYJETCONNECT SYSTEM!
              <div className="roller">
                <span className="rolltext">
                  {JSON.parse(
                    localStorage.getItem("authUserV0")
                  ).nameSurname.toUpperCase()}{" "}
                  <br />
                  {JSON.parse(localStorage.getItem("authUserV0")).role}
                  <br />
                  <span className="spare-time">
                    {JSON.parse(localStorage.getItem("authUserV0")).email}
                  </span>
                  <br />
                </span>
              </div>
            </h1>
          </div>
        </div>
      ) : (
        <div className="page-content mb-3">
          <Breadcrumbs title="Dashboard" />
          <Row gutter={[16, 16]}>
            <Col lg={16}>
              <Card className="mt-3">
                <Row gutter={[26, 26]}>
                  <Divider orientation="left">
                    {" "}
                    {customerName} Detail Info{" "}
                    <span style={{ opacity: "0.6", fontSize: 14 }}>
                      {value
                        ? ` [
                         ${dayjs(value[0]).format("DD-MM-YYYY")} /
                          ${dayjs(value[1]).format("DD-MM-YYYY")}
                       ]`
                        : ""}
                    </span>
                  </Divider>
                  <Col lg={8}>
                    <div className="ui">
                      <div className="ui_box">
                        <div className="ui_box__inner">
                          <h2>Total Order Count</h2>
                          <div className="stat_left">
                            {!infoLoading ? (
                              <h1>{totalInfo?.count}</h1>
                            ) : (
                              <Spin spinning={true} />
                            )}
                          </div>
                          <div className="progress_graph">
                            <div className="progress_graph__bar--1"></div>
                            <div className="progress_graph__bar--2"></div>
                            <div className="progress_graph__bar--3"></div>
                            <div className="progress_graph__bar--4"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="ui">
                      <div className="ui_box">
                        <div className="ui_box__inner">
                          <h2>Total Price</h2>
                          <div className="stat">
                            {!infoLoading ? (
                              <h1>
                                {" "}
                                <span>${totalInfo.price.toFixed(2)}</span>{" "}
                              </h1>
                            ) : (
                              <Spin spinning={true} />
                            )}
                          </div>
                          <div className="progress">
                            <div
                              className="progress_bar"
                              style={{
                                width: `${
                                  (Math.round(totalInfo?.price) * 100) / 5000
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={24}>
                    <Divider orientation="left">Search Criteria</Divider>
                    <Form form={formSearch} layout="vertical">
                      <Row gutter={[16, 16]}>
                        {JSON.parse(localStorage.getItem("authUserV0"))
                          .roleId === 1 && (
                          <Col lg={8}>
                            <Form.Item label="Customer" name="customerId">
                              <Select
                                showSearch
                                placeholder="Select a company"
                                options={customers}
                                onChange={e => onChangeCustomer(e)}
                                style={{ minWidth: "150px" }}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Col lg={8}>
                          <Form.Item label="Start / End Date" name="dates">
                            <RangePicker
                              style={{ width: "100%" }}
                              format={dateFormat}
                              allowClear
                              onChange={val => {
                                if (val) {
                                  setValue(val)
                                  if (val[0] && val[1]) {
                                    getDatas()
                                  }
                                } else {
                                  setValue(null)
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          lg={4}
                          className="d-flex align-items-end justify-content-start gap-2"
                        >
                          <Button
                            type="button"
                            className="btn btn-light d-flex align-items-center justify-content-start"
                            icon={<ClearOutlined />}
                            style={{
                              border: "1px solid #f94040",
                              background: "#f94040",
                              color: "white",
                            }}
                            onClick={() => {
                              setCustomerName("All Customer")
                              setValue(null)
                              formSearch.resetFields()
                              getDatas()
                              formSearch.setFieldsValue({
                                customerId: "",
                              })
                            }}
                          >
                            Clear
                          </Button>
                          <Button
                            type="button"
                            className="btn btn-primary d-flex align-items-center justify-content-start "
                            style={{ border: "1px solid #556ee6" }}
                            icon={<SearchOutlined />}
                            onClick={() => getDatas()}
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={8}>
              <Card
                hoverable
                className="mt-3"
                style={{ opacity: "0.4", minHeight: 370 }}
              >
                <Divider orientation="left">Financial Balance</Divider>
                <Row gutter={[16, 16]}>
                  <Col lg={24}>
                    <div className="ui">
                      <div className="ui_box">
                        <div className="ui_box__inner">
                          <h2>Total Balance</h2>
                          <div className="stat">
                            <h1>
                              {" "}
                              <span>${"5000"}</span>{" "}
                            </h1>
                          </div>
                          <div className="progress">
                            <div
                              className="progress_bar"
                              style={{ width: "99%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={24}>
                    <div className="ui">
                      <div className="ui_box_red">
                        <div className="ui_box__inner">
                          <div
                            className="start"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="stat">
                              <h2>Total Expenditure</h2>
                              <h1>
                                {" "}
                                <span>${"2100"}</span>{" "}
                              </h1>
                            </div>
                            <div className="stat">
                              <h2>Total Available Balance</h2>
                              <h1>
                                {" "}
                                <span>${"2900"}</span>{" "}
                              </h1>
                            </div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress_bar"
                              style={{
                                width: `${(Math.round(2500) * 100) / 5000}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-3">
            <Col lg={16}>
              <Card>
                <div className="main banner1 card_banner">
                  <h1>
                    WELCOME TO EASYJETCONNECT SYSTEM!
                    <div className="roller">
                      <span className="rolltext">
                        {JSON.parse(
                          localStorage.getItem("authUserV0")
                        ).nameSurname.toUpperCase()}{" "}
                        <br />
                        {JSON.parse(localStorage.getItem("authUserV0")).role}
                        <br />
                        <span className="spare-time">
                          {JSON.parse(localStorage.getItem("authUserV0")).email}
                        </span>
                        <br />
                      </span>
                    </div>
                  </h1>
                </div>
              </Card>
            </Col>
            <Col lg={8}>
              {(JSON.parse(localStorage.getItem("authUserV0")).roleId === 2 ||
                JSON.parse(localStorage.getItem("authUserV0")).roleId === 1) &&
                typeOptions.length > 0 && (
                  <Card className="overflow-hidden">
                    <Divider>Order Search Criteria</Divider>
                    <Form form={form} layout="vertical">
                      <Row gutter={[16, 16]}>
                        <Col lg={24}>
                          <Form.Item
                            label="Search Type"
                            name="type"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              },
                            ]}
                          >
                            <Select
                              options={typeOptions}
                              placement="topLeft"
                              placeholder="Selected search type"
                              allowClear
                              disabled={typeOptions.length === 1}
                              onChange={e => setBtnDisabled(e ? false : true)}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={24}>
                          <Form.Item
                            label="Order Number"
                            name="orderId"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              },
                            ]}
                          >
                            <Input placeholder="Write Order Number..." />
                          </Form.Item>
                        </Col>

                        <Col
                          lg={24}
                          className="d-flex align-items-end justify-content-start gap-2"
                        >
                          <Button
                            type="button"
                            className="btn btn-light d-flex align-items-center justify-content-center"
                            icon={<ClearOutlined />}
                            style={{
                              border: "1px solid #f94040",
                              background: "#f94040",
                              color: "white",
                              width: "100%",
                            }}
                            onClick={() => {
                              if (typeOptions.length > 1) {
                                form.setFieldsValue({
                                  type: "",
                                })
                              }
                              form.setFieldsValue({
                                orderId: "",
                              })
                            }}
                          >
                            Clear
                          </Button>
                          <Button
                            type="button"
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            style={{
                              border: "1px solid #556ee6",
                              width: "100%",
                            }}
                            icon={<SearchOutlined />}
                            disabled={btnDisabled}
                            onClick={() => {
                              form
                                .validateFields()
                                .then(() => {
                                  handleSearch()
                                })
                                .catch(err => {})
                            }}
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                )}
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  )
}

export default Dashboard
