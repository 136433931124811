import React, { useState, useEffect, useContext } from "react"
import { Container, Row, Col } from "reactstrap"
import {
  Table,
  Button,
  Select,
  Card,
  Tooltip,
  Form,
  Input,
  Tag,
  Space,
  Popconfirm,
  Typography,
} from "antd"
import * as Http from "utils/http.helper"
import {
  SearchOutlined,
  ClearOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  AppstoreAddOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import toastr from "toastr"
import * as moment from "moment"
import { UpsertModal } from "../Modals" // İlgili yolu güncelleyin
import config from "config"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom"
import { CustomContext } from "context"

const { Option } = Select
const { Paragraph } = Typography

const css = `
#order-list td{
  vertical-align: middle;
}
tr{ cursor:pointer }
 .multipleRow, .multipleRow .ant-table-cell-fix-right{
  background:#c2dafe!important;
}
.multipleRow td:first-child
{
  font-weight:bold
}
.ant-table-row-level-1 .ant-table-cell:first-child,
.ant-table-row-level-2 .ant-table-celll:first-child,
.ant-table-row-level-3 .ant-table-celll:first-child,
.ant-table-row-level-4 .ant-table-celll:first-child,
.ant-table-row-level-5 .ant-table-celll:first-child,
.ant-table-row-level-6 .ant-table-celll:first-child,
.ant-table-row-level-7 .ant-table-celll:first-child,
.ant-table-row-level-8 .ant-table-celll:first-child,
.ant-table-row-level-9 .ant-table-celll:first-child,
.ant-table-row-level-10 .ant-table-celll:first-child{
  font-weight:bold
}
.ant-table-cell-row-hover,
.ant-table-cell-row-hover.ant-table-cell-fix-right{
  background:#dddddd!important;
}
.ant-table-cell.ant-table-cell-with-append .ant-table-row-expand-icon.ant-table-row-expand-icon-spaced{
  background:red
}
.multipleRow:hover .ant-table-cell-row-hover{
  background:#c2dafe!important;
}
@page  
{ 
    size: auto;  
    margin: 25mm 5mm 25mm 5mm;  
}`
const orderStatus = [
  {
    value: 10,
    label: "New Order",
  },
  {
    value: 20,
    label: "Production Ready",
  },
  {
    value: 30,
    label: "Production",
  },
  {
    value: 40,
    label: "PDF Printed",
  },
  {
    value: 50,
    label: "Completed",
  },
  {
    value: 60,
    label: "Shipment",
  },
  {
    value: 70,
    label: "Waiting For Design",
  },
  {
    value: 80,
    label: "Canceled",
  },
]
const EngravingList = () => {
  document.title = "Engraving List | EASY JET CONNECT"
  const { state, dispatch } = useContext(CustomContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const orderId = params.get("orderId")
  const [loading, setLoading] = useState(false)
  const [disabledBtnPdf, setDisabledBtnPdf] = useState(true)
  const [upsertModal, setUpsertModal] = useState({ show: false, data: null })
  const [haveOrderBefore, setHaveOrderBefore] = useState(true)
  const [isReadyRecord, setIsReadyRecord] = useState(false)
  const [selectedRecordName, setSelectedRecordName] = useState(null)
  const [recordNameList, setRecordNameList] = useState([])
  const [companyDataList, setCompanyDataList] = useState([])
  const [maxDate, setMaxDate] = useState(null)
  const [engravingList, setEngravingList] = useState([])
  useEffect(() => {
    if (orderId) {
      form.setFieldsValue({
        orderId: orderId,
      })
    }
  }, [])

  const getHaveOrderBefore = () => {
    Http.get(`api/orders/HaveOrderBefore?categoryId=3`)
      .then(res => {
        if (res?.success) {
          setHaveOrderBefore(!res.data)
        }
      })
      .catch(function (error) {})
  }

  const getCompanyData = () => {
    setLoading(true)
    Http.get("api/companies/getAllCustomers?categoryId=3")
      .then(res => {
        if (res?.success) {
          let arr = [{ label: "All", value: "" }]
          res?.data.map(item => {
            arr.push({ label: item.name, value: item.id })
          })
          setCompanyDataList(arr)
          getRecordsName("")
          form.setFieldsValue({
            companyId: "",
          })
        }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("authUserV0")).roleId === 1 ||
      JSON.parse(localStorage.getItem("authUserV0")).roleId === 7
    ) {
      getCompanyData()
    }
    if (
      JSON.parse(localStorage.getItem("authUserV0")).roleId === 1 ||
      JSON.parse(localStorage.getItem("authUserV0")).roleId === 2
    ) {
      getHaveOrderBefore()
    }
  }, [])

  const getRecordsName = val => {
    setDisabledBtnPdf(val ? false : true)
    Http.get(
      `api/orders/GetRecordNames?companyId=${
        JSON.parse(localStorage.getItem("authUserV0")).roleId === 1 ||
        JSON.parse(localStorage.getItem("authUserV0")).roleId === 7
          ? val
            ? val
            : ""
          : JSON.parse(localStorage.getItem("authUserV0")).companyId
      }&categoryId=3`
    ).then(res => {
      if (res.data.length > 0) {
        const uniqueArray = []
        const nameSet = new Set()
        if (res?.data.length > 1) {
          res?.data.forEach(item => {
            if (!nameSet.has(item.name)) {
              nameSet.add(item.name)
              uniqueArray.push(item)
            }
          })
          setRecordNameList(
            uniqueArray.map(item => {
              return { label: item.name, value: item.id }
            })
          )
        } else {
          setRecordNameList(
            res.data.map(item => {
              return { label: item.name, value: item.id }
            })
          )
        }

        form.setFieldsValue({
          recordName: res?.data[0].id,
        })
        setSelectedRecordName({
          label: res?.data[0].name,
          value: res?.data[0].id,
        })
        getTableData(null, res?.data[0].name)
        setLoading(false)
      } else {
        setRecordNameList([])
        setEngravingList([])
        setLoading(false)
        form.setFieldsValue({
          recordName: "",
        })
      }
    })
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 &&
      JSON.parse(localStorage.getItem("authUserV0"))?.companyId
    ) {
      getRecordsName(JSON.parse(localStorage.getItem("authUserV0"))?.companyId)
    }
  }, [])

  useEffect(() => {
    if (
      selectedRecordName?.value &&
      JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2
    ) {
      Http.get(
        `api/orders/IsReadyProductionRecordName?recordName=${selectedRecordName.label}&categoryId=3`
      ).then(res => {
        setIsReadyRecord(res.data.isReady)
      })
    }
  }, [selectedRecordName])

  const getTableData = (pagination, recName) => {
    if (form.validateFields()) {
      setEngravingList([])
      setLoading(true)
      const formValues = form.getFieldValue()
      let findRecordName = recordNameList.find(
        x => x.value === formValues.recordName
      )

      if (formValues.recordName || formValues.orderId || orderId) {
        Http.get(
          `api/orders/getorders?PageNumber=${
            pagination?.current
              ? pagination?.current
              : state.tableParams?.current
          }&Limit=${
            pagination?.pageSize
              ? pagination?.pageSize
              : state.tableParams?.pageSize
          }&categoryId=3${
            formValues.companyId ? `&customerId=${formValues.companyId}` : ""
          }&OrderRecordName=${
            recName ? recName : findRecordName ? findRecordName.label : ""
          }${
            orderId
              ? "&search=" + formValues.orderId
              : formValues.orderId
              ? "&search=" + formValues.orderId
              : ""
          }`
        )
          .then(res => {
            if (res?.success) {
              if (res?.data?.data?.orders.length > 0) {
                let maxDate = null

                res?.data?.data?.orders.map(item => {
                  item.key = item.id
                  item?.children?.length === 0 && delete item.children
                  const addDate = new Date(item.addDate)
                  if (maxDate === null || addDate > maxDate) {
                    maxDate = addDate
                  }
                })
                let newMaxDate = new Date(maxDate)
                // Saati, dakikayı ve saniyeyi sıfırlıyoruz
                setMaxDate(moment(new Date(newMaxDate)).format("DD/MMM/YYYY"))
                // Her bir öğeyi kontrol ederek en büyük addDate değerini bulma
                setEngravingList(res?.data?.data?.orders)
                let payload = {
                  current: pagination?.current
                    ? pagination.current
                    : state.tableParams?.current,
                  pageSize: pagination?.pageSize
                    ? pagination.pageSize
                    : state.tableParams?.pageSize,
                  total:
                    res?.data?.totalItems !== 0
                      ? res?.data?.totalItems
                      : state.tableParams.total,
                }
                dispatch({ type: "SET_TABLEPARAMS", payload })
              } else {
                setEngravingList([])
              }
            } else {
              let payload = {
                current: 1,
                pageSize: 10,
                total: 0,
              }
              dispatch({ type: "SET_TABLEPARAMS", payload })
            }
            setLoading(false)
          })
          .catch(function (error) {
            setLoading(false)
            toastr.error(error)
          })
      }
    }
  }

  const handleChangeStatus = (id, statusCode, sku, orderId) => {
    setLoading(true)
    Http.post("api/orders/ChangeOrderStatus", {
      orderId: id,
      status: statusCode,
    })
      .then(res => {
        if (res.success) {
          toastr.success(
            `${orderId} order number and ${sku} sku number, order status has been update.`
          )
          statusCode === 80 && getTableData()
        } else {
          setProducts([])
          toastr.error(
            `${orderId} order number and ${sku} sku number, order status update process failed`
          )
          getTableData()
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        setProducts([])
        getTableData()
        toastr.error("Status update process fail")
      })
  }

  const columns = [
    {
      title: "Sku",
      dataIndex: "sku",
      width: 150,
      render: value => {
        return <Paragraph copyable>{value}</Paragraph>
      },
    },

    {
      title: "Order No",
      dataIndex: "orderId",
      render: value => {
        return <Paragraph copyable>{value}</Paragraph>
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      width: 150,
      // hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Quant.",
      dataIndex: "quantity",
      width: 80,
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Variant",
      dataIndex: "variant",
      width: 130,
      render: (_, row) => {
        return (
          <Tooltip placement="top" title={row.variant}>
            <div className="tooltip_table">
              {row.variant.substring(0, 12)}...
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "180px",
      render: (_, row) => {
        if (row.price && row.price > 0) {
          if (
            JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 &&
            row?.status < 30
          ) {
            return (
              <Select
                style={{
                  width: "100%",
                }}
                disabled={row?.status > 20 || !row?.hasImages}
                defaultValue={row?.status}
                onChange={e =>
                  handleChangeStatus(row.id, e, row.sku, row.orderId)
                }
              >
                {orderStatus.map((item, index) => {
                  if (item.value < row.status + 20 && item.value < 30) {
                    return (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    )
                  }
                })}
              </Select>
            )
          } else {
            return (
              <Tag
                color={
                  row.status === 10
                    ? "#28A745"
                    : row.status === 20
                    ? "#FFC107"
                    : row.status === 30
                    ? "#007BFF"
                    : row.status === 40
                    ? "#6F42C1"
                    : row.status === 50
                    ? "#6C757D"
                    : "#17A2B8"
                }
                key={row.id}
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                {orderStatus.find(x => x.value === row.status)?.label}
              </Tag>
            )
          }
        } else {
          return "Price değeri bulunamamış. Güncelleyebilir ve ya İptal ederek üretim işlemine devam edebilirsiniz."
        }
      },
    },
    {
      title: "Name Surname",
      dataIndex: "nameSurname",
      render: (_, row) => {
        return row.shipmentDetail.nameSurname
      },
    },
    {
      title: "Sale Date",
      dataIndex: "saleDate",
      key: "saleDate",
      render: value => {
        return moment(new Date(value)).format("DD MMM YYYY")
      },
    },
    {
      title: "Has Image",
      dataIndex: "hasImages",
      align: "center",
      render: value => {
        return value ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: 20 }} />
        )
      },
    },
    {
      title: " ",
      key: "action",
      // hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7,
      align: "center",
      render: (_, record) => {
        return (
          <Space size="middle">
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 &&
              record.productName &&
              record.price && (
                <Tooltip placement="top" title="Image Upload / Delete">
                  <Button
                    type="button"
                    className={
                      record.hasImages
                        ? "btn btn_success d-flex align-items-center justify-content-center"
                        : "btn btn_tertiary d-flex align-items-center justify-content-center"
                    }
                    icon={<UploadOutlined />}
                    onClick={() => {
                      if (!isReadyRecord) {
                        navigate("/engraving-design-upload", {
                          state: {
                            ...record,
                            waitingList: false,
                            delete: false,
                          },
                        })
                      } else {
                        navigate("/engraving-design-upload", {
                          state: {
                            ...record,
                            waitingList: false,
                            delete: true,
                          },
                        })
                      }
                    }}
                  />
                </Tooltip>
              )}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 &&
              !record.hasImages && (
                <Tooltip placement="top" title="Edit Order">
                  <Button
                    type="button"
                    className="btn btn_primary d-flex align-items-center justify-content-center"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setUpsertModal({
                        show: true,
                        data: record,
                      })
                    }}
                  />
                </Tooltip>
              )}
            {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 ||
              JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) &&
              !record.hasImages &&
              record.status < 20 && (
                <Tooltip placement="top" title="Move Waiting For Design">
                  <Popconfirm
                    title="Attention!"
                    description={
                      <>
                        Are you sure you want to move order number <br />{" "}
                        <span style={{ fontWeight: "700" }}>
                          {record.orderId}
                        </span>{" "}
                        to the design waiting list?
                      </>
                    }
                    onConfirm={() => {
                      onMoveWaitingList(record)
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="button"
                      className="btn btn_secondary d-flex align-items-center justify-content-center"
                      icon={<ClockCircleOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              )}
            {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 ||
              JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7 ||
              JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) && (
              <Tooltip placement="top" title="Cancel Order">
                <Popconfirm
                  title="Attention!"
                  description="Are you sure you want to cancel the order?"
                  onConfirm={() => {
                    handleChangeStatus(
                      record.id,
                      80,
                      record.sku,
                      record.orderId
                    )
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="button"
                    className="btn btn_quinary d-flex align-items-center justify-content-center"
                    icon={<CloseCircleOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </Space>
        )
      },
    },
  ].filter(item => !item.hidden)

  const handleTableChange = pagination => {
    setLoading(true)
    let payload = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: state.tableParams.total,
    }
    dispatch({ type: "SET_TABLEPARAMS", payload })

    if (
      state.tableParams.current !== pagination.current ||
      state.tableParams.pageSize !== pagination.pageSize
    ) {
      getTableData(payload)
    }
  }

  const onMoveWaitingList = values => {
    Http.post("api/orders/moveWaitingForDesign", {
      categoryId: 3,
      orderId: values.id,
    })
      .then(res => {
        if (res.success) {
          toastr.success(
            `${values.sku} sku, order has been moved to waiting for design list.`
          )
          getTableData()
        } else {
          getTableData()
          toastr.error(
            `${values.sku} sku, order could not be moved to waiting for design list. `
          )
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        getTableData()
      })
  }

  const getPdf = async () => {
    setLoading(true)
    const { recordName, companyId } = form.getFieldValue()
    const findCompany = companyDataList.find(x => x.value === companyId)
    const findRecord = recordNameList.find(x => x.value === recordName)
    try {
      fetch(`${config.apiUrl}/api/orders/downloadPdf`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          orderRecordId: recordName,
          customerId: companyId,
        }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok")
          }
          return response.blob()
        })
        .then(blob => {
          const downloadUrl = window.URL.createObjectURL(blob)
          const link = document.createElement("a")
          link.href = downloadUrl
          link.download = `${findRecord.label} ${findCompany.label} ${moment(
            new Date()
          ).format("DD.MM.YYYY HH.mm.ss")}.pdf`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          getTableData()
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
        })
    } catch (error) {
      console.error("Failed to download PDF", error)
    }
  }

  const onMoveCompany = () => {
    const { recordName } = form.getFieldsValue()
    const findRecord = recordNameList.find(x => x.value === recordName)
    Http.get(
      `api/orders/SendForProduction?recordName=${findRecord.label}&categoryId=3`
    )
      .then(res => {
        if (res.success) {
          toastr.success(
            `${recordName} isimli kayıttaki siparişler üretime gönderilmiştir.`
          )
          getTableData()
          getHaveOrderBefore()
        } else {
          getTableData()
          toastr.error(
            `${recordName} isimli kayıttaki siparişler üretime gönderilememiştir.`
          )
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error?.response?.data?.Message)
      })
  }

  console.log(engravingList)

  return (
    <React.Fragment>
      <style>{css}</style>
      <div className="page-content mb-3">
        {upsertModal.show && (
          <UpsertModal
            upsertModal={upsertModal}
            setLoading={setLoading}
            loading={loading}
            onClose={boolen => {
              setUpsertModal({ data: null, show: false })
              if (boolen) {
                getTableData()
              }
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs
            title="Engraving List"
            button={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Row>
                  <Col
                    lg="12"
                    className="d-flex align-items-center justify-content-end gap-2"
                  >
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      2 &&
                      !haveOrderBefore &&
                      !isReadyRecord && (
                        <Button
                          type="button"
                          className="btn btn_tertiary d-flex align-items-center"
                          onClick={() => {
                            const formValues = form.getFieldValue()
                            let findRecordName = recordNameList.find(
                              x => x.value === formValues.recordName
                            )

                            setUpsertModal({
                              show: true,
                              data: { recordName: findRecordName?.label },
                            })
                          }}
                          icon={<AppstoreAddOutlined />}
                        >
                          Manuel Add Order
                        </Button>
                      )}
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      2 && (
                      <Button
                        type="button"
                        className="btn btn-primary d-flex align-items-center"
                        onClick={() => {
                          navigate(`/engraving-add`, {
                            state: {
                              maxDate: maxDate,
                            },
                          })
                        }}
                        icon={<AppstoreAddOutlined />}
                      >
                        Add Order
                      </Button>
                    )}
                    {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      1 ||
                      JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                        7) &&
                      engravingList.length > 0 && (
                        <Tooltip
                          placement="top"
                          visible={disabledBtnPdf ? undefined : false}
                          title={
                            disabledBtnPdf
                              ? "All seçeneği için pdf dosyası indirilemez. Lütfen customer seçtikten sonra işlem yapınız!"
                              : "Pdf indir"
                          }
                        >
                          <Button
                            type="button"
                            className={
                              disabledBtnPdf
                                ? "btn btn_disabled d-flex align-items-center"
                                : "btn btn-primary d-flex align-items-center"
                            }
                            icon={<DownloadOutlined />}
                            onClick={() => {
                              !disabledBtnPdf && getPdf()
                            }}
                          >
                            Download Pdf
                          </Button>
                        </Tooltip>
                      )}
                  </Col>
                </Row>
              </div>
            }
          />
          <Card className="mt-3">
            <Form form={form} layout="vertical">
              <Row>
                <Col lg={2}>
                  <Form.Item label="Order No & Sku" name="orderId">
                    <Input onPressEnter={() => getTableData(null)} allowClear />
                  </Form.Item>
                </Col>
                {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                  1 ||
                  JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                    7) && (
                  <Col lg={2}>
                    <Form.Item label="Customer" name="companyId">
                      <Select
                        showSearch
                        allowClear
                        options={companyDataList}
                        onChange={e => getRecordsName(e)}
                        disabled={companyDataList.length === 0}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col lg={2}>
                  <Form.Item
                    label="Record Name"
                    name="recordName"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Select
                      options={recordNameList}
                      disabled={recordNameList.length === 0}
                      onChange={(_, data) => {
                        setSelectedRecordName(data)
                        getTableData(null)
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={3}
                  className="d-flex align-items-center justify-content-start gap-2"
                >
                  <Button
                    type="button"
                    className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                    icon={<ClearOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        orderId: "",
                        recordName: "",
                        companyId: "",
                      })
                      JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                        1 && setRecordNameList([])
                      getRecordsName()
                      setEngravingList([])
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                    icon={<SearchOutlined />}
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => {
                          getTableData(null)
                        })
                        .catch(err => {
                          setLoading(false)
                        })
                    }}
                  >
                    Search
                  </Button>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                    2 &&
                    !isReadyRecord && (
                      <Popconfirm
                        title="Attention!"
                        description={
                          <>
                            Üretime gönderme işlemi yaparsanız <br /> bu gün
                            için csv yükleme, manuel order ekleme <br /> vb
                            işlemleri yapamayacaksınız onaylıyor musunuz?
                          </>
                        }
                        onConfirm={() => {
                          onMoveCompany()
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="button"
                          className="btn btn_quinary d-flex align-items-center"
                        >
                          Üretime Gönder
                        </Button>
                      </Popconfirm>
                    )}
                </Col>
              </Row>
            </Form>
            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={engravingList}
              loading={loading}
              pagination={state.tableParams}
              onChange={handleTableChange}
              rowKey={record => record.id}
              rowClassName={record =>
                record.children?.length > 0 ? "multipleRow" : ""
              }
            />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EngravingList
