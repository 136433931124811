import React, { useState } from "react"
import { Col, Row, Label } from "reactstrap"
import { Form, Input, Modal, Card, Space, Button, ColorPicker } from "antd"
import * as Http from "utils/http.helper"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const css = `.ant-tag{
  white-space:normal!important;
  padding: 0 15px
}
.ant-space-item:first-child,.ant-space-item:nth-child(2) ,.ant-space-item:nth-child(3) {
  width:100%;
}
.ant-color-picker-trigger{
  width:100%;
  justify-content: start;
}
.ant-modal-body{
 max-height:600px;
 overflow-x:hidden!important;
 overflow-y:auto!important;
}`

const UpsertModal = ({
  upsertModal,
  setUpsertModal,
  loading,
  setLoading,
  getData,
}) => {
  const [form] = Form.useForm()
  const [frontImage, setFrontImage] = useState(null)
  const [frontPosition, setFrontPosition] = useState([])
  const [backImage, setBackImage] = useState(null)
  const [backPosition, setBackPosition] = useState([])
  const [interiorImage, setInteriorImage] = useState(null)
  const [interiorPosition, setInteriorPosition] = useState([])
  const [spineImage, setSpineImage] = useState(null)
  const [toolsImage, setToolsImage] = useState(null)
  const [stones, setStones] = useState(null)
  const [guitarPick, setGuitarPick] = useState(null)

  const onFinish = values => {
    const arr = [
      {
        sku: values.sku.trim(),
        colorHexCode: values.hexCode ? "#" + values.hexCode.trim() : "#000000",
        price: values.price ? values.price : 0,
      },
    ]
    values.skuColorList?.length > 0 &&
      values.skuColorList.map(item => {
        arr.push({
          sku: item.sku.trim(),
          colorHexCode: item.hexCode ? "#" + item.hexCode.trim() : "#000000",
          price: item.price ? item.price : 0,
        })
      })

    const formData = new FormData()
    formData.append(`productName`, values.productName)
    arr.map((item, index) => {
      formData.append(`skuEngraving[${index}].sku`, item.sku)
      formData.append(`skuEngraving[${index}].colorHexCode`, item.colorHexCode)
      formData.append(`skuEngraving[${index}].price`, item.price)
    })
    let imagesList = []
    if (frontImage) {
      imagesList.push({ value: 10, image: frontImage, position: frontPosition })
    }
    if (backImage) {
      imagesList.push({ value: 20, image: backImage, position: backPosition })
    }
    if (interiorImage) {
      imagesList.push({
        value: 30,
        image: interiorImage,
        position: interiorPosition,
      })
    }
    if (spineImage) {
      imagesList.push({ value: 40, image: spineImage, position: ["spine"] })
    }
    if (toolsImage) {
      imagesList.push({ value: 50, image: toolsImage, position: ["tools"] })
    }
    if (stones) {
      imagesList.push({ value: 60, image: stones, position: ["stones"] })
    }
    if (guitarPick) {
      imagesList.push({
        value: 70,
        image: guitarPick,
        position: ["guitar_pick"],
      })
    }

    imagesList.map((item, index) => {
      if (item.image.length > 0) {
        let val = 50
        ;[...item.image].map((x, i) => {
          formData.append(`positionImage[${index + i}].type`, val)
          formData.append(`positionImage[${index + i}].image`, x)
          val += 10
        })
      } else {
        formData.append(`positionImage[${index}].type`, item.value)
        formData.append(`positionImage[${index}].image`, item.image)
        if (item.position.length > 0) {
          item.position.map((a, b) => {
            formData.append(`positionImage[${index}].positionCode[${b}]`, a)
          })
        }
      }
    })

    // const abc = []
    // for (var p of formData) {
    //   let name = p[0]
    //   let value = p[1]
    //   abc.push({ name, value })
    // }
    if (
      !backImage &&
      !frontImage &&
      !interiorImage &&
      !toolsImage &&
      !spineImage &&
      !guitarPick &&
      !stones
    ) {
      setLoading(false)
      toastr.error(`Görsel ekledikten sonra kaydetme işlemi yapabilirsiniz!`)
    } else {
      Http.post_form_data("api/orders/addEngravingImages", formData)
        .then(res => {
          if (res.success) {
            setLoading(false)
            getData()
            setUpsertModal({ show: false, data: null })
            toastr.success(`Görseller eklenmiştir.`)
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    }
  }
  return (
    <Modal
      title={"Add Engraving Image"}
      maskClosable={false}
      centered
      open={upsertModal.show}
      cancelText="Close"
      okText={"Save"}
      onOk={() => {
        setLoading(true)
        setTimeout(() => {
          form
            .validateFields()
            .then(val => {
              onFinish(val)
            })
            .catch(err => {
              setLoading(false)
            })
        }, 250)
      }}
      onCancel={() => setUpsertModal({ show: false, data: null })}
      width={900}
      confirmLoading={loading}
    >
      <style>{css}</style>

      <Form
        form={form}
        layout="vertical"
        onSubmit={e => {
          form
            .validateFields()
            .then(val => {
              onFinish(val)
            })
            .catch(err => {})
          return false
        }}
      >
        <Card className="mb-3" style={{ backgroundColor: "whitesmoke" }}>
          <Row>
            <Col lg={12}>
              <Space
                style={{
                  display: "flex",
                  marginBottom: 5,
                  alignItems: "center",
                  justifyContent: "start",
                  width: "100%",
                }}
                align="baseline"
              >
                <Form.Item
                  name={"productName"}
                  label={`Product Name  `}
                  rules={[
                    {
                      required: true,
                      message: "Product name alanı zorunludur.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Lütfen engraving'e ait product name giriniz..."
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col lg={12}>
              <Space
                style={{
                  display: "flex",
                  marginBottom: 5,
                  alignItems: "center",
                  justifyContent: "start",
                  width: "100%",
                }}
                align="baseline"
              >
                <Form.Item
                  name={"sku"}
                  label={`Sku`}
                  rules={[
                    {
                      required: true,
                      message: "Sku alanı zorunludur.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Lütfen engraving'e ait sku giriniz..."
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                <Form.Item name={"price"} label={`Price`}>
                  <Input
                    placeholder="Lütfen engraving'e ait price giriniz..."
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item name="hexCode" label={`HexCode`}>
                  <Input
                    placeholder="Lütfen engraving'e ait hexcode giriniz..."
                    style={{ width: "100%" }}
                    onChange={e => {
                      form.setFieldsValue({
                        hexCodeShow: e.target.value,
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item name="hexCodeShow" label={` `}>
                  <ColorPicker
                    onChange={e => {
                      form.setFieldsValue({
                        hexCode: e.toHexString().split("#")[1],
                      })
                    }}
                  />
                </Form.Item>

                <MinusCircleOutlined style={{ marginTop: 12, opacity: 0 }} />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {!upsertModal?.data?.id && (
                <Form.List name="skuColorList" style={{ width: "50%" }}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 5,
                            alignItems: "center",
                            justifyContent: "start",
                            width: "100%",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "sku"]}
                            label={`Sku  ${name + 1}`}
                            rules={[
                              {
                                required: true,
                                message: "Sku alanı zorunludur.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Lütfen engraving'e ait sku giriniz..."
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "price"]}
                            label={`Price  ${name + 1}`}
                            rules={[
                              {
                                required: true,
                                message: "Price alanı zorunludur.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Lütfen engraving'e ait price giriniz..."
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "hexCode"]}
                            label={` HexCode ${name + 1}`}
                          >
                            <Input
                              placeholder="Lütfen engraving'e ait hexcode giriniz..."
                              style={{ width: "100%" }}
                              onChange={e => {
                                const fields = form.getFieldsValue()
                                const { skuColorList } = fields

                                skuColorList[name].hexCodeShow =
                                  skuColorList[name].hexCode

                                form.setFieldsValue({
                                  skuColorList,
                                })
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, "hexCodeShow"]}
                            label=" "
                          >
                            <ColorPicker
                              onChange={e => {
                                const fields = form.getFieldsValue()
                                const { skuColorList } = fields

                                skuColorList[name].hexCode = skuColorList[
                                  name
                                ].hexCodeShow
                                  .toHexString()
                                  .split("#")[1]
                                form.setFieldsValue({
                                  skuColorList,
                                })
                              }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ marginTop: 12, color: "red" }}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          className="btn btn-primary d-flex align-items-center justify-content-center"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add sku and color values for engraving
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              )}
            </Col>
          </Row>
        </Card>
        <Row>
          <Col lg={6}>
            <Card className="mb-3" style={{ backgroundColor: "whitesmoke" }}>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label ">Front Center Template </Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    setFrontImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label ">
                  Front Bottom Center Template{" "}
                </Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label ">
                  Front Left Lower Corner Template{" "}
                </Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label ">
                  Front Right Lower Corner Template{" "}
                </Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label ">
                  Front Vertical on the Right Template{" "}
                </Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                  }}
                />
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="mb-3" style={{ backgroundColor: "whitesmoke" }}>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Inside Center Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    // setInteriorImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Inside Upper Side Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    // setInteriorImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Inside Left Side Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    // setInteriorImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Inside Right Side Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    // setInteriorImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">
                  Inside Left Lower Corner Template
                </Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    // setInteriorImage(e.target.files[0])
                  }}
                />
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="mb-3" style={{ backgroundColor: "whitesmoke" }}>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Back Center Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    // setBackImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">
                  Back Bottom Center Template
                </Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    // setBackImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">
                  Back Right Lower Corner Template
                </Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    // setBackImage(e.target.files[0])
                  }}
                />
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="mb-3" style={{ backgroundColor: "whitesmoke" }}>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Spine Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    setSpineImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Tools Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    setToolsImage(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Stones Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    setStones(e.target.files[0])
                  }}
                />
              </div>
              <div className="mb-3" style={{ width: "100%" }}>
                <Label className="form-label">Guitar Pick Template</Label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={e => {
                    e.target.files[0].url = URL.createObjectURL(
                      e.target.files[0]
                    )
                    setGuitarPick(e.target.files[0])
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        {false &&
          (frontImage ||
            backImage ||
            spineImage ||
            interiorImage ||
            guitarPick ||
            stones ||
            toolsImage) && (
            <Card title="Yüklenecek Görseller">
              <Row>
                <Col lg={3}>
                  {frontImage?.url && (
                    <>
                      <div
                        style={{ textAlign: "center", marginBottom: 10 }}
                      >{`Front `}</div>
                      <img
                        data-dz-thumbnail=""
                        src={frontImage?.url}
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          width: "100%",
                        }}
                      />
                    </>
                  )}
                </Col>
                <Col lg={3}>
                  {backImage?.url && (
                    <>
                      <div
                        style={{ textAlign: "center", marginBottom: 10 }}
                      >{`Back `}</div>
                      <img
                        data-dz-thumbnail=""
                        src={backImage?.url}
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          width: "100%",
                        }}
                      />
                    </>
                  )}
                </Col>
                <Col lg={3}>
                  {interiorImage?.url && (
                    <>
                      <div
                        style={{ textAlign: "center", marginBottom: 10 }}
                      >{`Interior `}</div>
                      <img
                        data-dz-thumbnail=""
                        src={interiorImage?.url}
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          width: "100%",
                        }}
                      />{" "}
                    </>
                  )}{" "}
                </Col>
                <Col lg={3}>
                  {spineImage?.url && (
                    <>
                      <div
                        style={{ textAlign: "center", marginBottom: 10 }}
                      >{`Spine `}</div>
                      <img
                        data-dz-thumbnail=""
                        src={spineImage?.url}
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          width: "100%",
                        }}
                      />
                    </>
                  )}{" "}
                </Col>
              </Row>
              <Row>
                {toolsImage?.url && (
                  <Col lg={3} style={{ marginTop: "10px" }}>
                    <div
                      style={{ textAlign: "center", marginBottom: 10 }}
                    >{`Tools`}</div>{" "}
                    <img
                      data-dz-thumbnail=""
                      src={toolsImage?.url}
                      style={{
                        maxHeight: "200px",
                        maxWidth: "200px",
                        width: "100%",
                      }}
                    />
                  </Col>
                )}
                {stones?.url && (
                  <Col lg={3} style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: 10,
                      }}
                    >{`Stones`}</div>{" "}
                    <img
                      data-dz-thumbnail=""
                      src={stones?.url}
                      style={{
                        maxHeight: "200px",
                        maxWidth: "200px",
                        width: "100%",
                      }}
                    />
                  </Col>
                )}
                {guitarPick?.url && (
                  <Col lg={3} style={{ marginTop: "10px" }}>
                    <div
                      style={{ textAlign: "center", marginBottom: 10 }}
                    >{`Guitar Pick`}</div>{" "}
                    <img
                      data-dz-thumbnail=""
                      src={guitarPick?.url}
                      style={{
                        maxHeight: "200px",
                        maxWidth: "200px",
                        width: "100%",
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Card>
          )}
      </Form>
    </Modal>
  )
}

export default UpsertModal
const { Search } = Input
