import React, { useEffect, useState } from "react"
import { Row, Col, Select, Input, Form, Modal } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import "toastr/build/toastr.min.css"
const workerRoleList = [
  { label: "Shipment", value: "Shipment" },
  { label: "Completed", value: "Completed" },
]
const UpsertModal = ({ setUpsertModal, upsertModal, getData, setLoading }) => {
  let navigate = useNavigate()
  const [form] = Form.useForm()
  const [roleList, setRoleList] = useState([])
  const [showCompany, setShowCompany] = useState(false)
  const [showWorkerType, setShowWorkerType] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const getCompany = () => {
    Http.get("api/companies/getCompanies")
      .then(res => {
        if (res.data.length > 0) {
          setCompanyList(
            res.data.map(item => {
              return { label: item.name, value: item.id }
            })
          )

          if (upsertModal?.data?.id) {
            form.setFieldsValue({
              companyId: upsertModal.data?.companyId,
            })
          }
        } else {
          toastr.error(
            "You can add users after adding a company to Customer. You are being redirected, wait!"
          )
          setTimeout(() => {
            navigate("/company-list")
          }, 2000)
        }
      })
      .catch(function (error) {
        toastr.error(error.response.Message)
      })
  }

  const getRoles = () => {
    Http.get("api/roles/getRoles")
      .then(res => {
        if (res?.success) {
          let arr = []
          if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1) {
            res.data.map(item => {
              if (
                item.id === 1 &&
                JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1
              ) {
                arr.push({ label: item.name, value: item.id })
              } else if (item.id !== 1) {
                arr.push({ label: item.name, value: item.id })
              }
            })
            setRoleList(arr)
          } else {
            res.data.map(item => {
              if (item.id !== 3) {
                arr.push({ label: item.name, value: item.id })
              }
            })
            setRoleList(arr)
          }
          form.setFieldsValue({
            roleId: upsertModal.data?.roleId,
          })
        }
      })
      .catch(function (error) {
        toastr.error(error.response.message)
      })
  }
  useEffect(() => {
    getRoles()
  }, [])

  const getUser = userId => {
    setLoading(true)
    Http.get(`api/users/GetUser?UserId=${userId}`)
      .then(res => {
        if (res?.success) {
          const response = res.data
          setLoading(false)
          if (response?.roleId === 2) {
            if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1) {
              setShowCompany(true)
              getCompany()
            } else {
              setShowCompany(false)
            }
          } else if (response?.roleId === 7) {
            setShowWorkerType(true)

            response?.actions &&
              form.setFieldsValue({
                actions: response?.actions[0],
              })
          } else {
            setShowWorkerType(false)
          }
          form.setFieldsValue({
            companyId: response?.companyId,
            email: response?.email,
            firstName: response?.firstName,
            lastName: response?.lastName,
          })
          setTimeout(() => {
            form.setFieldsValue({
              password: "",
              confirmPassword: "",
              phoneNumber: response?.phoneNumber ? response?.phoneNumber : "",
            })
          }, 500)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.message)
      })
  }

  useEffect(() => {
    if (upsertModal?.data?.id) {
      getUser(upsertModal?.data?.id)
    }
  }, [upsertModal])

  const handleSave = values => {
    const obj = {
      id: upsertModal?.data?.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobilePhone: values.phoneNumber,
      roleId: values.roleId.toString(),
      companyId: values.companyId,
      password: values.password ? values.password : "",
      confirmPassword: values.confirmPassword ? values.confirmPassword : "",
      actions: values.workerRole ? [values.workerRole] : [],
    }
    if (values.roleId.toString() !== "2") {
      delete obj.companyId
    }
    if (upsertModal?.data?.id) {
      Http.post("api/users/UpdateUser", obj)
        .then(res => {
          if (res.success) {
            toastr.success(`User has been updated.`)
            getData()
            setUpsertModal({ show: false, data: [] })
          } else {
            toastr.error(`User update failed`)
          }
          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.Message)
        })
    } else {
      delete obj.id
      Http.post("api/users/createuser", obj)
        .then(res => {
          if (res.success) {
            getData()
            toastr.success(`User added to the system`)
            setUpsertModal({ show: false, data: [] })
          } else {
            toastr.error(`User could not be added to the system`)
          }
          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.Message)
        })
    }
  }

  return (
    <Modal
      width={750}
      maskClosable={false}
      open={true}
      centered
      onOk={() => {
        form
          .validateFields()
          .then(val => {
            setLoading(true)
            handleSave(val)
          })
          .catch(err => {})
      }}
      onCancel={() => setUpsertModal({ show: false, data: [] })}
      okText="Save"
      title={
        upsertModal.data?.id ? (
          <span style={{ display: "flex", alignItems: "center" }}>
            Update User{" "}
            <span style={{ fontSize: 14, marginLeft: 6 }}>
              <span style={{ opacity: "0.3" }}>[ Kullanıcı: </span>
              {upsertModal.data?.nameSurname}{" "}
              <span style={{ opacity: "0.3" }}>| Company: </span>
              {upsertModal.data?.companyName}
              <span style={{ opacity: "0.3" }}> ]</span>
            </span>
          </span>
        ) : (
          "Create New User"
        )
      }
    >
      <Form form={form} layout="vertical" style={{ marginTop: 25 }}>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label="User Role"
              name="roleId"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                disabled={upsertModal?.data?.id}
                options={roleList}
                onChange={e => {
                  if (e === 2) {
                    // customer admin
                    getCompany()
                    setShowCompany(true)
                    setShowWorkerType(false)
                  } else if (e === 7) {
                    // company worker
                    setShowWorkerType(true)
                    setShowCompany(false)
                  } else {
                    setShowCompany(false)
                    setShowWorkerType(false)
                  }
                }}
              />
            </Form.Item>
          </Col>
          {showCompany && (
            <Col lg={12}>
              <Form.Item
                label="Customer"
                name="companyId"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <Select
                  disabled={upsertModal?.data?.id}
                  style={{ width: "100%" }}
                  options={companyList}
                />
              </Form.Item>
            </Col>
          )}
          {showWorkerType && (
            <Col lg={12}>
              <Form.Item
                label="Actions"
                name="workerRole"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <Select
                  allowClear
                  disabled={upsertModal?.data?.id}
                  style={{ width: "100%" }}
                  options={workerRoleList}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Input type="email" disabled={upsertModal?.data?.id} />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item label="Mobile Phone" name="phoneNumber">
              <Input type="text" />
            </Form.Item>
          </Col>{" "}
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              label="Password"
              name="password"
              autocomplete="off"
              rules={[
                {
                  required: upsertModal?.data?.id ? false : true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input.Password
                type="password"
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                {
                  required: upsertModal?.data?.id ? false : true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input.Password
                type="password"
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default UpsertModal
