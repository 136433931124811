import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Button,
  Select,
  Divider,
  Form,
  DatePicker,
  Row,
  Tooltip,
  Col,
  Drawer,
  Card,
  Spin,
  Table,
} from "antd"
import {
  SearchOutlined,
  ClearOutlined,
  EyeFilled,
  EyeOutlined,
} from "@ant-design/icons"
import * as Http from "utils/http.helper"
import { useNavigate } from "react-router-dom"
import toastr from "toastr"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import Sounds from "utils/sounds"

dayjs.extend(customParseFormat)
const { RangePicker } = DatePicker
const css = `.ant-form-item{
  margin-bottom:0!important
}`
let dateFormat = "DD/MM/YYYY"
let apiToDateFormat = "MM-DD-YYYY"
const today = dayjs()
const tomorrow = today

const startOfMonth = dayjs().startOf("month")

const EngravingFinancial = () => {
  document.title = "Engraving Financial | EASY JET CONNECT"
  let navigate = useNavigate()
  const [companyForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [drawer, setDrawer] = useState({ show: false, data: null })
  const [value, setValue] = useState(null)
  const [customerName, setCustomerName] = useState(null)
  const [customerDraver, setCustomerDrawer] = useState({
    show: false,
    data: null,
    title: null,
  })
  const [data, setData] = useState([])
  const [detailData, setDetailData] = useState([])
  const [totalInfo, setTotalInfo] = useState({
    count: 0,
    price: 0,
    allCount: 0,
    allPrice: 0,
    allPaymentAmount: 0,
  })
  const [infoLoading, setInfoLoading] = useState(false)
  const [customers, setCustomers] = useState([])
  const [customerNameBtTotalInfo, setCustomerNameBtTotalInfo] = useState({
    price: 0,
    count: 0,
  })

  const getAllCustomers = () => {
    setLoading(true)
    Http.get(`api/companies/GetAllCustomers?categoryId=3`)
      .then(res => {
        if (res?.success && res.data.length > 0) {
          let arr = [{ value: "", label: "Tüm Müşteriler" }]
          res.data.map(item =>
            arr.push({
              value: item.id,
              label: item.name,
            })
          )
          setCustomers(arr)
          companyForm.setFieldsValue({
            customerId: "",
          })
          getDetailCompany()
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("authUserV0")).roleId === 1) {
      getAllCustomers()
    }
    setValue([startOfMonth, tomorrow])
    companyForm.setFieldsValue({
      dates: [startOfMonth, tomorrow],
    })
  }, [])

  const getTotalInfo = () => {
    setInfoLoading(true)
    const formValues = companyForm.getFieldsValue()
    if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) {
      //customer Admin
      Http.get(
        `api/Accounting/GetCustomerApparelEngravingPriceSummary?categoryId=3&startDate=${
          formValues?.dates
            ? dayjs(formValues?.dates[0]).format(apiToDateFormat)
            : ""
        }&endDate=${
          formValues?.dates
            ? dayjs(formValues?.dates[1]).format(apiToDateFormat)
            : ""
        }`
      )
        .then(res => {
          if (res.success) {
            setTotalInfo({
              count: res?.data?.orderCount,
              price: res?.data?.totalPrice,
              allCount: res?.data?.allOrderCount,
              allPrice: res?.data?.allOrderTotalPrice,
              allPaymentAmount: res?.data?.allPaymentAmount,
            })
          }
          setInfoLoading(false)
        })
        .catch(err => {
          setInfoLoading(false)
        })
    } else if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1) {
      //company Admin

      Http.get(
        `api/Accounting/GetCompanyApparelEngravingPriceSummary?categoryId=3&customerId=${
          formValues?.customerId ? formValues?.customerId : ""
        }&startDate=${
          formValues?.dates
            ? dayjs(formValues?.dates[0]).format(apiToDateFormat)
            : ""
        }&endDate=${
          formValues?.dates
            ? dayjs(formValues?.dates[1]).format(apiToDateFormat)
            : ""
        }`
      )
        .then(res => {
          if (res.success) {
            setTotalInfo({
              count: res?.data?.orderCount,
              price: res?.data?.totalPrice,
              allCount: res?.data?.allOrderCount,
              allPrice: res?.data?.allOrderTotalPrice,
              allPaymentAmount: res?.data?.allPaymentAmount,
            })
          }
          setInfoLoading(false)
        })
        .catch(err => {
          setInfoLoading(false)
        })
    }
  }

  const getDetail = () => {
    setLoading(true)
    //customer Admin
    const formValues = companyForm.getFieldsValue()

    Http.get(
      `api/Accounting/getCustomerEngravingRecordPrice?startDate=${
        formValues?.dates
          ? dayjs(formValues?.dates[0]).format(apiToDateFormat)
          : ""
      }&endDate=${
        formValues?.dates
          ? dayjs(formValues?.dates[1]).format(apiToDateFormat)
          : ""
      }`
    )
      .then(res => {
        if (res.success) {
          setData(res?.data)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getTotalInfo()
    if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) {
      getDetail()
    }
  }, [])

  const getDetailCompany = () => {
    setLoading(true)
    //company Admin
    const formValues = companyForm.getFieldsValue()
    Http.get(
      `api/Accounting/getCompanyEngravingRecordPrice?customerId=${
        formValues?.customerId
      }&startDate=${
        formValues?.dates
          ? dayjs(formValues?.dates[0]).format(apiToDateFormat)
          : ""
      }&endDate=${
        formValues?.dates
          ? dayjs(formValues?.dates[1]).format(apiToDateFormat)
          : ""
      }`
    )
      .then(res => {
        if (res.success) {
          setData(res?.data)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const columns = [
    {
      title: "Kayıt Adı",
      dataIndex: "recordName",
    },
    {
      title: "Toplam Sipariş Sayısı",
      dataIndex: "totalCount",
    },
    {
      title: "Taplam Harcama",
      dataIndex: "totalPrice",
      render: (_, row) => {
        return row.totalPrice ? row.totalPrice.toFixed(2) + " $" : ""
      },
    },

    {
      title: " ",
      key: "action",
      width: 50,
      render: (_, record) => {
        return (
          <Tooltip placement="top" title="Detail">
            <Button
              type="button"
              className="btn btn_primary d-flex align-items-center justify-content-center"
              icon={<EyeFilled />}
              onClick={() => {
                if (
                  JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2
                ) {
                  getDetailByRecordId(record)
                } else {
                  getCompanyDetailByRecordId(record)
                }
              }}
            />
          </Tooltip>
        )
      },
    },
  ].filter(item => !item.hidden)

  const columnsCustomerDetail = [
    {
      title: "Ürün Adı",
      dataIndex: "productName",
    },
    {
      title: "Toplam Sipariş Sayısı",
      dataIndex: "totalCount",
    },
    {
      title: "Toplam Harcama",
      dataIndex: "totalPrice",
      render: (_, row) => {
        return row.totalPrice ? row.totalPrice.toFixed(2) + " $" : ""
      },
    },
  ]

  const columnsDetail = [
    {
      title: "Ürün Adı",
      dataIndex: "productName",
    },
    {
      title: "Toplam Sipariş Sayısı",
      dataIndex: "totalCount",
    },
    {
      title: "Toplam Harcama",
      dataIndex: "totalPrice",
      render: (_, row) => {
        return row.totalPrice ? row.totalPrice.toFixed(2) + " $" : ""
      },
    },
  ]
  const getDetailByRecordId = record => {
    setLoading(true)
    Http.get(
      `api/Accounting/GetCustomerEngravingRecordDetailPrice?recordName=${record?.recordName}`
    )
      .then(res => {
        if (res.success) {
          if (res?.data.length > 0) {
            setDetailData(res?.data)
            setDrawer({ show: true, data: record })
          } else {
            toastr.error(
              "Bu müşteri için engraving detay bilgilerine uluşılamadı. Engraving kaydı olduğunundan emin olun!"
            )
          }
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const getCompanyDetailByRecordId = record => {
    setLoading(true)
    const { customerId } = companyForm.getFieldsValue()
    console.log(record)
    Http.get(
      `api/Accounting/GetCompanyEngravingRecordDetailPrice?customerId=${customerId}&recordName=${record?.recordName.replaceAll(
        "/",
        "-"
      )}`
    )
      .then(res => {
        if (res.success) {
          if (res?.data.length > 0) {
            setDetailData(res?.data)
            setDrawer({ show: true, data: record })
          } else {
            toastr.error(
              "Bu kayıt için detay bilgilerine uluşılamadı. Lütfen bildiriniz!"
            )
          }
        }
        setLoading(false)
      })
      .catch(() => {
        toastr.error("Lütfen bir müşteri seçtiğinizden emin olunuz!")
        setLoading(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <Spin spinning={loading}>
          <style>{css}</style>
          <Breadcrumbs title="Engraving Finansal Bilgilendirme" />

          <Row gutter={[16, 16]}>
            <Col lg={8}>
              <Card className="mini-stats-wid mt-3" hoverable>
                <Row gutter={[16, 16]}>
                  <Col lg={24}>
                    <Divider orientation="left">
                      {JSON.parse(localStorage.getItem("authUserV0")).roleId ===
                      1
                        ? customerName?.length > 0
                          ? `${customerName}`
                          : "Tüm Müşteriler"
                        : "Sipariş Bilgisi"}
                      <span style={{ opacity: "0.6", fontSize: 14 }}>
                        {value
                          ? ` [
                      ${dayjs(value[0]).format(dateFormat)} /
                       ${dayjs(value[1]).format(dateFormat)}
                    ]`
                          : ``}
                      </span>
                    </Divider>
                  </Col>
                  <Col lg={12}>
                    <div className="ui">
                      <div className="ui_box">
                        <div className="ui_box__inner">
                          <h5> Sipariş Sayısı</h5>
                          <div className="stat_left">
                            {!infoLoading ? (
                              <h4>{totalInfo?.count}</h4>
                            ) : (
                              <Spin spinning={true} />
                            )}
                          </div>
                          <div className="progress_graph">
                            <div className="progress_graph__bar--4"></div>
                            <div className="progress_graph__bar--1"></div>
                            <div className="progress_graph__bar--2"></div>
                            <div className="progress_graph__bar--3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="ui">
                      <div className="ui_box ui_box_orange">
                        <div className="ui_box__inner">
                          <h5>Harcama </h5>
                          <div className="stat">
                            {!infoLoading ? (
                              <h4> ${totalInfo.price.toFixed(2)}</h4>
                            ) : (
                              <Spin spinning={true} text={totalInfo.price} />
                            )}
                          </div>
                          <div className="progress">
                            <div
                              className="progress_bar"
                              style={{
                                width: `${
                                  (totalInfo?.price * 100) /
                                  totalInfo?.allPaymentAmount
                                }%`,
                                background: "#000000",
                              }}
                            ></div>
                            <div className="progress_score">
                              %
                              {totalInfo?.allPaymentAmount
                                ? (
                                    (totalInfo?.price * 100) /
                                    totalInfo?.allPaymentAmount
                                  ).toFixed(2)
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={16}>
              <Card hoverable className="mt-3">
                <Row gutter={[16, 16]}>
                  <Col lg={24}>
                    <Divider orientation="left">Cüzdan</Divider>
                    {((totalInfo?.allPaymentAmount - totalInfo?.allPrice) *
                      100) /
                      totalInfo?.allPaymentAmount <=
                      0 && (
                      <span
                        style={{
                          color: "red",
                          fontSize: 14,
                          fontWeight: "700",
                          opacity: "0.6",
                        }}
                      >
                        {JSON.parse(localStorage.getItem("authUserV0"))
                          .roleId === 1
                          ? " * Kalan tutar negatif değere veya sıfıra ulaşmıştır. Negatif bakiyeli kullanıcılardan ödeme taleb ediniz!"
                          : "* Kalan tutar negatif değere veya sıfıra ulaşmıştır. Lütfen ödeme yapınız!"}
                      </span>
                    )}
                  </Col>
                  <Col lg={6}>
                    <div className="ui">
                      <div className="ui_box">
                        <div className="ui_box__inner">
                          <h5>Toplam Sipariş Sayısı</h5>
                          <div className="stat_left">
                            {!infoLoading ? (
                              <h4>{totalInfo?.allCount}</h4>
                            ) : (
                              <Spin spinning={true} />
                            )}
                          </div>
                          <div className="progress_graph">
                            <div className="progress_graph__bar--2"></div>
                            <div className="progress_graph__bar--3"></div>
                            <div className="progress_graph__bar--1"></div>
                            <div className="progress_graph__bar--4"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="ui">
                      <div className="ui_box">
                        <div className="ui_box__inner">
                          <div className="stat">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {" "}
                              <h5>Toplam Ödeme</h5>{" "}
                              <Tooltip
                                placement="top"
                                title={"Detaylı Görüntüle"}
                              >
                                <Button
                                  className="btn btn_primary d-flex align-items-center justify-content-center"
                                  onClick={() =>
                                    navigate("/payment-management/3")
                                  }
                                  icon={<EyeOutlined />}
                                  size="small"
                                />
                              </Tooltip>
                            </div>{" "}
                            {!infoLoading ? (
                              <h4>${totalInfo?.allPaymentAmount.toFixed(2)}</h4>
                            ) : (
                              <Spin spinning={true} />
                            )}
                          </div>
                          <div className="progress_graph">
                            <div className="progress_graph__bar--1"></div>
                            <div className="progress_graph__bar--2"></div>
                            <div className="progress_graph__bar--3"></div>
                            <div className="progress_graph__bar--4"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="ui">
                      <div className="ui_box ui_box_red">
                        <div className="ui_box__inner">
                          <h5>Toplam Harcama</h5>
                          <div className="stat">
                            {!infoLoading ? (
                              <h4>${totalInfo?.allPrice?.toFixed(2)}</h4>
                            ) : (
                              <Spin spinning={true} />
                            )}
                          </div>
                          <div className="progress">
                            <div
                              className="progress_bar"
                              style={{
                                width: `${(
                                  (totalInfo?.allPrice * 100) /
                                  totalInfo?.allPaymentAmount
                                ).toFixed(2)}%`,
                                background: "#000000",
                              }}
                            ></div>
                            <div className="progress_score">
                              %
                              {(
                                (totalInfo?.allPrice * 100) /
                                totalInfo?.allPaymentAmount
                              ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="ui">
                      <div className="ui_box ui_box_orange">
                        <div className="ui_box__inner">
                          <div className="stat">
                            <h5>Kalan Anlık Tutar</h5>
                            {!infoLoading ? (
                              <h4>
                                ${" "}
                                {(
                                  totalInfo?.allPaymentAmount -
                                  totalInfo?.allPrice
                                ).toFixed(2)}
                              </h4>
                            ) : (
                              <Spin spinning={true} />
                            )}
                          </div>
                          <div className="progress">
                            <div
                              className="progress_bar"
                              style={{
                                width: `${
                                  ((totalInfo?.allPaymentAmount -
                                    totalInfo?.allPrice) *
                                    100) /
                                  totalInfo?.allPaymentAmount
                                }%`,
                                background: "#000000",
                              }}
                            ></div>
                            <div className="progress_score">
                              %
                              {(
                                ((totalInfo?.allPaymentAmount -
                                  totalInfo?.allPrice) *
                                  100) /
                                totalInfo?.allPaymentAmount
                              ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col lg={24}>
              <Card hoverable>
                <Form form={companyForm} layout="vertical" className="mb-3">
                  <Row gutter={[16, 16]}>
                    {JSON.parse(localStorage.getItem("authUserV0")).roleId ===
                      1 && (
                      <Col lg={4}>
                        <Form.Item label="Müşteri" name="customerId">
                          <Select
                            showSearch
                            placeholder="Müşteri Seçiniz!"
                            options={customers}
                            style={{ minWidth: "150px" }}
                            onChange={(e, data) => {
                              setCustomerName(data.label)
                              getTotalInfo()
                              if (
                                JSON.parse(localStorage.getItem("authUserV0"))
                                  ?.roleId === 2
                              ) {
                                getDetail()
                              } else {
                                getDetailCompany()
                              }
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col lg={4}>
                      <Form.Item label="Başlangıç/Bitiş Tarihi" name="dates">
                        <RangePicker
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          allowClear
                          onChange={val => {
                            if (val) {
                              setValue(val)
                              if (val[0] && val[1]) {
                                getTotalInfo()
                                if (
                                  JSON.parse(localStorage.getItem("authUserV0"))
                                    ?.roleId === 2
                                ) {
                                  getDetail()
                                } else {
                                  getDetailCompany()
                                }
                              }
                            } else {
                              setValue(null)
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      lg={4}
                      className="d-flex align-items-end justify-content-start gap-2"
                    >
                      <Button
                        type="button"
                        className="btn btn-light d-flex align-items-center justify-content-center"
                        icon={<ClearOutlined />}
                        style={{
                          border: "1px solid #f94040",
                          background: "#f94040",
                          color: "white",
                          width: "100%",
                        }}
                        onClick={() => {
                          companyForm.resetFields()
                          setValue(null)
                          getTotalInfo()
                          if (
                            JSON.parse(localStorage.getItem("authUserV0"))
                              ?.roleId === 2
                          ) {
                            getDetail()
                          } else {
                            getDetailCompany()
                          }
                          companyForm.setFieldsValue({
                            customerId: "",
                          })
                        }}
                      >
                        Temizle
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-primary d-flex align-items-center justify-content-center "
                        style={{
                          border: "1px solid #556ee6",
                          width: "100%",
                        }}
                        icon={<SearchOutlined />}
                        onClick={() => {
                          getTotalInfo()
                          if (
                            JSON.parse(localStorage.getItem("authUserV0"))
                              ?.roleId === 2
                          ) {
                            getDetail()
                          } else {
                            getDetailCompany()
                          }
                        }}
                      >
                        Ara
                      </Button>
                    </Col>
                    {JSON.parse(localStorage.getItem("authUserV0")).roleId ===
                      1 && (
                      <Col lg={12}>
                        <Row
                          gutter={[12, 12]}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Col
                            lg={10}
                            style={{
                              display: "flex",
                              alignItems: "end",
                              justifyContent: "end",
                              columnGap: 10,
                            }}
                          >
                            <Form.Item
                              label="Müşterilere Ait Ürün Satış Harcama Bilgisi"
                              name="multiple_customer_id"
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                placeholder="Bir/Birden fazla müşteri seçiniz!"
                                options={customers.filter(x => x.value !== "")}
                                style={{ minWidth: "400px" }}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                            <Button
                              type="button"
                              className="btn btn_primary d-flex align-items-center justify-content-center"
                              icon={<EyeFilled />}
                              style={{ minWidth: 32 }}
                              onClick={() => {
                                const { multiple_customer_id } =
                                  companyForm.getFieldsValue()
                                if (multiple_customer_id) {
                                  let filter = customers.filter(x =>
                                    multiple_customer_id.includes(x.value)
                                  )
                                  let text = ""
                                  filter.map((item, index) => {
                                    if (index === filter.length - 1) {
                                      text += item.label
                                    } else {
                                      text += item.label + " | "
                                    }
                                  })
                                  setInfoLoading(true)
                                  Http.post(
                                    `api/accounting/GetCompanyEngravingRecordDetailPriceByCustomerIds`,
                                    { customerIds: multiple_customer_id }
                                  )
                                    .then(res => {
                                      if (res.success) {
                                        let price = 0
                                        let count = 0
                                        res.data.map(item => {
                                          price += item.totalPrice
                                          count += item.totalCount
                                        })
                                        setCustomerNameBtTotalInfo({
                                          price: price.toFixed(2) + " $",
                                          count: count,
                                        })
                                        setCustomerDrawer({
                                          show: true,
                                          data: res.data,
                                          title: `[ ${text} ]`,
                                        })
                                      }
                                      setInfoLoading(false)
                                    })
                                    .catch(err => {
                                      setInfoLoading(false)
                                    })
                                } else {
                                  toastr.warning(
                                    "Lütfen en az bir tane müşteri seçimi yapınız."
                                  )
                                  Sounds.warning()
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Form>
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  dataSource={data}
                  rowKey={record => record.id}
                />
              </Card>
            </Col>
          </Row>
          <Drawer
            title={`Kayıt Adı: ${drawer?.data?.recordName}`}
            placement="right"
            size="large"
            onClose={() => setDrawer({ show: false, data: null })}
            open={drawer.show}
          >
            <Table
              bordered
              size="small"
              columns={columnsDetail}
              dataSource={detailData}
              rowKey={record => record.id}
            />
          </Drawer>

          <Drawer
            title={
              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col>{`${customerDraver?.title} Ürün Detayı`}</Col>
                  <Col>
                    Toplam Sipariş sayısı:{" "}
                    <span style={{ color: "red", fontWeight: "700" }}>
                      {customerNameBtTotalInfo.count}
                    </span>
                    <br /> Toplam Harcama:{" "}
                    <span style={{ color: "red", fontWeight: "700" }}>
                      {customerNameBtTotalInfo.price}
                    </span>
                  </Col>
                </Row>
              </>
            }
            placement="right"
            size="large"
            onClose={() =>
              setCustomerDrawer({ show: false, data: null, title: null })
            }
            open={customerDraver.show}
          >
            <Table
              bordered
              size="small"
              columns={columnsCustomerDetail}
              dataSource={customerDraver.data}
              rowKey={record => record.id}
              pagination={false}
            />
          </Drawer>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default EngravingFinancial
