import axios from "axios"
import config from "config"

const url = endpoint => {
  return `${config.apiUrl}/${endpoint}`
}

const postLogin = (endpoint, data = {}) => {
  return axios
    .post(url(endpoint), data, {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    })
    .then(res => res.data)
}
const post = (endpoint, data = {}) => {
  return axios
    .post(url(endpoint), data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(res => res.data)
    .catch(err => err.response.data)
}

const post_form_data = (endpoint, data = {}) => {
  return axios
    .post(url(endpoint), data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(res => res.data)
}
const createFormData = (endpoint, data = {}) => {
  return axios
    .post(url(endpoint), data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(res => res.data)
}
const get = endpoint => {
  return axios
    .get(url(endpoint), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(res => res.data)
}
const getUserInfo = (endpoint, headers) => {
  return axios
    .get(url(endpoint), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(res => res.data)
}

export { postLogin, post, get, getUserInfo, post_form_data, createFormData }
