import React, { useState, useEffect, useRef } from "react"
import { Table, Button, Tooltip, Result, Collapse, Popconfirm } from "antd"
import * as Http from "utils/http.helper"
import UpsertModal from "./modals/upsert"
import { getColumnSearchProps } from "utils/filter"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const { Panel } = Collapse

const StyleSizeManagement = () => {
  document.title = "Size  Management | EASY JET CONNECT"
  const searchInput = useRef(null)
  const [loading, setLoading] = useState(true)
  const [upsertModal, setUpsertModal] = useState({
    show: false,
    data: null,
    type: null,
  })
  const [size, setSize] = useState([])
  const [searchText, setSearchText] = useState("")
  const [styleList, setStyleList] = useState([])

  const getData = () => {
    setLoading(true)
    Http.get(`api/orders/GetAllProperties`)
      .then(res => {
        if (res?.success && res?.data.length > 0) {
          const size = []
          res.data.map(item => {
            item.sizes.map(x => {
              size.push({
                styleId: item.styleId,
                styleName: item.styleName,
                name: x.name,
                id: x.id,
              })
            })
          })
          setSize(size)
          setStyleList(
            res.data.map(item => {
              return {
                label: item.styleName,
                value: item.styleId,
              }
            })
          )
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const onDelete = id => {
    setLoading(true)
    Http.post(`api/sizes/remove`, {
      sizeId: id,
    })
      .then(res => {
        if (res?.success) {
          toastr.success("Deletion completed successfully")
          getData()
          setLoading(false)
        } else {
          setLoading(false)
          toastr.error(res?.Message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.message)
      })
  }

  const columns = [
    {
      title: "Style Name",
      dataIndex: "styleName",
      key: "styleName",
      ...getColumnSearchProps(
        "styleName",
        "Filter by size style name",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.styleName.localeCompare(b.styleName),
    },
    {
      title: "Size Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps(
        "name",
        "Filter by size size name",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: " ",
      key: "action",
      align: "center",
      width: 120,
      render: (_, record) => (
        <div
          className="d-flex align-items-center justify-content-start"
          style={{ columnGap: 10 }}
        >
          <Tooltip placement="top" title="Edit Size">
            <Button
              type="button"
              className="btn btn-primary d-flex align-items-center justify-content-center m-auto"
              icon={<i className="mdi mdi-pencil" />}
              onClick={() => {
                setUpsertModal({ show: true, data: record, type: "size" })
              }}
            />
          </Tooltip>
          <Popconfirm
            title="Attention!"
            description={
              <>
                Are you sure you want to delete size{" "}
                <span style={{ fontWeight: "700" }}>{record.name}</span> ?
              </>
            }
            onConfirm={() => {
              onDelete(record.id)
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="top" title="Delete">
              <Button
                className="btn btn_quinary d-flex align-items-center justify-content-center m-auto"
                type="button"
                icon={<i className="mdi mdi-delete" />}
                style={{ marginRight: 5 }}
              />
            </Tooltip>
          </Popconfirm>{" "}
        </div>
      ),
    },
  ]

  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="If you haven't selected any company or added financial data yet, you can select and list a company or create a new financial value."
      />
    ),
  }
  return (
    <React.Fragment>
      {upsertModal.show && (
        <UpsertModal
          upsertModal={upsertModal}
          setUpsertModal={setUpsertModal}
          loading={loading}
          setLoading={setLoading}
          getData={getData}
          styleList={styleList}
        />
      )}
      <div className="page-content mb-3">
        <Collapse activeKey={["1"]} expandIcon={() => false}>
          <Panel
            key="1"
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Size Managemenet
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    columnGap: "15px",
                  }}
                >
                  <div>
                    <Button
                      className="btn btn-primary d-flex align-items-center justify-content-end"
                      onClick={() =>
                        setUpsertModal({ show: true, data: null, type: "size" })
                      }
                      icon={
                        <i className="bx bx-plus font-size-16 align-middle"></i>
                      }
                    >
                      Add Size
                    </Button>
                  </div>
                </div>
              </div>
            }
          >
            <Table
              size="small"
              bordered
              columns={columns}
              dataSource={size}
              loading={loading}
              locale={locale}
            />
          </Panel>
        </Collapse>
      </div>
    </React.Fragment>
  )
}

export default StyleSizeManagement
