import React, { useState } from "react"
import { Form, Select, Row, Modal, Col, Spin } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const { Option } = Select
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}
const menuValue = [
  { value: 1, label: "Apparel" },
  { value: 2, label: "DTF" },
  { value: 3, label: "Engraving" },
  { value: 4, label: "Uvdtf" },
  { value: 5, label: "Package" },
]

const CopyModal = ({
  setCopyModal,
  loading,
  setLoading,
  customersList,
  getData,
}) => {
  const [form] = Form.useForm()
  const [toCustomerList, setToCustomerList] = useState([])

  const handleChange = val => {
    const filter = customersList.filter(x => x.value !== val)
    setToCustomerList(filter)
  }

  const onFinish = values => {
    setLoading(true)
    const obj = {
      fromCompanyId: values.fromCompanyId,
      toCompanyId: values.toCompanyId,
    }

    Http.post("api/Accounting/CopyProductPrices", obj)
      .then(res => {
        if (res.success) {
          toastr.success(`Process Success.`)
          setCopyModal(false)
          getData()
          setLoading(false)
        } else {
          setLoading(false)
          toastr.success(`Process Fail.`)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.title)
      })
  }

  return (
    <Modal
      title={"Price Copy Process"}
      centered
      maskClosable={false}
      open={true}
      cancelText="Close"
      okText={"Copy"}
      onOk={() => {
        form
          .validateFields()
          .then(val => {
            onFinish(val)
          })
          .catch(err => {})
      }}
      onCancel={() => setCopyModal(false)}
      width={600}
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <Row style={{ marginTop: 25 }}>
          <Col span={24}>
            <Form {...layout} form={form}>
              <Form.Item
                label="From Company"
                name="fromCompanyId"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select a company"
                  style={{ minWidth: "150px" }}
                  onChange={e => {
                    setToCustomerList([])
                    handleChange(e)
                  }}
                >
                  {customersList.map(item => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="To Company"
                name="toCompanyId"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select a company"
                  style={{ minWidth: "150px" }}
                  disabled={toCustomerList.length > 0 ? false : true}
                >
                  {toCustomerList.map(item => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export default CopyModal
