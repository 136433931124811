import React, { useState, useRef, useEffect } from "react"
import {
  Form,
  Input,
  Row,
  Col,
  Popconfirm,
  Button,
  Collapse,
  Tag,
  Table,
  Result,
} from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import * as moment from "moment"
const { Panel } = Collapse
import Sounds from "utils/sounds"
import { SearchOutlined, ClearOutlined, EyeTwoTone } from "@ant-design/icons"
import { useLocation } from "react-router-dom"
import config from "config"
import "toastr/build/toastr.min.css"

const statusPackage = [
  {
    id: 10,
    name: "Paket Hazır",
  },
  {
    id: 20,
    name: "Paket Gönderildi",
  },
  {
    id: 30,
    name: "Paket Teslim Alındı",
  },
]
const statusProduct = [
  {
    id: 10,
    name: "Ürün Hazır",
  },
  {
    id: 20,
    name: "Ürün Gönderildi",
  },
  {
    id: 30,
    name: "Ürün Teslim Alındı",
  },
  {
    id: 40,
    name: "Ürün Çıkarıldı",
  },
]
const PackageList = () => {
  document.title = "Received | EASY JET CONNECT"
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [packageData, setPackageData] = useState([])
  const [data, setData] = useState([])
  const [totalItems, settotalItems] = useState(0)
  const [product, setProduct] = useState(null)
  const [form] = Form.useForm()
  const [searchForm] = Form.useForm()
  const [formProduct] = Form.useForm()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const productBarcode = params.get("productBarcode")
  const packageBackode = params.get("packageBarcode")
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })
  const [tableParamsPackages, setTableParamsPackagesPackages] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const getData = (pagination, barcode, productBarcode) => {
    setLoading(true)
    Http.get(
      `api/packages/GetCompanyPackageProducts?PageNumber=${
        pagination?.current ? pagination.current : 1
      }&Limit=${pagination.limit ? pagination.limit : 10}${
        barcode ? "&PackageBarcode=" + barcode : ""
      }${productBarcode ? "&ProductBarcode=" + productBarcode : ""}`
    )
      .then(res => {
        if (res?.data?.data?.length > 0) {
          setData(res.data.data)
          setTableParams({
            current: pagination?.current ? pagination.current : 1,
            pageSize: pagination?.pageSize ? pagination.pageSize : 10,
            total: res?.data?.totalItems,
          })
          settotalItems(res?.data?.totalItems)
          setLoading(false)
        } else {
          setData([])
          setTableParams({
            current: 1,
            pageSize: 10,
            total: 0,
          })
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }

  const formControl = () => {
    const values = form.getFieldsValue()
    console.log(values)
    values.barcode ? getData(tableParams, values.barcode.trim()) : setData([])
  }

  const formControlProduct = () => {
    setLoading(true)
    const values = form.getFieldsValue()
    let finder = packageData.find(x => x.barcode === values.barcode)
    if (finder.status !== 30) {
      formProduct
        .validateFields()
        .then(value => {
          Http.post("api/packages/UpdateCompanyPackageProductStatus", {
            packageBarcode: values.barcode,
            productBarcode: value.barcodeProduct,
          })
            .then(res => {
              if (res.success) {
                setData([])
                Sounds.success()
                formProduct.setFieldsValue({
                  barcodeProduct: "",
                })
                getData(tableParams, values.barcode.trim())
                const values2 = searchForm.getFieldsValue()
                getPackageData(tableParamsPackages, values2)
                setTimeout(() => {
                  inputRef.current && inputRef.current.focus()
                }, 600)
              } else {
                setLoading(false)
                formProduct.setFieldsValue({
                  barcodeProduct: "",
                })
                setTimeout(() => {
                  inputRef.current && inputRef.current.focus()
                }, 600)
                if (finder) {
                  toastr.error(`Paket içerisinde aranan ürün bulunamadı.`)
                } else {
                  toastr.error(
                    "Aranan paket bulunamamıştır. Paket barkodunu kontrol ediniz."
                  )
                }
                Sounds.error()
              }
            })
            .catch(function (error) {
              setLoading(false)
              formProduct.setFieldsValue({
                barcodeProduct: "",
              })
              setTimeout(() => {
                inputRef.current && inputRef.current.focus()
              }, 600)
              toastr.error(error.response.data.Message)
              Sounds.error()
            })
        })
        .catch(err => {
          setLoading(false)
        })
    } else {
      Sounds.warning()
      toastr.error(`Paketteki tüm ürünler zaten teslim alınmıştır.`)
      setLoading(false)
      form.setFieldsValue({
        barcode: "",
      })
      form.validateFields()
      formProduct.setFieldsValue({
        barcodeProduct: "",
      })
      formProduct.validateFields()
    }
  }

  const columnsPackage = [
    {
      title: "Package Barcode",
      dataIndex: "barcode",
    },
    {
      title: "Customer",
      dataIndex: "companyName",
      width: 120,
    },
    {
      title: "Products Count",
      dataIndex: "productCount",
      width: 100,
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      width: 150,
      render: (_, row) => {
        return row.totalPrice ? row.totalPrice.toFixed(2) + " $" : ""
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      width: 120,
      render: (_, row) => {
        return moment(new Date(row.createdDate)).format("DD.MM.YYYY")
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 130,
      render: (_, row) => {
        return (
          <Tag
            style={{ width: "100%", textAlign: "center", padding: 4 }}
            color={
              row.status === 10
                ? "lime"
                : row.status === 20
                ? "orange"
                : row.status === 30
                ? "cyan"
                : "magenta"
            }
          >
            {statusPackage.find(x => x.id === row.status)
              ? statusPackage.find(x => x.id === row.status).name
              : ""}
          </Tag>
        )
      },
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      width: 130,
      render: (_, row) => {
        return (
          <Button
            type="default"
            onClick={() => {
              form.setFieldsValue({
                barcode: row.barcode,
              })
              setProduct(row.barcode)
              getData(tableParams, row.barcode)
            }}
          >
            Product List
          </Button>
        )
      },
    },
  ]

  const columns = [
    {
      title: "Product Barcode",
      dataIndex: "barcode",
    },
    {
      title: "Customer ",
      dataIndex: "companyName",
      width: 150,
    },

    {
      title: "Created Date",
      dataIndex: "createdDate",
      width: 120,
      render: (_, row) => {
        return moment(new Date(row.createdDate)).format("DD.MM.YYYY")
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 120,
      render: (_, row) => {
        return (
          <Tag
            style={{ width: "100%", textAlign: "center", padding: 4 }}
            color={
              row.status === 10
                ? "lime"
                : row.status === 20
                ? "orange"
                : row.status === 30
                ? "cyan"
                : "magenta"
            }
          >
            {statusProduct.find(x => x.id === row.status)
              ? statusProduct.find(x => x.id === row.status).name
              : ""}
          </Tag>
        )
      },
    },
  ]

  const handleTableChange = pagination => {
    setLoading(true)
    const values = form.getFieldsValue()
    setTableParams({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: tableParams.total,
    })
    if (
      tableParams.current !== pagination.current ||
      tableParams.pageSize !== pagination.pageSize
    ) {
      getData(
        {
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        values.barcode.trim()
      )
    }
  }

  const getPackageData = (pagination, values) => {
    setLoading(true)
    Http.get(
      `api/packages/GetCompanyPackages?PageNumber=${
        pagination?.current ? pagination.current : 1
      }&Limit=10${values?.barcode ? "&PackageBarcode=" + values.barcode : ""}`
    )
      .then(res => {
        if (res?.data?.data?.length > 0) {
          setPackageData(res.data.data)
          setTableParamsPackagesPackages({
            current: pagination?.current ? pagination.current : 1,
            pageSize: pagination?.pageSize ? pagination.pageSize : 10,
            total: res?.data?.totalItems,
          })
          setLoading(false)
        } else {
          setPackageData([])
          setData([])
          setTableParamsPackagesPackages({
            current: 1,
            pageSize: 10,
            total: 0,
          })
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }

  const handleTableChangePackages = pagination => {
    setLoading(true)
    const values = searchForm.getFieldsValue()
    setTableParamsPackagesPackages({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: tableParamsPackages.total,
    })
    if (
      tableParamsPackages.current !== pagination.current ||
      tableParamsPackages.pageSize !== pagination.pageSize
    ) {
      getPackageData(
        {
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        values
      )
    }
  }

  useEffect(() => {
    if (packageBackode || productBarcode) {
      searchForm.setFieldsValue({
        barcode: packageBackode,
      })
      getPackageData(tableParams, { barcode: packageBackode })
      getData(tableParams, packageBackode, productBarcode)
    } else {
      getPackageData(tableParams, null)
    }
  }, [])

  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="You have not performed any pectation process yet."
      />
    ),
  }

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            alignItems: "start",
            marginTop: 10,
          }}
        >
          <Col lg={12}>
            <Collapse activeKey={["1"]} expandIcon={() => false}>
              <Panel header="Received" key="1">
                <Form form={form} layout="vertical">
                  <Form.Item
                    label={
                      <>
                        Package Barcode
                        <span
                          style={{
                            fontSize: "10px",
                            color: "red",
                            marginLeft: "10px",
                            opacity: "0.6",
                            marginTop: 5,
                          }}
                        >
                          * Please press enter!
                        </span>
                      </>
                    }
                    name="barcode"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Input
                      allowClear
                      onPressEnter={() => formControl()}
                      disabled={loading}
                    />
                  </Form.Item>
                </Form>
                <Form form={formProduct} layout="vertical">
                  {data.length > 0 && (
                    <Form.Item
                      label={
                        <>
                          Product Delivery Barcode (Received)
                          <span
                            style={{
                              fontSize: "10px",
                              color: "red",
                              marginLeft: "10px",
                              opacity: "0.6",
                              marginTop: 5,
                            }}
                          >
                            * Please press enter!
                          </span>
                        </>
                      }
                      name="barcodeProduct"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        ref={inputRef}
                        onPressEnter={() => formControlProduct()}
                        disabled={loading}
                      />
                    </Form.Item>
                  )}
                </Form>
              </Panel>
            </Collapse>
            <Collapse
              activeKey={["1"]}
              expandIcon={() => false}
              style={{ marginTop: 15 }}
            >
              <Panel header="Package List" key="1">
                <Form form={searchForm} layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col lg={8}>
                      <Form.Item label="Package Barcode" name="barcode">
                        <Input
                          onPressEnter={() => {
                            const values = searchForm.getFieldsValue()
                            getPackageData(
                              {
                                current: 1,
                                pageSize: 10,
                              },
                              values
                            )
                          }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={8}
                      className="d-flex align-items-center justify-content-start gap-2"
                    >
                      <Button
                        type="button"
                        className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                        icon={<ClearOutlined />}
                        onClick={() => {
                          searchForm.setFieldsValue({
                            barcode: "",
                          })
                          form.resetFields()
                          getPackageData(
                            {
                              current: 1,
                              pageSize: 10,
                            },
                            null
                          )
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                        icon={<SearchOutlined />}
                        onClick={() => {
                          const values = searchForm.getFieldsValue()
                          getPackageData(
                            {
                              current: 1,
                              pageSize: 10,
                            },
                            values
                          )
                        }}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Table
                  bordered
                  size="small"
                  columns={columnsPackage}
                  dataSource={packageData}
                  locale={locale}
                  loading={loading}
                  rowKey={record => record.barcode}
                  pagination={tableParamsPackages}
                  onChange={handleTableChangePackages}
                />
              </Panel>
            </Collapse>
          </Col>

          {data.length > 0 && (
            <Col lg={12}>
              <Collapse activeKey={["1"]} expandIcon={() => false}>
                <Panel
                  header={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {product} Product List
                      <div>Total Items: {totalItems} </div>
                    </div>
                  }
                  key="1"
                >
                  <Table
                    bordered
                    size="small"
                    columns={columns}
                    dataSource={data}
                    locale={locale}
                    loading={loading}
                    rowKey={record => record.barcode}
                    pagination={tableParams}
                    onChange={handleTableChange}
                  />
                </Panel>
              </Collapse>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default PackageList
