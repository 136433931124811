import React, { useState, useEffect } from "react"

function showSvgFromUrl(svgUrl, className) {
  return new Promise((resolve, reject) => {
    fetch(svgUrl)
      .then(res => res.text())
      .then(svgText => {
        // SVG içeriğini bir DOM nesnesine dönüştür
        const parser = new DOMParser()
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml")
        // Path elementini seç
        const polygonElements = svgDoc.querySelectorAll("polygon")
        const polylineElements = svgDoc.querySelectorAll("polyline")
        const gElements = svgDoc.querySelectorAll("g")
        const lineElements = svgDoc.querySelectorAll("line")
        const pathElements = svgDoc.querySelectorAll("path")
        var styleTags = svgDoc.querySelectorAll("style")
        lineElements.forEach(function (line, i) {
          lineElements[i].classList.add("line0")
        })

        styleTags.forEach(function (styleTag) {
          styleTag.parentNode.removeChild(styleTag)
        })

        if (pathElements.length > 0) {
          let newPathEl = []
          let newPathEl2 = []
          pathElements.forEach(function (path, index) {
            // path.parentNode.removeChild(path)
            let dAttribute = path.getAttribute("d")
            var filtered = dAttribute.split(" ").filter(x => x !== "")
            filtered.map((x, i) => {
              const pathElement = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
              )
              pathElement.setAttribute("class", "st_0")
              x && pathElement.setAttribute("d", x)
              let find = newPathEl.find(x => x.val === i)
              if (!find) {
                newPathEl.push({ val: i, path: pathElement })
              } else {
                newPathEl2.push({ val: i, path: pathElement })
              }
            })
          })
          newPathEl.map(item => {
            if (gElements.length > 0) {
              gElements[0].appendChild(item.path)
              gElements[0].classList.add("gs0")
            } else {
              resolve(svgDoc.documentElement.outerHTML)
            }
          })
          if (gElements.length > 1) {
            newPathEl2.map(item => {
              gElements[1].appendChild(item.path)
              if (polygonElements.length > 0 || polylineElements.length > 0) {
                gElements[1].classList.add("gs0")
              } else {
                gElements[1].classList.add("gs1")
              }
            })
          }
          resolve(svgDoc.documentElement.outerHTML)
        } else {
          resolve(svgText)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

function SvgComponent({ svgUrl, className, width, height }) {
  const [svgContent, setSvgContent] = useState(null)

  useEffect(() => {
    showSvgFromUrl(svgUrl, className)
      .then(svgText => {
        setSvgContent(svgText)
      })
      .catch(error => {})
  }, [svgUrl])

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: svgContent }}
      style={{
        width: width,
        height: height,
        margin: "auto",
      }}
    />
  )
}

export default SvgComponent
