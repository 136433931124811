import React, { useEffect, useState } from "react"
import { Modal, DatePicker, Form, Input, Select, InputNumber } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import Maps from "components/map"
import { Row, Col } from "reactstrap"
import * as moment from "moment"
const { TextArea } = Input

import "toastr/build/toastr.min.css"
const stateCodeList = [
  { value: "AL", label: "Alabama (AL)" },
  { value: "AK", label: "Alaska (AK)" },
  { value: "AZ", label: "Arizona (AZ)" },
  { value: "AR", label: "Arkansas (AR)" },
  { value: "CA", label: "California (CA)" },
  { value: "CO", label: "Colorado (CO)" },
  { value: "CT", label: "Connecticut (CT)" },
  { value: "DE", label: "Delaware (DE)" },
  { value: "DC", label: "District Of Columbia (DC)" },
  { value: "FL", label: "Florida (FL)" },
  { value: "GA", label: "Georgia (GA)" },
  { value: "HI", label: "Hawaii (HI)" },
  { value: "ID", label: "Idaho (ID)" },
  { value: "IL", label: "Illinois (IL)" },
  { value: "IN", label: "Indiana (IN)" },
  { value: "IA", label: "Iowa (IA)" },
  { value: "KS", label: "Kansas (KS)" },
  { value: "KY", label: "Kentucky (KY)" },
  { value: "LA", label: "Louisiana (LA)" },
  { value: "ME", label: "Maine (ME)" },
  { value: "MD", label: "Maryland (MD)" },
  { value: "MA", label: "Massachusetts (MA)" },
  { value: "MI", label: "Michigan (MI)" },
  { value: "MN", label: "Minnesota (MN)" },
  { value: "MS", label: "Mississippi (MS)" },
  { value: "MO", label: "Missouri (MO)" },
  { value: "MT", label: "Montana (MT)" },
  { value: "NE", label: "Nebraska (NE)" },
  { value: "NV", label: "Nevada (NV)" },
  { value: "NH", label: "New Hampshire (NH)" },
  { value: "NJ", label: "New Jersey (NJ)" },
  { value: "NM", label: "New Mexico (NM)" },
  { value: "NY", label: "New York (NY)" },
  { value: "NC", label: "North Carolina (NC)" },
  { value: "ND", label: "North Dakota (ND)" },
  { value: "OH", label: "Ohio (OH)" },
  { value: "OK", label: "Oklahoma (OK)" },
  { value: "OR", label: "Oregon (OR)" },
  { value: "PA", label: "Pennsylvania (PA)" },
  { value: "RI", label: "Rhode Island (RI)" },
  { value: "SC", label: "South Carolina (SC)" },
  { value: "SD", label: "South Dakota (SD)" },
  { value: "TN", label: "Tennessee (TN)" },
  { value: "TX", label: "Texas (TX)" },
  { value: "UT", label: "Utah (UT)" },
  { value: "VT", label: "Vermont (VT)" },
  { value: "VA", label: "Virginia (VA)" },
  { value: "WA", label: "Washington (WA)" },
  { value: "WV", label: "West Virginia (WV)" },
  { value: "WI", label: "Wisconsin (WI)" },
  { value: "WY", label: "Wyoming (WY)" },
]
const UpsertModal = ({
  setUpsertModal,
  upsertModal,
  getTableData,
  setLoading,
  tableParams,
  orderStatus,
}) => {
  const [refForm] = Form.useForm()
  const [addressInfo, setAddressInfo] = useState(null)
  const [style, setStyle] = useState(null)
  const [size, setSize] = useState(null)
  const [color, setColor] = useState(null)
  const [options, setOptions] = useState([])

  const getOptions = () => {
    setLoading(true)
    Http.get(`api/orders/GetApparelProperties`)
      .then(res => {
        if (res?.success) {
          setLoading(false)
          setOptions({
            sizes: [
              {
                label: "Size",
                options: res.data.sizes.map(item => {
                  return { label: item.name, value: item.id }
                }),
              },
            ],
            colors: [
              {
                label: "Color",
                options: res.data.colors.map(item => {
                  return {
                    label: item.name + " (" + item.hexCode + ")",
                    value: item.id,
                    hexCode: item.hexCode,
                  }
                }),
              },
            ],
            types: [
              {
                label: "Types",
                options: res.data.styles.map(item => {
                  return {
                    label: item.name,
                    value: item.id,
                  }
                }),
              },
            ],
          })
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }

  useEffect(() => {
    getOptions()
  }, [])
  useEffect(() => {
    if (upsertModal?.data?.id) {
      setColor(upsertModal.data?.colorId ? upsertModal.data?.colorId : "")
    }
  }, [upsertModal])

  useEffect(() => {
    if (addressInfo?.value?.place_id) {
      refForm.setFieldsValue({
        city:
          addressInfo?.value?.terms.length === 4
            ? addressInfo?.value?.terms[1]?.value
            : addressInfo?.value?.terms.length === 5
            ? addressInfo?.value?.terms[2]?.value
            : addressInfo?.value?.terms.length === 6
            ? addressInfo?.value?.terms[3]?.value
            : "",
        addressLine1:
          addressInfo?.value?.terms.length === 4
            ? addressInfo?.value?.terms[0]?.value
            : addressInfo?.value?.terms.length === 5
            ? addressInfo?.value?.terms[0]?.value +
              " " +
              addressInfo?.value?.terms[1]?.value
            : addressInfo?.value?.terms.length === 6
            ? addressInfo?.value?.terms[0]?.value +
              " " +
              addressInfo?.value?.terms[1]?.value
            : "",
        addressLine2:
          addressInfo?.value?.terms.length === 6
            ? addressInfo?.value?.terms[2]?.value
            : "",
        stateCode:
          addressInfo?.value?.terms.length === 4
            ? addressInfo?.value?.terms[2]?.value
            : addressInfo?.value?.terms.length === 5
            ? addressInfo?.value?.terms[3]?.value
            : addressInfo?.value?.terms.length === 6
            ? addressInfo?.value?.terms[4]?.value
            : "",
      })
    }
  }, [addressInfo])

  const handleSave = () => {
    const formValues = refForm.getFieldsValue()
    const obj = {
      ...formValues,
      recordName: upsertModal?.recordName,
      saleDate:
        moment.utc(formValues.saleDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
    }
    if (upsertModal.data?.id) {
      alert(" geliştirme bekliyor")
    } else {
      delete obj.searchMaps
      delete obj.status
      Http.post("api/orders/createApparel", obj)
        .then(res => {
          if (res.success) {
            toastr.success(`${formValues.sku} order has been create.`)
            getTableData({
              current: tableParams.current,
              pageSize: tableParams.pageSize,
            })
          } else {
            toastr.error(res.Message)
          }
          setUpsertModal({ show: false, data: null, recordName: "" })
          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(` order create failed`)
        })
    }
  }

  useEffect(() => {
    if (style?.value && size?.value && color?.value) {
      refForm.setFieldsValue({
        variant: `Style: ${style.label} / ${size.label}, Color: ${color.label}`,
      })
    }
  }, [style, size, color])
  return (
    <Modal
      width={1000}
      maskClosable={false}
      centered
      title={
        upsertModal.data?.id ? (
          <span style={{ display: "flex", alignItems: "center" }}>
            Edit Order
            <span
              style={{
                opacity: "0.6",
                fontSize: "13px",
                marginLeft: "8px",
              }}
            >
              [ {upsertModal.data?.sku} | {upsertModal.data?.orderId} ]
            </span>
          </span>
        ) : (
          "Add Order"
        )
      }
      open={upsertModal.show}
      onOk={() => {
        setLoading(true)
        refForm
          .validateFields()
          .then(() => {
            handleSave()
          })
          .catch(err => {
            setLoading(false)
          })
      }}
      okText="Save"
      onCancel={() =>
        setUpsertModal({ show: false, data: null, recordName: "" })
      }
    >
      <Form
        form={refForm}
        layout="vertical"
        initialValues={{
          status: upsertModal?.data?.status || 10,
          country: "USA",
        }}
        onSubmit={e => {
          e.preventDefault()
          return false
        }}
      >
        <Row>
          <Col lg={4}>
            <Form.Item
              label="SKU"
              name="sku"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item
              label="Order Id"
              name="orderId"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>{" "}
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Item
              label="Name Surname"
              name="nameSurname"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item
              label="Sale Date"
              name="saleDate"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format={"DD.MM.YYYY"} />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={orderStatus}
                disabled={!upsertModal?.data}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Item
              label="Style"
              name="styleId"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select
                options={options?.types}
                onChange={(_, val) => setStyle(val)}
              />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item
              label="Size"
              name="sizeId"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select
                options={options?.sizes}
                onChange={(_, val) => setSize(val)}
              />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item
              label="Color"
              name="colorId"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select
                options={options?.colors}
                onChange={(_, val) => setColor(val)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item
              label="Personalization"
              name="personalization"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Variant"
              name="variant"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Form.Item label="Search Address" name="searchMaps">
              <Maps setValue={setAddressInfo} value={addressInfo} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={[
                  { value: "USA", label: "United States of America (USA)" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col lg={3}>
            <Form.Item
              label="State"
              name="stateCode"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select options={stateCodeList} />
            </Form.Item>
          </Col>

          <Col lg={3}>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={3}>
            <Form.Item
              label="ZipCode"
              name="zipCode"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Item label="Address" name="addressLine1">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item label="Apartment/Suit" name="addressLine2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default UpsertModal
